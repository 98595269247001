// import {
//   AttachEmail,
//   AttachFile,
//   Close,
//   Draw,
//   LocationOn,
//   LockOutlined,
//   Mail,
//   Person,
//   PersonAdd,
//   Send,
// } from '@mui/icons-material'
// import {
//   Autocomplete,
//   Avatar,
//   Box,
//   Button,
//   Card,
//   Checkbox,
//   Chip,
//   CircularProgress,
//   Divider,
//   FormControl,
//   FormControlLabel,
//   Grid,
//   IconButton,
//   InputAdornment,
//   Modal,
//   Radio,
//   RadioGroup,
//   Slide,
//   TextField,
//   Typography,
//   createFilterOptions,
// } from '@mui/material'
// import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
// import { useEffect, useState } from 'react'
// import FileUpload from '../File/FileUpload'
// import { useFetch } from '../../hooks/useFetch'
// import { useForm } from 'react-hook-form'
// import { usePost } from '../../hooks/usePost'
// import { enqueueSnackbar } from 'notistack'
// import axios from 'axios'
// import usePlacesAutocomplete from 'use-places-autocomplete'
// import { BASE_URL } from '../../utils/config'
// import { authHeader } from '../../utils/auth'

// import { styled } from '@mui/material/styles'
// import AddIcon from '@mui/icons-material/Add'

// export default function CreateMessage({
//   open,
//   close,
//   refetch,
//   setRefetch,
// }: {
//   open: boolean
//   close: any
//   refetch?: boolean
//   setRefetch?: any
// }) {
//   const [fileUrl, setFileUrl] = useState<string>()
//   const [fileState, setFileState] = useState<any>()
//   const [url, setUrl] = useState() as any
//   const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([])

//   console.log({ fileUrl })

//   useEffect(() => {
//     console.log({ fileState })
//     if (fileState) {
//       axios
//         .get(
//           `${BASE_URL}/public/document/presigned-url?fileKey=${fileState?.name}`,
//           {
//             headers: authHeader(),
//           }
//         )
//         .then((res) => setUrl(res?.data?.url))
//         .catch((e) => console.log({ e }))
//     }
//   }, [fileState])
//   useEffect(() => {
//     if (url) {
//       const reader = new FileReader()
//       reader.onload = handleReaderLoaded
//       reader.readAsArrayBuffer(fileState)
//     }
//   }, [url])
//   const handleReaderLoaded = (event: any) => {
//     const binaryData = event.target.result
//     console.log({ binaryData })

//     axios
//       .put(url, binaryData)
//       .then((res) => setFileUrl(url?.split('?')?.[0]))
//       .catch((e) => console.log({ e }))
//   }

//   type FormValues = {}

//   const {
//     register,
//     control,
//     handleSubmit,
//     setValue,
//     formState: { errors },
//   } = useForm<FormValues>({
//     defaultValues: {},
//     mode: 'onBlur',
//   })

//   const onSubmit = (data: any) => {}

//   const {
//     ready,
//     value,
//     suggestions: { status, data },
//     setValue: setPlacesValue,
//     clearSuggestions,
//   } = usePlacesAutocomplete({
//     callbackName: 'GO_PLACES',
//     requestOptions: {
//       /* Define search scope here */
//     },
//     debounce: 300,
//   })

//   const platforms = [
//     { name: 'Text', value: 'TEXT' },
//     { name: 'Email', value: 'EMAIL' },
//     { name: 'Fax', value: 'FAX' },
//   ]

//   const CustomAutocomplete = styled(Autocomplete)({
//     '& .MuiOutlinedInput-root': {
//       borderRadius: 10, // Sets rounded corners
//       background: '#f0f0f0', // Light gray background
//       '& fieldset': {
//         borderWidth: '0px', // Removes border
//       },
//       '&:hover fieldset': {
//         borderWidth: '0px', // Removes border on hover
//       },
//       '&.Mui-focused fieldset': {
//         borderWidth: '0px', // Removes border on focus
//       },
//     },
//     '& .MuiAutocomplete-endAdornment': {
//       top: 'calc(50% - 12px)', // Centers the icon vertically
//       display: 'none',
//     },
//   })

//   const [messageType, setMessageType] = useState('Email')

//   function MessageIcon({ icon, type }: { icon: any; type: string }) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           gap: 0.5,
//         }}
//       >
//         {icon}
//         <Typography
//           sx={{
//             color: messageType === type ? '#3376D6' : '#BBC4DD',

//             fontSize: 13,
//             fontWeight: 'bold',
//           }}
//         >
//           {type}
//         </Typography>
//       </Box>
//     )
//   }

//   function SentMessage({
//     imageUrl,
//     message,
//     time,
//     language,
//   }: {
//     imageUrl: string
//     message: string
//     time: string
//     language: string
//   }) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           gap: 2,
//           alignItems: 'flex-start',
//           pr: 8.4,
//           mb: 2,
//         }}
//       >
//         <Box
//           sx={{
//             bgcolor: '#E2ECF4',
//             height: 48,
//             width: 50,
//             borderRadius: '100%',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             px: 1,
//             py: 0.2,
//           }}
//         >
//           <Avatar sx={{ width: 40, height: 40 }} src={imageUrl} />
//         </Box>
//         <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 3, p: 1.5 }}>
//           <Typography sx={{ fontSize: 14 }}>{message}</Typography>
//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               py: 1,
//             }}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 gap: 1,
//               }}
//             >
//               <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
//                 {time}
//               </Typography>
//               <Box sx={{ borderRadius: 5, border: '2px solid gray' }} />
//               <Typography
//                 sx={{
//                   color: 'primary.main',
//                   fontSize: 12,
//                   fontWeight: 'bold',
//                 }}
//               >
//                 Change to original language
//               </Typography>
//               <Typography
//                 sx={{
//                   color: 'black',
//                   fontSize: 12,
//                   fontWeight: 'bold',
//                 }}
//               >
//                 ({language})
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     )
//   }

//   function ReceivedMessage({
//     imageUrl,
//     message,
//     time,
//     language,
//   }: {
//     imageUrl: string
//     message: string
//     time: string
//     language: string
//   }) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           gap: 2,
//           alignItems: 'flex-start',
//           pl: 8.4,
//           mb: 2,
//         }}
//       >
//         <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 3, p: 1.5 }}>
//           <Typography sx={{ fontSize: 14 }}>{message}</Typography>
//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'space-between',
//               py: 1,
//             }}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 gap: 1,
//                 justifyContent: 'flex-end',
//                 width: '100%',
//               }}
//             >
//               <Typography
//                 sx={{
//                   color: 'primary.main',
//                   fontSize: 12,
//                   fontWeight: 'bold',
//                 }}
//               >
//                 Change to original language
//               </Typography>
//               <Typography
//                 sx={{
//                   color: 'black',
//                   fontSize: 12,
//                   fontWeight: 'bold',
//                 }}
//               >
//                 ({language})
//               </Typography>
//               <Box sx={{ borderRadius: 5, border: '2px solid gray' }} />
//               <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
//                 {time}
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             bgcolor: '#E2ECF4',
//             height: 48,
//             width: 50,
//             borderRadius: '100%',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             px: 1,
//             py: 0.2,
//           }}
//         >
//           <Avatar sx={{ width: 40, height: 40 }} src={imageUrl} />
//         </Box>
//       </Box>
//     )
//   }

//   return (
//     <Modal open={open} onClose={close} closeAfterTransition>
//       <Slide in={open} direction='left'>
//         <Box sx={{ position: 'relative', float: 'right' }}>
//           <Card
//             sx={{
//               width: { xs: '100vw', sm: '80vw', md: 900 },
//               height: '100vh',
//               bgcolor: '#FBFBFB',
//             }}
//           >
//             <Box
//               sx={{
//                 bgcolor: '#FBFBFB',
//                 height: 60,
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'space-between',
//                 px: 3,
//               }}
//             >
//               <Box
//                 sx={{
//                   bgcolor: '#EDEDED',
//                   px: 3,
//                   py: 0.8,
//                   cursor: 'pointer',
//                   borderRadius: 2,
//                 }}
//                 onClick={close}
//               >
//                 Back
//               </Box>
//               <Typography sx={{ fontWeight: 'bold' }}>New Message</Typography>
//               <Box> </Box>
//             </Box>
//             <Divider />
//             <Box sx={{ borderBottom: '1px solid #CFDAE5', height: 70 }}>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   px: 3,
//                   height: '100%',
//                 }}
//               >
//                 <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
//                   <MessageIcon
//                     icon={
//                       <Mail
//                         sx={{
//                           color:
//                             messageType === 'Email' ? '#3376D6' : '#BBC4DD',
//                         }}
//                       />
//                     }
//                     type='Email'
//                   />
//                   <MessageIcon
//                     icon={
//                       <Mail
//                         sx={{
//                           color: messageType === 'Text' ? '#3376D6' : '#BBC4DD',
//                         }}
//                       />
//                     }
//                     type='Text'
//                   />
//                 </Box>
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 height: '65vh',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 p: 3,
//               }}
//             >
//               <Box
//                 sx={{
//                   bgcolor: '#FFFFFF',
//                   boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
//                   p: 3,
//                   borderRadius: 4,
//                   height: '100%',
//                   width: '75%',
//                 }}
//               >
//                 <Box sx={{ py: 2 }}>
//                   <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
//                     Wed Apr 2, 2024
//                   </Typography>
//                 </Box>
//                 <Divider />
//                 <Box sx={{ py: 2, height: '94%' }}>
//                   <Box
//                     sx={{
//                       height: '90%',
//                       '&::-webkit-scrollbar': {
//                         width: 0,
//                       },
//                       overflowY: 'auto',
//                     }}
//                   >
//                     {/* <SentMessage
//                       imageUrl=''
//                       language='Spanish'
//                       message='Ferrars all spirits his imagine effects amongst
//                           neither. It bachelor cheerful of mistaken. Tore has
//                           sons put upon wife use bred seen. Its dissimilar
//                           invitation ten has discretion unreserved. Had you him
//                           humoured jointure ask expenses learning. Blush on in
//                           jokes sense do do. Brother hundred he assured reached
//                           on up no. On am nearer missed lovers. To it mother
//                           extent temper figure better.'
//                       time='5 min ago'
//                     />

//                     <ReceivedMessage
//                       imageUrl=''
//                       language='Spanish'
//                       message='He an thing rapid these after going drawn or. Timed she his law the spoil round defer. In surprise concerns informed betrayed he learning is ye. Ignorant formerly so ye blessing. He as spoke avoid given downs money on we. Of properly carriage shutters ye as wandered up repeated moreover. Inquietude attachment if ye an solicitude to. Remaining so continued concealed as knowledge happiness. Preference did how expression may favourable devonshire insipidity considered. An length design regret an hardly barton mr figure.'
//                       time='5 min ago'
//                     /> */}
//                     <CustomAutocomplete
//                       multiple
//                       disableClearable
//                       options={['123', '234', '456', '356']}
//                       // getOptionLabel={(option) => option}
//                       renderInput={(params) => (
//                         <TextField
//                           {...params}
//                           placeholder='Person(s)'
//                           variant='outlined'
//                           InputProps={{
//                             ...params.InputProps,
//                             endAdornment: (
//                               <>
//                                 {params.InputProps.endAdornment}
//                                 <PersonAdd
//                                   color='primary'
//                                   style={{ marginRight: 8 }}
//                                 />
//                               </>
//                             ),
//                           }}
//                         />
//                       )}
//                     />
//                   </Box>
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       gap: 3,
//                       justifyContent: 'flex-end',
//                       pr: 8.4,
//                     }}
//                   >
//                     <Button>
//                       <Box
//                         sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
//                       >
//                         <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
//                           Reply
//                         </Typography>
//                         <Send sx={{ width: 20, height: 20 }} />
//                       </Box>
//                     </Button>
//                   </Box>
//                 </Box>
//               </Box>
//             </Box>
//             <Divider sx={{ m: 2 }} />
//             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
//               <Box
//                 sx={{
//                   bgcolor: '#F7F7F7',
//                   ml: 7,
//                   borderTopLeftRadius: 25,
//                   borderTopRightRadius: 25,
//                   height: 'calc(100vh - 756px)',
//                   width: '86%',
//                 }}
//               >
//                 <Box
//                   sx={{
//                     pr: 2,
//                     width: '100%',
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     alignItems: 'center',
//                   }}
//                 >
//                   <Box>
//                     <TextField
//                       placeholder='Subject'
//                       sx={{
//                         '& .MuiOutlinedInput-root': {
//                           '& fieldset': {
//                             border: 'none', // Remove the outline
//                           },
//                         },
//                       }}
//                       size='small'
//                       InputProps={{
//                         sx: {
//                           borderRadius: 30,
//                           // height: 50,
//                           px: 1,
//                         },
//                       }}
//                     />
//                   </Box>
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       justifyContent: 'center',
//                       gap: 1,
//                     }}
//                   >
//                     <AttachFile />
//                     <LockOutlined />
//                   </Box>
//                 </Box>
//                 <Divider sx={{ mt: 1 }} />
//                 <Box>
//                   <textarea
//                     style={{
//                       height: 'calc(100vh - 796px)',
//                       width: '100%',
//                       border: 'none',
//                       resize: 'none',
//                       background: 'transparent',
//                       padding: '15px 22px',
//                       outline: 'none',
//                     }}
//                     placeholder='Write Message'
//                   />
//                 </Box>
//               </Box>
//               <Button>
//                 <Send sx={{ width: 35, height: 35 }} />
//               </Button>
//             </Box>
//           </Card>
//         </Box>
//       </Slide>
//     </Modal>
//   )
// }

import {
  AttachEmail,
  AttachFile,
  Camera,
  Close,
  Draw,
  LocationOn,
  LockOutlined,
  Mail,
  Message,
  Person,
  PersonAdd,
  Send,
  Videocam,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useContext, useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from 'react-hook-form'
import { usePost, usePostEmail } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import axios from 'axios'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { BASE_URL } from '../../utils/config'
import { authHeader } from '../../utils/auth'

import { styled } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add'
import { UserContext } from '../../context/UserContext'

export default function CreateMessage({
  open,
  close,
  refetch,
  setRefetch,
}: {
  open: boolean
  close: any
  refetch?: boolean
  setRefetch?: any
}) {
  const { user }: any = useContext(UserContext)
  const [fileUrl, setFileUrl] = useState<string>()
  const [fileState, setFileState] = useState<any>()
  const [url, setUrl] = useState() as any
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([])
  const [subject, setSubject] = useState() as any
  const [message, setMessage] = useState() as any
  const [selectedReceipients, setSelectedReceipients] = useState() as any

  console.log({ user })

  const { data: participants, mutate: fetchParticipants } = usePost(
    '/public/appointment/participants/list'
  )

  const {
    mutate: sendEmail,
    isLoading,
    isError,
    isSuccess,
    error,
  } = usePostEmail(`/accounts/${user?.id}/messages/submit`)

  const {
    mutate: saveAsDraft,
    isLoading: isDraftLoading,
    isError: isDraftError,
    isSuccess: isDraftSuccess,
    error: draftError,
  } = usePostEmail(`/accounts/${user?.id}/messages/drafts`)

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchParticipants(dataP)
  }, [])

  console.log({ participants, selectedReceipients })

  console.log({ fileUrl })

  const send = () => {
    const data: any = {
      from: {
        name: user?.firstName + ' ' + user?.lastName,
        address: user?.email,
      },
      to: selectedReceipients?.map((item: any) => {
        return {
          name: item?.name,
          address: item?.email,
        }
      }),
      subject: subject,
      text: message,
    }
    console.log({ data })
    sendEmail(data)
  }

  const saveDraft = () => {
    const data: any = {
      from: {
        name: user?.firstName + ' ' + user?.lastName,
        address: user?.email,
      },
      to: selectedReceipients?.map((item: any) => {
        return {
          name: item?.name,
          address: item?.email,
        }
      }),
      subject: subject,
      text: message,
    }
    console.log({ data })
    saveAsDraft(data)
  }

  useEffect(() => {
    console.log({ fileState })
    if (fileState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${fileState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [fileState])
  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(fileState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => setFileUrl(url?.split('?')?.[0]))
      .catch((e) => console.log({ e }))
  }

  type FormValues = {}

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
    mode: 'onBlur',
  })

  const onSubmit = (data: any) => {}

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue: setPlacesValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'GO_PLACES',
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })

  const platforms = [
    { name: 'Text', value: 'TEXT' },
    { name: 'Email', value: 'EMAIL' },
    { name: 'Fax', value: 'FAX' },
  ]

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Sent Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetch(!refetch)
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  useEffect(() => {
    if (isDraftSuccess) {
      enqueueSnackbar('Message Saved As Draft', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetch(!refetch)
      close()
    }
    if (isDraftError) {
      enqueueSnackbar(
        draftError?.response?.data?.message ||
          draftError?.message ||
          'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isDraftSuccess, isDraftError])

  const [messageType, setMessageType] = useState('Email')
  const [hoverMessageType, setHoverMessageType] = useState('')

  function MessageIcon({ icon, type }: { icon: any; type: string }) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 0.5,
          background:
            messageType === type
              ? 'linear-gradient(45deg, rgba(11, 97, 172, 1) 0%, rgba(111,161,255,1) 85%)'
              : hoverMessageType === type
              ? '#EFF2F5'
              : 'transparent',
          width: 55,
          height: 65,
          borderRadius: 2.5,
          cursor: 'pointer',
        }}
        onClick={() => setMessageType(type)}
        onMouseOver={() => setHoverMessageType(type)}
        onMouseOut={() => setHoverMessageType('')}
      >
        {icon}
        <Typography
          sx={{
            color: messageType === type ? 'White' : '#BBC4DD',

            fontSize: 13,
            fontWeight: 'bold',
          }}
        >
          {type}
        </Typography>
      </Box>
    )
  }

  function SentMessage({
    imageUrl,
    message,
    time,
    language,
  }: {
    imageUrl: string
    message: string
    time: string
    language: string
  }) {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'flex-start',
          pr: 8.4,
          mb: 2,
        }}
      >
        <Box
          sx={{
            bgcolor: '#E2ECF4',
            height: 48,
            width: 50,
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: 1,
            py: 0.2,
          }}
        >
          <Avatar sx={{ width: 40, height: 40 }} src={imageUrl} />
        </Box>
        <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 3, p: 1.5 }}>
          <Typography sx={{ fontSize: 14 }}>{message}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                {time}
              </Typography>
              <Box sx={{ borderRadius: 5, border: '2px solid gray' }} />
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                Change to original language
              </Typography>
              <Typography
                sx={{
                  color: 'black',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                ({language})
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  function ReceivedMessage({
    imageUrl,
    message,
    time,
    language,
  }: {
    imageUrl: string
    message: string
    time: string
    language: string
  }) {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'flex-start',
          pl: 8.4,
          mb: 2,
        }}
      >
        <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 3, p: 1.5 }}>
          <Typography sx={{ fontSize: 14 }}>{message}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                Change to original language
              </Typography>
              <Typography
                sx={{
                  color: 'black',
                  fontSize: 12,
                  fontWeight: 'bold',
                }}
              >
                ({language})
              </Typography>
              <Box sx={{ borderRadius: 5, border: '2px solid gray' }} />
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                {time}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: '#E2ECF4',
            height: 48,
            width: 50,
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: 1,
            py: 0.2,
          }}
        >
          <Avatar sx={{ width: 40, height: 40 }} src={imageUrl} />
        </Box>
      </Box>
    )
  }

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: '80vw', md: 900 },
              height: '100vh',
              bgcolor: '#FBFBFB',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                border: '1px solid #EBEBEB',
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>New Message</Typography>
              <Box>
                <Videocam color='primary' />
              </Box>
            </Box>
            <Box
              sx={{
                // borderBottom: '1px solid #EBEBEB',
                height: 90,
                mx: 3,
                mt: 3,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                    justifyContent: 'center',
                    // border: '1px solid red',
                    width: '100%',
                  }}
                >
                  <MessageIcon
                    icon={
                      <Mail
                        sx={{
                          color: messageType === 'Email' ? 'white' : '#BBC4DD',
                        }}
                      />
                    }
                    type='Email'
                  />
                  <MessageIcon
                    icon={
                      <Message
                        sx={{
                          color: messageType === 'Text' ? 'white' : '#BBC4DD',
                        }}
                      />
                    }
                    type='Text'
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: '70vh',
                py: 3,
                width: '100%',
              }}
            >
              <Autocomplete
                options={participants?.data?.participants || []}
                multiple
                disableClearable
                onChange={(event, value) => {
                  setSelectedReceipients(value)
                }}
                renderTags={(value, getTagProps) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    {value.map((option: any, index) => (
                      <Box
                        sx={{
                          bgcolor: '#F4FAFF',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          border: '1px solid #ECEDED',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1.5,
                        }}
                      >
                        <Avatar
                          sx={{ width: 20, height: 20 }}
                          src={option?.imageUrl}
                        />
                        <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                          {option?.name?.split(' ')[0]}
                        </Typography>
                        <IconButton
                          sx={{
                            border: '1px solid gray',
                            width: 16,
                            height: 16,
                          }}
                          onClick={() =>
                            getTagProps({ index })?.onDelete(option)
                          }
                        >
                          <Close
                            sx={{
                              width: 13,
                              height: 13,
                              color: 'gray',
                            }}
                          />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )}
                renderOption={(props, option: any) => (
                  <Box
                    component='li'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 6,
                      width: '100%',
                    }}
                    {...props}
                  >
                    <Avatar
                      sx={{ width: 45, height: 45 }}
                      src={option?.imageUrl}
                    />

                    <Typography sx={{ width: 200 }}>{option?.name}</Typography>
                    <Box
                      sx={{
                        bgcolor: '#F4FAFF',
                        px: 1,
                        borderRadius: 5,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#4D72A6',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: 12,
                        }}
                      >
                        {option?.role}
                      </Typography>
                    </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Person(s)'
                    variant='outlined'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <PersonAdd
                            color='primary'
                            // style={{ marginRight: 8 }}
                          />
                        </>
                      ),
                    }}
                  />
                )}
                sx={{
                  mx: 8,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 4, // Sets rounded corners
                    background: '#EFF2F5', // Light gray background
                    '& fieldset': {
                      border: '0.5px solid #DFE8F0',
                    },
                    '&:hover fieldset': {
                      borderWidth: '0px', // Removes border on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderWidth: '0px', // Removes border on focus
                    },
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    top: 'calc(50% - 12px)', // Centers the icon vertically
                    display: 'none',
                  },
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  my: 4,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EFF2F5',
                    ml: 8,
                    borderRadius: 5,
                    height: 'calc(100vh - 756px)',
                    width: '99%',
                    border: '0.5px solid #DFE8F0',
                  }}
                >
                  <Box
                    sx={{
                      pr: 2,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      pt: 1,
                    }}
                  >
                    <Box>
                      <TextField
                        placeholder='Subject'
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                          },
                        }}
                        onChange={(e) => setSubject(e.target.value)}
                        size='small'
                        InputProps={{
                          sx: {
                            borderRadius: 30,
                            // height: 50,
                            px: 1,
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                      }}
                    >
                      <AttachFile />
                      <LockOutlined />
                    </Box>
                  </Box>
                  <Divider sx={{ mt: 1 }} />
                  <Box>
                    <textarea
                      style={{
                        height: 'calc(100vh - 812px)',
                        width: '100%',
                        border: 'none',
                        resize: 'none',
                        background: 'transparent',
                        padding: '15px 22px',
                        outline: 'none',
                        userSelect: 'none',
                      }}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder='Write Message'
                    />
                  </Box>
                </Box>
                <Button onClick={send}>
                  <Send sx={{ width: 30, height: 30 }} />
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: 5,
                height: '10.9vh',
              }}
            >
              <Button
                variant='contained'
                onClick={saveDraft}
                sx={{
                  bgcolor: '#EFF2F5',
                  '&:hover': { bgcolor: '#EAF2F5' },
                  color: 'black',
                }}
              >
                Save As Draft
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
