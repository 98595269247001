import { useQuery } from 'react-query'
import { authHeader, logout } from '../utils/auth'
import axios from 'axios'
import { BASE_URL, EMAIL_BASE_URL } from '../utils/config'

export function useFetch(name: string, endpoint?: string, params?: any) {
  console.log({ params })
  const { data, isLoading, isError, error, refetch } = useQuery(name, () => {
    console.log({ params })
    return axios
      .get(`${BASE_URL}${endpoint}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout()
        }
        return Promise.reject(error)
      })
  })
  return { data: data?.data, isLoading, isError, error, refetch }
}

export function useFetchEmail(name: string, endpoint?: string, params?: any) {
  console.log({ params })
  const { data, isLoading, isError, error, refetch } = useQuery(name, () => {
    console.log({ params })
    return axios
      .get(`${EMAIL_BASE_URL}${endpoint}`, {
        headers: authHeader(),
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout()
        }
        return Promise.reject(error)
      })
  })
  return { data: data?.data, isLoading, isError, error, refetch }
}
