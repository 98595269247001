import { UseMutateFunction, useMutation } from 'react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { BASE_URL, EMAIL_BASE_URL } from '../utils/config'
import { authHeader, logout } from '../utils/auth'

interface MUTATE {
  mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, void, unknown>
  isLoading: boolean
  isError: boolean
  error: AxiosError | any
  isSuccess: boolean
  data: any
}

export function usePost(endpoint: string) {
  const { mutate, isLoading, isError, error, isSuccess, data }: MUTATE =
    useMutation((data) => {
      return axios
        .post(`${BASE_URL}${endpoint}`, data, {
          headers: authHeader(),
        })
        .then((response) => {
          return response
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            logout()
          }
          return Promise.reject(error)
        })
    })
  return { mutate, isLoading, isError, error, isSuccess, data }
}

export function usePostEmail(endpoint: string) {
  const { mutate, isLoading, isError, error, isSuccess, data }: MUTATE =
    useMutation((data) => {
      return axios
        .post(`${EMAIL_BASE_URL}${endpoint}`, data, {
          headers: authHeader(),
        })
        .then((response) => {
          return response
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            logout()
          }
          return Promise.reject(error)
        })
    })
  return { mutate, isLoading, isError, error, isSuccess, data }
}

export function usePost2(endpoint: string, token: string) {
  const { mutate, isLoading, isError, error, isSuccess, data }: MUTATE =
    useMutation((data) => {
      return axios.post(`${BASE_URL}${endpoint}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    })
  return { mutate, isLoading, isError, error, isSuccess, data }
}
