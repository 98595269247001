import {
  Add,
  ArrowDownward,
  ChevronLeft,
  ChevronRight,
  Download,
  Edit,
  GppGood,
  KeyboardArrowDown,
  LocationOn,
  PeopleAlt,
  Print,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Fab,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { ClockIcon, DatePicker } from '@mui/x-date-pickers'
import { MaterialReactTable } from 'material-react-table'
import moment from 'moment'
import { useState } from 'react'
import { Cell, Pie, PieChart } from 'recharts'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
export default function Billing() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const columns = [
    {
      id: 'client',
      header: 'Client',
    },
    {
      id: 'type',
      header: 'Type',
    },
    {
      id: 'program',
      header: 'Program',
    },
    {
      id: 'program',
      header: 'Program',
    },
    {
      id: 'insurance',
      header: 'Insurance',
    },
    {
      id: 'clin',
      header: 'Clin',
    },
    {
      id: 'diagnosis',
      header: 'Diagnosis',
    },
  ]

  function TypeCard({
    type,
    color,
    value,
  }: {
    type: string
    color: string
    value: number
  }) {
    return (
      <Box>
        <Typography sx={{ fontWeight: 'bold', fontSize: 13, color: color }}>
          {type}
        </Typography>
        <Typography sx={{ fontSize: 25 }}>{value}</Typography>
      </Box>
    )
  }

  const [hoverType, setHoverType] = useState('')

  function TypeChartCard({
    type,
    color,
    value,
  }: {
    type: string
    color: string
    value: number
  }) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          // border: '1px solid red',
          px: 2,
          py: 0.5,
          borderRadius: 2,
          my: 2,
          '&:hover': {
            bgcolor: 'white',
            boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
            cursor: 'pointer',
          },
        }}
        onMouseEnter={() => setHoverType(type)}
        onMouseLeave={() => setHoverType('')}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              bgcolor: color,
              p: 0.7,
              borderRadius: '100%',
              boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
            }}
          ></Box>
          <Typography sx={{ fontSize: 13 }}>{type}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{ fontSize: 13 }}>{value}</Typography>
          <ChevronRight
            sx={{
              width: 20,
              height: 20,
              color: hoverType === type ? 'black' : 'transparent',
            }}
          />
        </Box>
      </Box>
    )
  }

  const slideLeft = () => {
    let slider: any = document.getElementById('cardslider')
    let scrollAmount = slider.clientWidth * 0.513
    slider.scrollTo({
      left: slider.scrollLeft - scrollAmount,
      behavior: 'smooth',
    })
  }

  const slideRight = () => {
    let slider: any = document.getElementById('cardslider')
    let scrollAmount = slider.clientWidth * 0.513
    slider.scrollTo({
      left: slider.scrollLeft + scrollAmount,
      behavior: 'smooth',
    })
  }

  const data = [
    {
      name: 'Homeless',
      value: 2800,
    },
    {
      name: 'Sheltor',
      value: 1800,
    },
    {
      name: 'Housed',
      value: 1900,
    },
    {
      name: 'On Application',
      value: 3500,
    },
  ]
  const COLORS = [
    '#FF8097',
    '#BF828D',
    '#2699FB',
    '#09244B',
    '#2CD889',
    '#0178DD',
    '#F7617D',
    '#FFCD54',
    '#FF8080',
    '#00B5D6',
    '#000000',
    '#FF6F6F',
    '#00D96D',
    '#FFAC33',
    '#0178DD',
    '#2CD889',
    '#C44141',
  ]

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mx: 5,
          pt: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label='Billing Summary'
            {...a11yProps(0)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label='Analytics'
            {...a11yProps(1)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label='Config'
            {...a11yProps(2)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
        </Tabs>
        <Button
          variant='contained'
          sx={{ borderRadius: 7, py: 1, my: 1 }}
          // onClick={() => setOpenNewIntake(true)}
          //   onClick={handleClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Add />
            <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
              Generate Billing Report
            </Typography>
          </Box>
        </Button>
      </Box>
      {/* <Box sx={{ p: 3 }}>

        <Box
          sx={{
            bgcolor: '#F4FAFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              px: 3,
              py: 2,
            }}
          >
            <Typography
              sx={{ color: '#163055', fontWeight: 'bold', fontSize: 14 }}
            >
              Patient Accounting
            </Typography>
            <Box sx={{ border: '2px solid #163055', borderRadius: '100%' }} />
            <Typography
              sx={{ color: '#163055', fontWeight: 'bold', fontSize: 14 }}
            >
              Insurance Claims
            </Typography>
            <Box sx={{ border: '2px solid #163055', borderRadius: '100%' }} />
            <Typography
              sx={{ color: '#163055', fontWeight: 'bold', fontSize: 14 }}
            >
              Insurance Payments
            </Typography>
          </Box>
          <IconButton sx={{}}>
            <KeyboardArrowDown sx={{ width: 40, height: 40 }} />
          </IconButton>
        </Box>
        <Box sx={{ my: 3 }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={2.5}>
              <Box>
                <Typography
                  sx={{ color: '#627DA5', fontSize: 12, fontWeight: 'bold' }}
                >
                  Insurance Status
                </Typography>
                <Box
                  sx={{
                    border: '1px solid #2CD889',
                    px: 2,
                    py: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    width: 150,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <GppGood sx={{ color: '#2CD889' }} />
                  <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                    Verified
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <Box>
                <Typography
                  sx={{ color: '#627DA5', fontSize: 12, fontWeight: 'bold' }}
                >
                  Eligibility Status
                </Typography>
                <Box
                  sx={{
                    border: '1px solid #EFF4F9',
                    // py: 1,
                    px: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    width: 250,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      color: '#A1A1A1',
                      px: 2,
                      py: 1.5,
                    }}
                  >
                    Not Verified
                  </Typography>
                  <Button variant='contained' sx={{ height: 35 }}>
                    Check
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4.5}>
              <Box>
                <Typography
                  sx={{ color: '#627DA5', fontSize: 12, fontWeight: 'bold' }}
                >
                  Date Coverage
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <Box
                    sx={{
                      border: '1px solid #EFF4F9',
                      py: 1,
                      px: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                      width: 250,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 14,
                        px: 2,
                        color: '#364C6C',
                      }}
                    >
                      {`${moment().format('ll')} - ${moment().format('ll')}`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ bgcolor: '#CDD4E8', px: 2, py: 1.5, borderRadius: 3 }}
                  >
                    <Typography
                      sx={{
                        color: '#354B6D',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      Capitated
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3,
                  height: '100%',
                }}
              >
                <Edit sx={{ color: 'primary.main' }} />
                <Download sx={{ color: 'primary.main' }} />
                <Print sx={{ color: 'primary.main' }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: '#F4FAFF', p: 4 }}>
          <Typography
            sx={{ color: '#3376D6', fontWeight: 'bold', fontSize: 17 }}
          >
            {`${moment().format('lll')} - ${moment().format('lll')}`}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>Diagnosis</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, color: '#9FA1A2' }}>
                259.00
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                Homelessness
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, color: '#9FA1A2' }}>
                Service Code:
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                H0043
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, color: '#9FA1A2' }}>
                DOB:
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                1/1/2000
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, color: '#9FA1A2' }}>
                Clinician
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                Abebe
              </Typography>
            </Box>
          </Box>
          <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{
                bgcolor: 'white',
                width: 150,
                py: 1,
                boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ color: '#74A1E3', fontWeight: 'bold' }}>
                Mental Needs
              </Typography>
            </Box>
            <Box sx={{ border: '2px solid #163055', borderRadius: '100%' }} />
            <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
              Consultation Note
            </Typography>
          </Box>
          <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <ClockIcon />
              <Typography sx={{ fontWeight: 'bold' }}>60 minutes</Typography>
            </Box>
            <Divider orientation='vertical' flexItem />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <PeopleAlt />
              <Typography sx={{ fontWeight: 'bold' }}>60 minutes</Typography>
            </Box>
            <Divider orientation='vertical' flexItem />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <LocationOn />
              <Typography sx={{ fontWeight: 'bold' }}>60 minutes</Typography>
            </Box>
          </Box>
          <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{
                bgcolor: 'white',
                width: 550,
                height: 170,
                py: 1,
                boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            ></Box>
            <Box>
              <Button
                variant='contained'
                sx={{ borderRadius: 5, bgcolor: '#2CD889' }}
                fullWidth
              >
                Approve
              </Button>
              <Box
                sx={{ display: 'flex', alignItems: 'center', my: 1, gap: 2 }}
              >
                <Button
                  variant='contained'
                  color='warning'
                  sx={{ borderRadius: 5 }}
                >
                  <Typography sx={{ fontSize: 12 }}>correction</Typography>
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  sx={{ borderRadius: 5 }}
                >
                  <Typography sx={{ fontSize: 12 }}>
                    mark non-billable
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box> */}
      <Box sx={{ boxShadow: '0px 4px 10px 1px rgba(0,0,0,0.07)', px: 8 }}>
        <Box
          sx={{
            py: 3,
            px: 6,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={2.7}>
              <Box sx={{ my: 1 }}>
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  Insurance
                </Typography>
                <Autocomplete
                  options={[
                    'Comprehensive Needs',
                    'Housing Needs',
                    'Medical Needs',
                    'Employment Needs',
                  ]}
                  getOptionLabel={(item) => item}
                  size='small'
                  // onChange={(event, value) => {
                  //   setLanguage(value)
                  // }}
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      fullWidth
                      // onChange={(e) => setLanguage(e.target.value)}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2.7}>
              <Box sx={{ my: 1 }}>
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  Programs
                </Typography>
                <Autocomplete
                  options={[
                    'Comprehensive Needs',
                    'Housing Needs',
                    'Medical Needs',
                    'Employment Needs',
                  ]}
                  renderOption={(option, state) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3,
                        px: 3,
                        py: 1,
                      }}
                    >
                      <Avatar sx={{ width: 40, height: 40 }} />
                      <Typography>Kebede</Typography>
                    </Box>
                  )}
                  getOptionLabel={(item) => item}
                  size='small'
                  // onChange={(event, value) => {
                  //   setLanguage(value)
                  // }}
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      fullWidth
                      // onChange={(e) => setLanguage(e.target.value)}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2.7}>
              <Box sx={{ my: 1 }}>
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  Staff
                </Typography>
                <Autocomplete
                  options={[
                    'Comprehensive Needs',
                    'Housing Needs',
                    'Medical Needs',
                    'Employment Needs',
                  ]}
                  getOptionLabel={(item) => item}
                  size='small'
                  renderOption={(option, state) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3,
                        px: 3,
                        py: 1,
                      }}
                    >
                      <Avatar sx={{ width: 40, height: 40 }} />
                      <Typography>Kebede</Typography>
                    </Box>
                  )}
                  // onChange={(event, value) => {
                  //   setLanguage(value)
                  // }}
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      fullWidth
                      // onChange={(e) => setLanguage(e.target.value)}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2.7}>
              <Box sx={{ my: 1 }}>
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  Date
                </Typography>
                <DatePicker slotProps={{ textField: { size: 'small' } }} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={1.2}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  pb: 1,
                }}
              >
                <Button
                  variant='contained'
                  sx={{
                    width: 400,
                    bgcolor: '#4F6D9A',
                    '&:hover': { bgcolor: '#4F6F9A' },
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <TypeCard type='Submitted' color='#3376D6' value={28} />
            <Divider flexItem orientation='vertical' />
            <TypeCard type='Pending' color='#C2A719' value={28} />
            <Divider flexItem orientation='vertical' />
            <TypeCard type='Not Submitted' color='#7B91B3' value={28} />
            <Divider flexItem orientation='vertical' />
            <TypeCard type='Approved' color='#39DA90' value={28} />
            <Divider flexItem orientation='vertical' />
            <TypeCard type='Rejected' color='#D27070' value={28} />
            <Divider flexItem orientation='vertical' />
            <TypeCard type='Denied' color='#DA9A9A' value={28} />
            <Divider flexItem orientation='vertical' sx={{ height: 1 }} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
          p: 3,
        }}
      >
        <Fab
          aria-label='left'
          onClick={slideLeft}
          sx={{ bgcolor: 'white', width: 50, height: 50 }}
        >
          <ChevronLeft />
        </Fab>
        <Box
          id='cardslider'
          sx={{
            display: 'flex',
            gap: 5,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
            },
            scrollBehaviour: 'smooth',
            width: '91.5%',
            // border: '1px solid red',
          }}
        >
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>Medhin Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>ABC Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>CBE Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>
                MedGHIhin Insurance
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>{' '}
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>Medhin Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>ABC Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>CBE Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>
                MedGHIhin Insurance
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>{' '}
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>Medhin Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>ABC Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>CBE Insurance</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              borderRadius: 5,
              py: 2,
              width: '50%',
              height: 430,
            }}
          >
            <Box
              sx={{
                bgcolor: '#3376D6',
                pl: 3,
                py: 1,
                pr: 5,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
                width: 'fit-content',
              }}
            >
              <Typography sx={{ color: 'white' }}>
                MedGHIhin Insurance
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 372,
              }}
            >
              <PieChart width={400} height={300}>
                <Pie
                  data={data}
                  cx='50%'
                  cy='50%'
                  outerRadius={130}
                  innerRadius={90}
                  dataKey='value'
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
              <Box
                sx={{
                  py: 1,
                  gap: 2,
                  height: '100%',
                  px: 5,
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                  width: 300,
                }}
              >
                <TypeChartCard type='Paid' value={500} color='#8D1FB4' />
                <TypeChartCard type='Submitted' value={500} color='#8D1FB4' />
                <TypeChartCard
                  type='Not Submitted'
                  value={200}
                  color='#9891FF'
                />
                <TypeChartCard type='Approved' value={150} color='#3376D6' />
                <TypeChartCard type='Pending' value={100} color='#FFBB00' />
                <TypeChartCard type='Non Billable' value={34} color='#8D1FB4' />
                <TypeChartCard type='Denied' value={50} color='#E10070' />
                <TypeChartCard type='Rejected' value={50} color='#C44141' />
              </Box>
            </Box>
          </Box>
        </Box>
        <Fab
          aria-label='right'
          onClick={slideRight}
          sx={{ bgcolor: 'white', width: 50, height: 50 }}
        >
          <ChevronRight />
        </Fab>
      </Box>
    </Box>
  )
}
