import {
  Add,
  AddCircleOutlineOutlined,
  CalendarMonth,
  Delete,
  Done,
  Edit,
  Flag,
  FlagOutlined,
  Group,
  ManageAccounts,
  OpenInFull,
  OpenWithSharp,
  Timeline,
  Visibility,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Popover,
  Radio,
  RadioGroup,
  Slide,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useAsyncError, useNavigate, useParams } from 'react-router-dom'
import FileUpload from '../components/File/FileUpload'
import Appointments from './Appointments'
import AssignCaseManager from '../components/CaseManager/AssignCaseManager'
import AddGoal from '../components/Goal/AddGoal'
import AddAppointment from '../components/Appointment/AddAppointment'
import { useFetch } from '../hooks/useFetch'
import moment from 'moment'
import AddLedger from '../components/Ledger/AddClientLedger'
import LedgerDetail from '../components/Ledger/LedgerDetail'
import { usePost } from '../hooks/usePost'
import { DatePicker } from '@mui/x-date-pickers'
import Documents from './Documnets'
import { enqueueSnackbar } from 'notistack'
import ExpandedNotesAndChats from '../components/Clients/ExpandedNotesAndChats'
import ClientTimeline from '../components/Clients/ClientTimeline'
import dayjs from 'dayjs'
import { usePatch } from '../hooks/usePatch'
import ClientDocuments from '../components/Clients/ClientDocuments'
import AppointmentDetails from '../components/Appointment/AppointmentDetails'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
function a11yPropsApp(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function CustomTabPanelApp(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function CustomTabPanelChat(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
function a11yPropsChat(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export function GoalCard() {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'cener',
          px: 3,
          py: 2,
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ color: '#889CB9', fontSize: 13 }}>
          <span style={{ fontWeight: 'bold', color: 'black' }}>
            Patient Registration
          </span>{' '}
          (4 Tasks)
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <CalendarMonth sx={{ color: '#6F7070' }} />
          <Typography
            sx={{
              fontSize: 12,
              color: '#6F7070',
              fontWeight: 'bold',
            }}
          >
            Jan 12, Wed
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          px: 3,
        }}
      >
        <LinearProgress
          variant='determinate'
          value={40}
          sx={{ height: 8, borderRadius: 3, bgcolor: '#F4FAFF' }}
        />
      </Box>
      <Box
        sx={{
          mx: 3,
          my: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          bgcolor: '#F4FAFF',
          p: 1.5,
          borderRadius: 10,
          border: '1px solid #E7EFF6',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Avatar sx={{ width: 30, height: 30, position: 'absolute' }}></Avatar>
          <Box
            sx={{
              position: 'absolute',
              left: 23,
              p: 0.3,
              borderRadius: '100%',
              bgcolor: '#F4FAFF',
            }}
          >
            <Avatar
              sx={{
                width: 30,
                height: 30,
              }}
            ></Avatar>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              left: 46,
              p: 0.3,
              borderRadius: '100%',
              bgcolor: '#F4FAFF',
            }}
          >
            <Avatar
              sx={{
                width: 30,
                height: 30,
              }}
            ></Avatar>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Flag sx={{ color: '#BF0C0C' }} />
          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
            High Priority
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 3,
          mx: 3,
        }}
      />
    </Box>
  )
}

export default function ClientDetail() {
  const navigate = useNavigate()
  const { id }: any = useParams()
  console.log({ id })
  const [openFlag, setOpenFlag] = useState(false)
  const [value, setValue] = useState(0)
  const [valueApp, setValueApp] = useState(0)
  const [valueChat, setValueChat] = useState(0)
  const [flagItem, setFlagItem] = useState() as any
  const [flagged, setFlagged] = useState<string[]>([])
  const [expandAppointment, setExpandAppointment] = useState(false)
  const [openAssign, setOpenAssign] = useState(false)
  const [openAddGoal, setOpenAddGoal] = useState(false)
  const [openAddAppointment, setOpenAddAppointment] = useState(false)
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false)
  const [appontment, setAppointment] = useState() as any
  const [openAddLedger, setopenAddLedger] = useState(false)
  const [openLedgerDetail, setOpenLedgerDetail] = useState(false)
  const [isWriring, setIsWriting] = useState(false)
  const [expandNotes, setExpandNotes] = useState(false)
  const [ledgerId, setLedgerId] = useState() as any
  const [selectedNoteType, setSelectedNoteType] = useState() as any
  const [noteText, setNoteText] = useState() as any
  const [noteDate, setNoteDate] = useState() as any
  const [noteDuration, setNoteDuration] = useState() as any
  const [refetchGoal, setRefetchGoal] = useState(false)
  const [openTimeline, setOpenTimeline] = useState(false)
  const [isEditingConfig, setIsEditingConfig] = useState(false)
  const [isEditingProgram, setIsEditingProgram] = useState(false)
  const [intakeEntryToUpdate, setIntakeEntryToUpdate] = useState() as any
  const [programEntryToUpdate, setProgramEntryToUpdate] = useState() as any
  const [intakeValues, setIntakeValues] = useState<
    {
      intake_entry_id: string
      intake_category_field_id: string
      intake_entry_value_id: string
      values: string[]
    }[]
  >([])

  const { data: carePlanNotes } = useFetch(
    'getCarePlanNotes',
    `/public/clients/${id}/care_plan_notes`
  )
  console.log({ carePlanNotes })

  const [programId, setProgramId] = useState() as any

  console.log({ programId })
  console.log({ intakeValues })

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popId = open ? 'simple-popover' : undefined

  const { data: client, refetch } = useFetch(
    'oneclient',
    `/public/clients/${id}`
  )
  const [selectedNoteProgram, setSelectedNoteProgram] = useState(
    client?.client?.programs?.[0]?.id
  )

  const { mutate: getClientAppointment, data: clientAppointments } = usePost(
    `/public/appointment/participants/${id}/appointments/list`
  )

  console.log({ clientAppointments })

  const { mutate: getClientLedger, data: clientLedgers } = usePost(
    `/public/clients/${id}/ledgers/list`
  )

  const { mutate: getClientNotes, data: clientNotes } = usePost(
    `/public/clients/notes/list`
  )

  const { data: goalData, mutate } = usePost('/public/goal/goals/list')

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    mutate(datap)
  }, [refetchGoal])

  console.log({ goalData })

  const {
    mutate: updateIntake,
    isLoading: isEntryUpdateLoading,
    isSuccess: isEntryUpdateSuccess,
    isError: isEntryUpdateError,
    error: entryUpdateError,
  } = usePost(`/public/configs/intakes/${id}/${client?.client?.intake?.id}`)

  const {
    mutate: updateProgramEntry,
    isLoading: isProgramEntryUpdateLoading,
    isSuccess: isProgramEntryUpdateSuccess,
    isError: isProgramEntryUpdateError,
    error: programEntryUpdateError,
  } = usePatch(`/public/configs/programs/entries/${programEntryToUpdate}`)

  const {
    mutate: createNote,
    isError,
    isLoading,
    isSuccess,
    error,
  } = usePost(`/public/clients/notes`)

  console.log({ clientNotes })

  const [refetchNotes, setRefetchNotes] = useState(false)

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
      client_id: id,
    }
    getClientNotes(datap)
  }, [refetchNotes])

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    getClientLedger(datap)
  }, [])

  console.log({ clientLedgers })

  const [refetchAppointemnt, setRefetchAppointment] = useState(false)

  useEffect(() => {
    const datap: any = {
      filters: {
        filters: [
          {
            field: 'client_id',
            equal: {
              value: {
                string_value: `${id}`,
              },
            },
          },
        ],
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    getClientAppointment(datap)
  }, [refetchAppointemnt])

  console.log({ clientAppointments })

  console.log({ client })

  useEffect(() => {
    refetch()
  }, [id])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeApp = (event: React.SyntheticEvent, newValue: number) => {
    setValueApp(newValue)
  }

  const handleChangeChat = (event: React.SyntheticEvent, newValue: number) => {
    setValueChat(newValue)
  }

  const handleSave = () => {
    const data: any = {
      client_id: id,
      value: noteText,
      program_id: selectedNoteProgram,
      note_type_id: selectedNoteType,
      duration:
        noteDuration === '15 Min'
          ? '15m'
          : noteDuration === '30 Min'
          ? '30m'
          : noteDuration === '45 Min'
          ? '45m'
          : '60m',
      date: noteDate,
    }
    createNote(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Note Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetchNotes(!refetchNotes)
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  useEffect(() => {
    if (isEntryUpdateSuccess) {
      enqueueSnackbar('Intake Entry Updated Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      refetch()
      setIsEditingConfig(false)
    }
    if (isEntryUpdateError) {
      enqueueSnackbar(
        entryUpdateError?.response?.data?.message ||
          entryUpdateError?.message ||
          'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isEntryUpdateSuccess, isEntryUpdateError])

  useEffect(() => {
    if (isProgramEntryUpdateSuccess) {
      enqueueSnackbar('Program Entry Updated Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      refetch()
      setIsEditingProgram(false)
    }
    if (isProgramEntryUpdateError) {
      enqueueSnackbar(
        programEntryUpdateError?.response?.data?.message ||
          programEntryUpdateError?.message ||
          'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isProgramEntryUpdateSuccess, isProgramEntryUpdateError])

  function GridItem({ title, value }: { title: string; value: string }) {
    return (
      <Grid container spacing={3} sx={{ my: 2 }}>
        <Grid
          item
          xs={4}
          sx={{
            diplay: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              bgcolor: isEditingConfig ? '' : '#F4FAFF',
              px: 2,
              py: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isEditingConfig ? (
              <TextField defaultValue={value} size='small' />
            ) : (
              <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    )
  }

  function DataItem({ title, info }: { title: string; info: string }) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          position: 'relative',
          cursor: openFlag ? 'pointer' : '',
        }}
        onMouseOver={() => {
          if (openFlag) {
            setFlagItem(title)
          }
        }}
        onMouseOut={() => {
          if (openFlag) {
            setFlagItem()
          }
        }}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
          {title}:{' '}
          <span style={{ fontWeight: 'normal', color: '#09244B' }}>{info}</span>
        </Typography>
        <Box sx={{ position: 'absolute', left: -23, top: -10 }}>
          {flagItem === title && !flagged?.includes(title) ? (
            <Box
              onClick={() => {
                setFlagged((flagged) => [...flagged, title])
              }}
            >
              <FlagOutlined sx={{ color: 'red' }} />
            </Box>
          ) : (
            <></>
          )}
          {flagged?.includes(title) && (
            <Box
              onClick={() => {
                if (openFlag) {
                  setFlagged((flagged) =>
                    flagged?.filter((item) => item !== title)
                  )
                }
              }}
            >
              <Flag sx={{ color: 'red' }} />
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  const onSubmit = () => {
    console.log({ intakeValues })

    const data: any = {
      client_id: id,
      intake_id: client?.client?.intake?.id,
      intake_entry_id: intakeValues?.[0]?.intake_entry_id,
      submit: true,
      values: intakeValues?.map((item) => {
        return {
          ...(item?.intake_entry_value_id && {
            intake_entry_value_id: item?.intake_entry_value_id,
          }),
          intake_category_field_id: item?.intake_category_field_id,
          values: item?.values,
        }
      }),
    }

    console.log(7777777777, { data })
    updateIntake(data)
  }

  const [programValues, setProgramValues] = useState<
    {
      program_entry_id: string
      program_entry_value_id: string
      program_field_id: string
      values: string[]
    }[]
  >([])

  const onProgramSubmit = () => {
    console.log({ programValues })

    const data: any = {
      program_entry_id: programValues?.[0]?.program_entry_id,
      value: programValues?.map((item) => {
        return {
          program_entry_value_id: item?.program_entry_value_id,
          program_field_id: item?.program_field_id,
          values: item?.values,
        }
      }),
    }
    console.log(888888888, { data })
    updateProgramEntry(data)
  }

  return (
    <Modal
      open={true}
      onClose={() => navigate('/clients')}
      closeAfterTransition
    >
      <Slide in={true} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 'calc(100vw - 280px)' },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 4,
                pb: 4,
                pt: 5,
                borderBottom: '1px solid #F7F7F7',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={() => navigate('/clients')}
                >
                  Back
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                    borderBottom: openFlag ? '2px solid #2088E0' : '',
                    py: 0.7,
                    px: 1,
                    border: '1px solid #CDDEEB',
                    borderRadius: 2,
                  }}
                >
                  {openFlag ? <Flag /> : <Flag sx={{ color: '#BBC4DD' }} />}
                  {/* <Box> */}
                  <Typography sx={{ fontSize: 13 }}>Flag</Typography>
                  {/* </Box> */}
                  {openFlag ? (
                    <IconButton
                      sx={{
                        width: 25,
                        height: 15,
                        bgcolor: '#2699FB',
                        borderRadius: 5,
                        '&:hover': {
                          bgcolor: '#3D99E6',
                        },
                      }}
                      onClick={() => {
                        setFlagItem()
                        setOpenFlag(false)
                      }}
                    >
                      <Done
                        sx={{
                          height: 15,
                          width: 15,
                          color: 'white',
                        }}
                      />
                    </IconButton>
                  ) : (
                    <Box
                      sx={{
                        bgcolor: '#EBF6FF',
                        px: 0.5,
                        py: 0.1,
                        borderRadius: 1,
                      }}
                    >
                      <IconButton
                        sx={{ width: 20, height: 20 }}
                        onClick={() => setOpenFlag(true)}
                      >
                        <Edit
                          sx={{ height: 15, width: 15, color: '#3D99E6' }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Box>
              <Typography
                sx={{ fontWeight: 'bold', color: '#1F385B', fontSize: 18 }}
              >
                {(client?.client?.firstName || '') +
                  ' ' +
                  (client?.client?.middleName || '') +
                  ' ' +
                  (client?.client?.lastName || '')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => setOpenAssign(true)}
                  >
                    <ManageAccounts sx={{ color: '#0178DD' }} />
                    <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                      Assign Case M
                    </Typography>
                  </Box>

                  {/* <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <AddCircleOutlineOutlined sx={{ color: '#0178DD' }} />
                    <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                      Add Program
                    </Typography>
                  </Box> */}
                </Box>
                <Divider orientation='vertical' flexItem />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenTimeline(true)}
                >
                  <Timeline sx={{ color: '#0178DD' }} />
                  <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    Timeline
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: 'calc(100vh - 73px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
                position: 'relative',
              }}
            >
              <Grid container sx={{ height: '100%' }}>
                <Grid item xs={12} sm={8.5}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      pt: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label='basic tabs example'
                      variant='scrollable'
                      // scrollButtons='auto'
                    >
                      <Tab
                        label='Summary'
                        {...a11yProps(0)}
                        sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                      />
                      {client?.client?.intake?.intakeCategory?.map(
                        (item: any, index: number) => (
                          <Tab
                            label={item?.title}
                            {...a11yProps(index + 1)}
                            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                          />
                        )
                      )}
                      {client?.client?.programs?.map(
                        (item: any, index: number) => (
                          <Tab
                            label={item?.title}
                            {...a11yProps(
                              client?.client?.intake?.intakeCategory?.length +
                                index +
                                1
                            )}
                            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                          />
                        )
                      )}
                      <Tab
                        label={'Documents'}
                        {...a11yProps(
                          client?.client?.intake?.intakeCategory +
                            client?.client?.intake?.intakeCategory?.length +
                            1
                        )}
                        sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                      />
                      <Tab
                        label={'Ledger'}
                        {...a11yProps(
                          client?.client?.intake?.intakeCategory +
                            client?.client?.intake?.intakeCategory?.length +
                            2
                        )}
                        sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <Box
                      sx={{
                        height: 'calc(100vh - 142px)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          width: 0,
                        },
                        py: 3,
                        px: 5,
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 3 }}
                      >
                        <Avatar
                          sx={{ width: 100, height: 100 }}
                          src={client?.client?.profilePictureUrl}
                          alt={client?.client?.firstName}
                        />
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              columnGap: 3,
                              rowGap: 1,
                            }}
                          >
                            <DataItem
                              title='Name'
                              info={
                                (client?.client?.firstName || '') +
                                ' ' +
                                (client?.client?.middleName || '') +
                                ' ' +
                                (client?.client?.lastName || '')
                              }
                            />
                            <DataItem
                              title='Csr No'
                              info={client?.client?.csrNo}
                            />
                            <DataItem
                              title='DOB'
                              info={moment(client?.client?.dob).format('ll')}
                            />
                            <DataItem
                              title='Gender'
                              info={client?.client?.gender}
                            />
                            <DataItem
                              title='Phone'
                              info={client?.client?.phone}
                            />
                            <DataItem
                              title='Email'
                              info={client?.client?.email}
                            />
                            <DataItem
                              title='Address'
                              info='Addis Ababa, Ethiopia'
                            />
                          </Box>
                          <Box
                            sx={{
                              bgcolor: '#EBF6FF',
                              display: 'flex',
                              flexWrap: 'wrap',
                              columnGap: 5,
                              rowGap: 1,
                              py: 1,
                              px: 2,
                              borderRadius: 2,
                            }}
                          >
                            <DataItem
                              title='Is Veteran'
                              info={client?.client?.isVeteran ? 'Yes' : 'No'}
                            />
                            <DataItem
                              title='Languages'
                              info={client?.client?.language?.join(', ')}
                            />
                          </Box>
                        </Box>
                      </Box>
                      {client?.client?.intake?.intakeCategory?.map(
                        (item: any) => {
                          if (
                            item?.intakeCategoryFields?.some(
                              (elt: any) => elt?.vital === true
                            )
                          ) {
                            return (
                              <Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 3,
                                    my: 3,
                                  }}
                                >
                                  <Typography sx={{ color: '#3794E4' }}>
                                    {item?.title}
                                  </Typography>
                                  <Divider sx={{ color: 'red', flexGrow: 1 }} />
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    columnGap: 5,
                                    rowGap: 1,
                                  }}
                                >
                                  {item?.intakeCategoryFields?.map(
                                    (el: any) => {
                                      if (el?.vital) {
                                        return (
                                          <DataItem
                                            title={el?.name}
                                            info={client?.client?.intakeEntries?.intakeEntryValues
                                              ?.filter(
                                                (elt: any) =>
                                                  elt?.intakeCategoryFieldId ===
                                                  el?.id
                                              )[0]
                                              ?.values?.join(', ')}
                                          />
                                        )
                                      }
                                    }
                                  )}
                                </Box>
                              </Box>
                            )
                          }
                        }
                      )}
                      {client?.client?.programs?.map((item: any) => {
                        if (
                          item?.programFields?.some(
                            (elt: any) => elt?.vital === true
                          )
                        ) {
                          return (
                            <Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 3,
                                  my: 3,
                                }}
                              >
                                <Typography sx={{ color: '#3794E4' }}>
                                  {item?.title}
                                </Typography>
                                <Divider sx={{ color: 'red', flexGrow: 1 }} />
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  columnGap: 5,
                                  rowGap: 1,
                                }}
                              >
                                {item?.programFields?.map((el: any) => {
                                  if (el?.vital) {
                                    return (
                                      <DataItem
                                        title={el?.name}
                                        info={client?.client?.programEntries
                                          ?.filter(
                                            (pre: any) =>
                                              pre?.programId === item?.id
                                          )?.[0]
                                          ?.programEntryValues?.filter(
                                            (elt: any) =>
                                              elt?.programFieldId === el?.id
                                          )?.[0]
                                          ?.values?.join(', ')}
                                      />
                                    )
                                  }
                                })}
                              </Box>
                            </Box>
                          )
                        }
                      })}
                    </Box>
                  </CustomTabPanel>
                  {client?.client?.intake?.intakeCategory?.map(
                    (item: any, index: number) => (
                      <CustomTabPanel value={value} index={index + 1}>
                        <Box
                          sx={{
                            p: 5,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              startIcon={<Edit />}
                              onClick={() => {
                                setIsEditingConfig(true)
                                setIntakeEntryToUpdate(
                                  client?.client?.intakeEntries?.id
                                )
                              }}
                            >
                              Edit
                            </Button>
                          </Box>
                          <Box>
                            <Box
                              sx={{
                                height: 'calc(100vh - 310px)',
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                  width: 0,
                                },
                              }}
                            >
                              {item?.intakeCategoryFields?.map((field: any) => {
                                if (!isEditingConfig) {
                                  return (
                                    <GridItem
                                      title={field?.name}
                                      value={client?.client?.intakeEntries?.intakeEntryValues
                                        ?.filter(
                                          (elt: any) =>
                                            elt?.intakeCategoryFieldId ===
                                            field?.id
                                        )[0]
                                        ?.values?.join(', ')}
                                    />
                                  )
                                } else {
                                  return (
                                    <Box>
                                      <Grid item xs={6}>
                                        <Box>
                                          {field?.type === 'Text' ? (
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: 14,
                                                  py: 0.5,
                                                }}
                                              >
                                                {field?.name}
                                              </Typography>
                                              <TextField
                                                size='small'
                                                fullWidth
                                                defaultValue={client?.client?.intakeEntries?.intakeEntryValues
                                                  ?.filter(
                                                    (elt: any) =>
                                                      elt?.intakeCategoryFieldId ===
                                                      field?.id
                                                  )[0]
                                                  ?.values?.join(', ')}
                                                onChange={(e) => {
                                                  const existingIndex =
                                                    intakeValues.findIndex(
                                                      (item) =>
                                                        item.intake_category_field_id ===
                                                        field?.id
                                                    )
                                                  if (existingIndex !== -1) {
                                                    setIntakeValues(
                                                      (prevState) => {
                                                        const updatedValues = [
                                                          ...prevState,
                                                        ]
                                                        updatedValues[
                                                          existingIndex
                                                        ].values = [
                                                          e.target.value,
                                                        ]
                                                        return updatedValues
                                                      }
                                                    )
                                                  } else {
                                                    // FieldId doesn't exist, create a new object and add it to the state
                                                    setIntakeValues(
                                                      (prevState) => [
                                                        ...prevState,
                                                        {
                                                          intake_category_field_id:
                                                            field?.id,
                                                          intake_entry_value_id:
                                                            client?.client?.intakeEntries?.intakeEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.intakeCategoryFieldId ===
                                                                field?.id
                                                            )[0]?.id,
                                                          intake_entry_id:
                                                            client?.client
                                                              ?.intakeEntries
                                                              ?.id,
                                                          values: [
                                                            e.target.value,
                                                          ],
                                                        },
                                                      ]
                                                    )
                                                  }
                                                }}
                                              />
                                            </Box>
                                          ) : field?.type === 'Dropdown' ? (
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: 14,
                                                  py: 0.5,
                                                }}
                                              >
                                                {field?.name}
                                              </Typography>
                                              <Autocomplete
                                                options={field?.values || []}
                                                getOptionLabel={(item: any) =>
                                                  item
                                                }
                                                size='small'
                                                defaultValue={
                                                  client?.client?.intakeEntries?.intakeEntryValues?.filter(
                                                    (elt: any) =>
                                                      elt?.intakeCategoryFieldId ===
                                                      field?.id
                                                  )[0]?.values[0]
                                                }
                                                onChange={(event, value) => {
                                                  const existingIndex =
                                                    intakeValues.findIndex(
                                                      (item) =>
                                                        item.intake_category_field_id ===
                                                        field?.id
                                                    )
                                                  if (existingIndex !== -1) {
                                                    setIntakeValues(
                                                      (prevState) => {
                                                        const updatedValues = [
                                                          ...prevState,
                                                        ]
                                                        updatedValues[
                                                          existingIndex
                                                        ].values = [value]
                                                        return updatedValues
                                                      }
                                                    )
                                                  } else {
                                                    setIntakeValues(
                                                      (prevState) => [
                                                        ...prevState,
                                                        {
                                                          intake_category_field_id:
                                                            field?.id,
                                                          intake_entry_value_id:
                                                            client?.client?.intakeEntries?.intakeEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.intakeCategoryFieldId ===
                                                                field?.id
                                                            )[0]?.id,
                                                          intake_entry_id:
                                                            client?.client
                                                              ?.intakeEntries
                                                              ?.id,
                                                          values: [value],
                                                        },
                                                      ]
                                                    )
                                                  }
                                                }}
                                                fullWidth
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    size='small'
                                                    fullWidth
                                                    sx={{
                                                      bgcolor: 'white',
                                                    }}
                                                  />
                                                )}
                                              />
                                            </Box>
                                          ) : field?.type === 'Date' ? (
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: 14,
                                                  py: 0.5,
                                                }}
                                              >
                                                {field?.name}
                                              </Typography>
                                              <DatePicker
                                                //   value={appDate}
                                                defaultValue={dayjs(
                                                  client?.client?.intakeEntries?.intakeEntryValues
                                                    ?.filter(
                                                      (elt: any) =>
                                                        elt?.intakeCategoryFieldId ===
                                                        field?.id
                                                    )[0]
                                                    ?.values[0]?.split('T')[0]
                                                )}
                                                onChange={(newValue: any) => {
                                                  const existingIndex =
                                                    intakeValues.findIndex(
                                                      (item) =>
                                                        item.intake_category_field_id ===
                                                        field?.id
                                                    )
                                                  if (existingIndex !== -1) {
                                                    setIntakeValues(
                                                      (prevState) => {
                                                        const updatedValues = [
                                                          ...prevState,
                                                        ]
                                                        updatedValues[
                                                          existingIndex
                                                        ].values = [
                                                          newValue?.$d
                                                            ?.toISOString()
                                                            ?.replace(
                                                              '.000',
                                                              ''
                                                            ),
                                                        ]
                                                        return updatedValues
                                                      }
                                                    )
                                                  } else {
                                                    setIntakeValues(
                                                      (prevState) => [
                                                        ...prevState,
                                                        {
                                                          intake_category_field_id:
                                                            field?.id,
                                                          intake_entry_value_id:
                                                            client?.client?.intakeEntries?.intakeEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.intakeCategoryFieldId ===
                                                                field?.id
                                                            )[0]?.id,
                                                          intake_entry_id:
                                                            client?.client
                                                              ?.intakeEntries
                                                              ?.id,
                                                          values: [
                                                            newValue?.$d
                                                              ?.toISOString()
                                                              ?.replace(
                                                                '.000',
                                                                ''
                                                              ),
                                                          ],
                                                        },
                                                      ]
                                                    )
                                                  }
                                                }}
                                                sx={{ width: '100%' }}
                                                slotProps={{
                                                  textField: {
                                                    size: 'small',
                                                  },
                                                }}
                                              />
                                            </Box>
                                          ) : field?.type === 'Checkbox' ? (
                                            <Box
                                              sx={{
                                                // display: 'flex',
                                                // alignItems: 'center',
                                                // gap: 3,
                                                py: 1,
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: 14,
                                                  py: 0.5,
                                                }}
                                              >
                                                {field?.name}
                                              </Typography>
                                              {field?.values?.map(
                                                (elt: any) => (
                                                  <FormControlLabel
                                                    name={elt}
                                                    label={elt}
                                                    onChange={(e) => {
                                                      console.log({
                                                        intakeId: field?.id,
                                                      })
                                                      const existingIndex =
                                                        intakeValues.findIndex(
                                                          (item) =>
                                                            item.intake_category_field_id ===
                                                            field?.id
                                                        )
                                                      if (
                                                        existingIndex !== -1
                                                      ) {
                                                        setIntakeValues(
                                                          (prevState) => {
                                                            const updatedValues =
                                                              [...prevState]
                                                            if (
                                                              updatedValues[
                                                                existingIndex
                                                              ].values?.includes(
                                                                elt
                                                              )
                                                            ) {
                                                              updatedValues[
                                                                existingIndex
                                                              ].values =
                                                                updatedValues[
                                                                  existingIndex
                                                                ].values?.filter(
                                                                  (el) =>
                                                                    el !== elt
                                                                )
                                                            } else {
                                                              updatedValues[
                                                                existingIndex
                                                              ].values?.push(
                                                                elt
                                                              )
                                                            }
                                                            return updatedValues
                                                          }
                                                        )
                                                      } else {
                                                        setIntakeValues(
                                                          (prevState) => [
                                                            ...prevState,
                                                            {
                                                              intake_category_field_id:
                                                                field?.id,
                                                              intake_entry_value_id:
                                                                client?.client?.intakeEntries?.intakeEntryValues?.filter(
                                                                  (elt: any) =>
                                                                    elt?.intakeCategoryFieldId ===
                                                                    field?.id
                                                                )[0]?.id,
                                                              intake_entry_id:
                                                                client?.client
                                                                  ?.intakeEntries
                                                                  ?.id,
                                                              values: [elt],
                                                            },
                                                          ]
                                                        )
                                                      }
                                                    }}
                                                    control={
                                                      <Checkbox
                                                        defaultChecked={client?.client?.intakeEntries?.intakeEntryValues
                                                          ?.filter(
                                                            (elt: any) =>
                                                              elt?.intakeCategoryFieldId ===
                                                              field?.id
                                                          )[0]
                                                          ?.values?.includes(
                                                            elt
                                                          )}
                                                      />
                                                    }
                                                  />
                                                )
                                              )}
                                            </Box>
                                          ) : (
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 3,
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: 14,
                                                  py: 0.5,
                                                }}
                                              >
                                                {field?.name}
                                              </Typography>
                                              <RadioGroup
                                                row
                                                aria-labelledby='demo-row-radio-buttons-group-label'
                                                name='row-radio-buttons-group'
                                                defaultValue={
                                                  client?.client?.intakeEntries?.intakeEntryValues?.filter(
                                                    (elt: any) =>
                                                      elt?.intakeCategoryFieldId ===
                                                      field?.id
                                                  )[0]?.values?.[0]
                                                }
                                                onChange={(e) => {
                                                  console.log({
                                                    intakeId: field?.id,
                                                  })

                                                  const existingIndex =
                                                    intakeValues.findIndex(
                                                      (item) => {
                                                        return (
                                                          item.intake_category_field_id ===
                                                          field?.id
                                                        )
                                                      }
                                                    )
                                                  console.log({
                                                    existingIndex,
                                                  })
                                                  if (existingIndex !== -1) {
                                                    setIntakeValues(
                                                      (prevState) => {
                                                        const updatedValues = [
                                                          ...prevState,
                                                        ]
                                                        updatedValues[
                                                          existingIndex
                                                        ].values = [
                                                          e.target.value,
                                                        ]
                                                        return updatedValues
                                                      }
                                                    )
                                                  } else {
                                                    setIntakeValues(
                                                      (prevState) => [
                                                        ...prevState,
                                                        {
                                                          intake_category_field_id:
                                                            field?.id,
                                                          intake_entry_value_id:
                                                            client?.client?.intakeEntries?.intakeEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.intakeCategoryFieldId ===
                                                                field?.id
                                                            )[0]?.id,
                                                          intake_entry_id:
                                                            client?.client
                                                              ?.intakeEntries
                                                              ?.id,
                                                          values: [
                                                            e.target.value,
                                                          ],
                                                        },
                                                      ]
                                                    )
                                                  }
                                                }}
                                              >
                                                {field?.values?.map(
                                                  (elt: any) => (
                                                    <FormControlLabel
                                                      value={elt}
                                                      control={
                                                        <Radio
                                                          defaultChecked={true}
                                                        />
                                                      }
                                                      label={elt}
                                                    />
                                                  )
                                                )}
                                              </RadioGroup>
                                            </Box>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Box>
                                  )
                                }
                              })}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: 3,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: 120,
                                  height: 40,
                                  bgcolor: '#EFEFEF',
                                  borderRadius: 2,
                                  cursor: 'pointer',
                                }}
                                onClick={() => setIsEditingConfig(false)}
                              >
                                <Typography
                                  sx={{ fontSize: 13, fontWeight: 'bold' }}
                                >
                                  Cancel
                                </Typography>
                              </Box>
                              <Button variant='contained' onClick={onSubmit}>
                                {isEntryUpdateLoading ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    <CircularProgress
                                      sx={{ color: 'white' }}
                                      size={20}
                                    />
                                    <Typography>saving...</Typography>
                                  </Box>
                                ) : (
                                  <Typography
                                    sx={{ fontWeight: 'bold', fontSize: 13 }}
                                  >
                                    Save
                                  </Typography>
                                )}
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </CustomTabPanel>
                    )
                  )}
                  {client?.client?.programs?.map((item: any, index: number) => (
                    <CustomTabPanel
                      value={value}
                      index={
                        client?.client?.intake?.intakeCategory?.length +
                        index +
                        1
                      }
                    >
                      <Box
                        sx={{
                          p: 5,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            startIcon={<Edit />}
                            onClick={() => {
                              setIsEditingProgram(true)
                              setProgramEntryToUpdate(
                                client?.client?.programEntries?.filter(
                                  (pre: any) => pre?.programId === item?.id
                                )?.[0]?.id
                              )
                            }}
                          >
                            Edit
                          </Button>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              height: 'calc(100vh - 310px)',
                              overflowY: 'auto',
                              '&::-webkit-scrollbar': {
                                width: 0,
                              },
                            }}
                          >
                            {item?.programFields?.map((field: any) => {
                              if (!isEditingProgram) {
                                return (
                                  <GridItem
                                    title={field?.name}
                                    value={client?.client?.programEntries
                                      ?.filter(
                                        (pre: any) =>
                                          pre?.programId === item?.id
                                      )?.[0]
                                      ?.programEntryValues?.filter(
                                        (elt: any) =>
                                          elt?.programFieldId === field?.id
                                      )?.[0]
                                      ?.values?.join(', ')}
                                  />
                                )
                              } else {
                                return (
                                  <Box>
                                    <Grid item xs={6}>
                                      <Box>
                                        {field?.type === 'Text' ? (
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 14,
                                                py: 0.5,
                                              }}
                                            >
                                              {field?.name}
                                            </Typography>
                                            <TextField
                                              size='small'
                                              fullWidth
                                              defaultValue={
                                                client?.client?.programEntries
                                                  ?.filter(
                                                    (pre: any) =>
                                                      pre?.programId ===
                                                      item?.id
                                                  )?.[0]
                                                  ?.programEntryValues?.filter(
                                                    (elt: any) =>
                                                      elt?.programFieldId ===
                                                      field?.id
                                                  )?.[0]?.values[0]
                                              }
                                              onChange={(e) => {
                                                setProgramId(item?.id)
                                                const existingIndex =
                                                  programValues.findIndex(
                                                    (item) =>
                                                      item.program_field_id ===
                                                      field?.id
                                                  )
                                                if (existingIndex !== -1) {
                                                  setProgramValues(
                                                    (prevState) => {
                                                      const updatedValues = [
                                                        ...prevState,
                                                      ]
                                                      updatedValues[
                                                        existingIndex
                                                      ].values = [
                                                        e.target.value,
                                                      ]
                                                      return updatedValues
                                                    }
                                                  )
                                                } else {
                                                  // FieldId doesn't exist, create a new object and add it to the state
                                                  setProgramValues(
                                                    (prevState) => [
                                                      ...prevState,
                                                      {
                                                        program_field_id:
                                                          field?.id,
                                                        program_entry_id:
                                                          client?.client?.programEntries
                                                            ?.filter(
                                                              (pre: any) =>
                                                                pre?.programId ===
                                                                item?.id
                                                            )?.[0]
                                                            ?.programEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.programFieldId ===
                                                                field?.id
                                                            )?.[0]
                                                            ?.programEntryId,
                                                        program_entry_value_id:
                                                          client?.client?.programEntries
                                                            ?.filter(
                                                              (pre: any) =>
                                                                pre?.programId ===
                                                                item?.id
                                                            )?.[0]
                                                            ?.programEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.programFieldId ===
                                                                field?.id
                                                            )?.[0]?.id,
                                                        values: [
                                                          e.target.value,
                                                        ],
                                                      },
                                                    ]
                                                  )
                                                }
                                              }}
                                            />
                                          </Box>
                                        ) : field?.type === 'Dropdown' ? (
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 14,
                                                py: 0.5,
                                              }}
                                            >
                                              {field?.name}
                                            </Typography>
                                            <Autocomplete
                                              options={field?.values || []}
                                              getOptionLabel={(item: any) =>
                                                item
                                              }
                                              size='small'
                                              defaultValue={
                                                client?.client?.programEntries
                                                  ?.filter(
                                                    (pre: any) =>
                                                      pre?.programId ===
                                                      item?.id
                                                  )?.[0]
                                                  ?.programEntryValues?.filter(
                                                    (elt: any) =>
                                                      elt?.programFieldId ===
                                                      field?.id
                                                  )?.[0]?.values[0]
                                              }
                                              onChange={(event, value) => {
                                                setProgramId(item?.id)
                                                const existingIndex =
                                                  programValues.findIndex(
                                                    (item) =>
                                                      item.program_field_id ===
                                                      field?.id
                                                  )
                                                if (existingIndex !== -1) {
                                                  setProgramValues(
                                                    (prevState) => {
                                                      const updatedValues = [
                                                        ...prevState,
                                                      ]
                                                      updatedValues[
                                                        existingIndex
                                                      ].values = [value]
                                                      return updatedValues
                                                    }
                                                  )
                                                } else {
                                                  // FieldId doesn't exist, create a new object and add it to the state
                                                  setProgramValues(
                                                    (prevState) => [
                                                      ...prevState,
                                                      {
                                                        program_field_id:
                                                          field?.id,
                                                        program_entry_id:
                                                          client?.client?.programEntries
                                                            ?.filter(
                                                              (pre: any) =>
                                                                pre?.programId ===
                                                                item?.id
                                                            )?.[0]
                                                            ?.programEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.programFieldId ===
                                                                field?.id
                                                            )?.[0]
                                                            ?.programEntryId,
                                                        program_entry_value_id:
                                                          client?.client?.programEntries
                                                            ?.filter(
                                                              (pre: any) =>
                                                                pre?.programId ===
                                                                item?.id
                                                            )?.[0]
                                                            ?.programEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.programFieldId ===
                                                                field?.id
                                                            )?.[0]?.id,
                                                        values: [value],
                                                      },
                                                    ]
                                                  )
                                                }
                                              }}
                                              fullWidth
                                              sx={{ width: '100%' }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  size='small'
                                                  fullWidth
                                                  sx={{ bgcolor: 'white' }}
                                                />
                                              )}
                                            />
                                          </Box>
                                        ) : field?.type === 'Date' ? (
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 14,
                                                py: 0.5,
                                              }}
                                            >
                                              {field?.name}
                                            </Typography>
                                            <DatePicker
                                              //   value={appDate}
                                              defaultValue={dayjs(
                                                client?.client?.programEntries
                                                  ?.filter(
                                                    (pre: any) =>
                                                      pre?.programId ===
                                                      item?.id
                                                  )?.[0]
                                                  ?.programEntryValues?.filter(
                                                    (elt: any) =>
                                                      elt?.programFieldId ===
                                                      field?.id
                                                  )?.[0]
                                                  ?.values?.[0]?.split('T')[0]
                                              )}
                                              onChange={(newValue: any) => {
                                                setProgramId(item?.id)
                                                const existingIndex =
                                                  programValues.findIndex(
                                                    (item) =>
                                                      item.program_field_id ===
                                                      field?.id
                                                  )
                                                if (existingIndex !== -1) {
                                                  setProgramValues(
                                                    (prevState) => {
                                                      const updatedValues = [
                                                        ...prevState,
                                                      ]
                                                      updatedValues[
                                                        existingIndex
                                                      ].values = [
                                                        newValue?.$d
                                                          ?.toISOString()
                                                          ?.replace('.000', ''),
                                                      ]
                                                      return updatedValues
                                                    }
                                                  )
                                                } else {
                                                  // FieldId doesn't exist, create a new object and add it to the state
                                                  setProgramValues(
                                                    (prevState) => [
                                                      ...prevState,
                                                      {
                                                        program_field_id:
                                                          field?.id,
                                                        program_entry_id:
                                                          client?.client?.programEntries
                                                            ?.filter(
                                                              (pre: any) =>
                                                                pre?.programId ===
                                                                item?.id
                                                            )?.[0]
                                                            ?.programEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.programFieldId ===
                                                                field?.id
                                                            )?.[0]
                                                            ?.programEntryId,
                                                        program_entry_value_id:
                                                          client?.client?.programEntries
                                                            ?.filter(
                                                              (pre: any) =>
                                                                pre?.programId ===
                                                                item?.id
                                                            )?.[0]
                                                            ?.programEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.programFieldId ===
                                                                field?.id
                                                            )?.[0]?.id,
                                                        values: [
                                                          newValue?.$d
                                                            ?.toISOString()
                                                            ?.replace(
                                                              '.000',
                                                              ''
                                                            ),
                                                        ],
                                                      },
                                                    ]
                                                  )
                                                }
                                              }}
                                              sx={{ width: '100%' }}
                                              slotProps={{
                                                textField: { size: 'small' },
                                              }}
                                            />
                                          </Box>
                                        ) : field?.type === 'Checkbox' ? (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: 3,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 14,
                                                py: 0.5,
                                              }}
                                            >
                                              {field?.name}
                                            </Typography>
                                            {field?.values?.map((elt: any) => (
                                              <FormControlLabel
                                                name={elt}
                                                label={elt}
                                                onChange={(e) => {
                                                  setProgramId(item?.id)
                                                  console.log({
                                                    intakeId: field?.id,
                                                  })
                                                  const existingIndex =
                                                    programValues.findIndex(
                                                      (item) =>
                                                        item.program_field_id ===
                                                        field?.id
                                                    )
                                                  if (existingIndex !== -1) {
                                                    setProgramValues(
                                                      (prevState) => {
                                                        const updatedValues = [
                                                          ...prevState,
                                                        ]
                                                        if (
                                                          updatedValues[
                                                            existingIndex
                                                          ].values?.includes(
                                                            elt
                                                          )
                                                        ) {
                                                          updatedValues[
                                                            existingIndex
                                                          ].values =
                                                            updatedValues[
                                                              existingIndex
                                                            ].values?.filter(
                                                              (el) => el !== elt
                                                            )
                                                        } else {
                                                          updatedValues[
                                                            existingIndex
                                                          ].values?.push(elt)
                                                        }
                                                        return updatedValues
                                                      }
                                                    )
                                                  } else {
                                                    setProgramValues(
                                                      (prevState) => [
                                                        ...prevState,
                                                        {
                                                          program_field_id:
                                                            field?.id,
                                                          program_entry_id:
                                                            client?.client?.programEntries
                                                              ?.filter(
                                                                (pre: any) =>
                                                                  pre?.programId ===
                                                                  item?.id
                                                              )?.[0]
                                                              ?.programEntryValues?.filter(
                                                                (elt: any) =>
                                                                  elt?.programFieldId ===
                                                                  field?.id
                                                              )?.[0]
                                                              ?.programEntryId,
                                                          program_entry_value_id:
                                                            client?.client?.programEntries
                                                              ?.filter(
                                                                (pre: any) =>
                                                                  pre?.programId ===
                                                                  item?.id
                                                              )?.[0]
                                                              ?.programEntryValues?.filter(
                                                                (elt: any) =>
                                                                  elt?.programFieldId ===
                                                                  field?.id
                                                              )?.[0]?.id,
                                                          values: [elt],
                                                        },
                                                      ]
                                                    )
                                                  }
                                                }}
                                                control={
                                                  <Checkbox
                                                    defaultChecked={client?.client?.programEntries
                                                      ?.filter(
                                                        (pre: any) =>
                                                          pre?.programId ===
                                                          item?.id
                                                      )?.[0]
                                                      ?.programEntryValues?.filter(
                                                        (elt: any) =>
                                                          elt?.programFieldId ===
                                                          field?.id
                                                      )?.[0]
                                                      ?.values?.includes(elt)}
                                                  />
                                                }
                                              />
                                            ))}
                                          </Box>
                                        ) : (
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: 3,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 14,
                                                py: 0.5,
                                              }}
                                            >
                                              {field?.name}
                                            </Typography>
                                            <RadioGroup
                                              row
                                              aria-labelledby='demo-row-radio-buttons-group-label'
                                              name='row-radio-buttons-group'
                                              defaultValue={
                                                client?.client?.programEntries
                                                  ?.filter(
                                                    (pre: any) =>
                                                      pre?.programId ===
                                                      item?.id
                                                  )?.[0]
                                                  ?.programEntryValues?.filter(
                                                    (elt: any) =>
                                                      elt?.programFieldId ===
                                                      field?.id
                                                  )?.[0]?.values[0]
                                              }
                                              onChange={(e) => {
                                                setProgramId(item?.id)
                                                const existingIndex =
                                                  programValues.findIndex(
                                                    (item) => {
                                                      return (
                                                        item.program_field_id ===
                                                        field?.id
                                                      )
                                                    }
                                                  )
                                                console.log({ existingIndex })
                                                if (existingIndex !== -1) {
                                                  setProgramValues(
                                                    (prevState) => {
                                                      const updatedValues = [
                                                        ...prevState,
                                                      ]
                                                      updatedValues[
                                                        existingIndex
                                                      ].values = [
                                                        e.target.value,
                                                      ]
                                                      return updatedValues
                                                    }
                                                  )
                                                } else {
                                                  // FieldId doesn't exist, create a new object and add it to the state
                                                  setProgramValues(
                                                    (prevState) => [
                                                      ...prevState,
                                                      {
                                                        program_field_id:
                                                          field?.id,
                                                        program_entry_id:
                                                          client?.client?.programEntries
                                                            ?.filter(
                                                              (pre: any) =>
                                                                pre?.programId ===
                                                                item?.id
                                                            )?.[0]
                                                            ?.programEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.programFieldId ===
                                                                field?.id
                                                            )?.[0]
                                                            ?.programEntryId,
                                                        program_entry_value_id:
                                                          client?.client?.programEntries
                                                            ?.filter(
                                                              (pre: any) =>
                                                                pre?.programId ===
                                                                item?.id
                                                            )?.[0]
                                                            ?.programEntryValues?.filter(
                                                              (elt: any) =>
                                                                elt?.programFieldId ===
                                                                field?.id
                                                            )?.[0]?.id,
                                                        values: [
                                                          e.target.value,
                                                        ],
                                                      },
                                                    ]
                                                  )
                                                }
                                              }}
                                            >
                                              {field?.values?.map(
                                                (elt: any) => (
                                                  <FormControlLabel
                                                    value={elt}
                                                    control={<Radio />}
                                                    label={elt}
                                                  />
                                                )
                                              )}
                                            </RadioGroup>
                                          </Box>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Box>
                                )
                              }
                            })}
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              gap: 3,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 120,
                                height: 40,
                                bgcolor: '#EFEFEF',
                                borderRadius: 2,
                                cursor: 'pointer',
                              }}
                              onClick={() => setIsEditingProgram(false)}
                            >
                              <Typography
                                sx={{ fontSize: 13, fontWeight: 'bold' }}
                              >
                                Cancel
                              </Typography>
                            </Box>
                            <Button
                              variant='contained'
                              onClick={onProgramSubmit}
                            >
                              {isProgramEntryUpdateLoading ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                  }}
                                >
                                  <CircularProgress
                                    sx={{ color: 'white' }}
                                    size={20}
                                  />
                                  <Typography>saving...</Typography>
                                </Box>
                              ) : (
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 13 }}
                                >
                                  Save
                                </Typography>
                              )}
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </CustomTabPanel>
                  ))}
                  <CustomTabPanel
                    value={value}
                    index={
                      client?.client?.intake?.intakeCategory?.length +
                      client?.client?.programs?.length +
                      1
                    }
                  >
                    <ClientDocuments client_id={id} />
                  </CustomTabPanel>
                  <CustomTabPanel
                    value={value}
                    index={
                      client?.client?.intake?.intakeCategory?.length +
                      client?.client?.programs?.length +
                      2
                    }
                  >
                    <Box
                      sx={{
                        height: 'calc(100vh - 142px)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          width: 0,
                        },
                        py: 3,
                        px: 5,
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                          variant='contained'
                          sx={{ borderRadius: 7, py: 1.5 }}
                          onClick={() => setopenAddLedger(true)}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Add />
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Add to Ledger
                            </Typography>
                          </Box>
                        </Button>
                      </Box>
                      <Box>
                        {clientLedgers?.data?.ledgers?.map((item: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              py: 2,
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  color: '#B1BACA',
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  py: 1,
                                }}
                              >
                                Type
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  color: '#4EACFC',
                                  fontWeight: 'bold ',
                                }}
                              >
                                {item?.ledgerTypeTitle}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  color: '#B1BACA',
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  py: 1,
                                }}
                              >
                                Amount
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 'bold ',
                                }}
                              >
                                ${item?.amount}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  color: '#B1BACA',
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  py: 1,
                                }}
                              >
                                Source
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 15,
                                  fontWeight: 'bold ',
                                }}
                              >
                                {item?.ledgerSourceTitle}
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                color: '#B1BACA',
                                fontSize: 12,
                                fontWeight: 'bold',
                                py: 1,
                              }}
                            >
                              {moment(item?.createdAt).format('l')}
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4,
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  setLedgerId(item?.id)
                                  setOpenLedgerDetail(true)
                                }}
                              >
                                <Visibility sx={{ color: '#0178DD' }} />
                              </IconButton>
                              <Delete sx={{ color: '#F49B9B' }} />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </CustomTabPanel>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <Box sx={{ height: '55%' }}>
                    <Box
                      sx={{
                        height: '100%',
                        bgcolor: '#F4FAFF',
                        borderRadius: 3,
                      }}
                    >
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: 'divider',
                          pt: 2,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Tabs
                          value={valueApp}
                          onChange={handleChangeApp}
                          aria-label='basic tabs example'
                        >
                          <Tab
                            label='Appointments'
                            {...a11yPropsApp(0)}
                            sx={{ fontWeight: 'bold' }}
                          />
                          <Tab
                            label={`Goals`}
                            {...a11yPropsApp(1)}
                            sx={{ fontWeight: 'bold' }}
                          />
                        </Tabs>
                        <Box sx={{ height: '100%', px: 2 }}>
                          <IconButton
                            onClick={() => setExpandAppointment(true)}
                          >
                            <OpenInFull
                              sx={{ rotate: '90deg', color: '#0178DD' }}
                            />
                          </IconButton>
                        </Box>
                      </Box>

                      <CustomTabPanelApp value={valueApp} index={0}>
                        <Box
                          sx={{
                            my: 3,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{ borderRadius: 7 }}
                            onClick={() => setOpenAddAppointment(true)}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Add />
                              <Typography
                                sx={{ fontSize: 13, fontWeight: 'bold' }}
                              >
                                New Appointment
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                        <Box sx={{ px: 3 }}>
                          {clientAppointments?.data?.appointments?.map(
                            (item: any) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 4,
                                  my: 2,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setAppointment(item?.id)
                                  setOpenAppointmentDetails(true)
                                }}
                              >
                                <Box
                                  sx={{
                                    bgcolor: 'white',
                                    borderRadius: '100%',
                                    p: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Group />
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      color: '#040E28',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {item?.meetingType} Appointment
                                  </Typography>
                                  <Typography sx={{ fontSize: 12 }}>
                                    <span
                                      style={{
                                        color: '#0178DD',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {moment(item?.startTime).format('lll')}
                                    </span>{' '}
                                    Appointment With{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      {item?.participants?.[0]?.name}
                                    </span>
                                  </Typography>
                                </Box>
                              </Box>
                            )
                          )}
                        </Box>
                      </CustomTabPanelApp>
                      <CustomTabPanelApp value={valueApp} index={1}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            my: 1,
                            mx: 2,
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{ borderRadius: 7 }}
                            onClick={() => setOpenAddGoal(true)}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Add />
                              <Typography
                                sx={{ fontSize: 13, fontWeight: 'bold' }}
                              >
                                New Goal
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                        <Box sx={{ bgcolor: 'white', m: 1, borderRadius: 3 }}>
                          <GoalCard />
                          <GoalCard />
                        </Box>
                      </CustomTabPanelApp>
                    </Box>
                  </Box>

                  <Box sx={{ height: '45%', py: 1 }}>
                    <Box
                      sx={{
                        height: '100%',
                        bgcolor: '#F4FAFF',
                        borderRadius: 3,
                        // px: 3,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box sx={{ px: 3 }}>
                          <Tabs
                            value={valueChat}
                            onChange={handleChangeChat}
                            aria-label='basic tabs example'
                          >
                            <Tab
                              label='Notes'
                              {...a11yPropsChat(0)}
                              sx={{ fontWeight: 'bold' }}
                            />
                            {/* <Tab
                              label={`Chat`}
                              {...a11yPropsChat(1)}
                              sx={{ fontWeight: 'bold' }}
                            /> */}
                          </Tabs>
                        </Box>
                        <CustomTabPanelChat index={1} value={valueChat}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                bgcolor: 'white',
                                borderRadius: 2,
                                px: 1,
                                py: 0.5,
                                boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                              }}
                            >
                              <Typography
                                sx={{ fontSize: 11, fontWeight: 'bold' }}
                              >
                                All
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                bgcolor: 'white',
                                borderRadius: 2,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Typography
                                sx={{ fontSize: 11, fontWeight: 'bold' }}
                              >
                                Email
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                bgcolor: 'white',
                                borderRadius: 2,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Typography
                                sx={{ fontSize: 11, fontWeight: 'bold' }}
                              >
                                Text
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                bgcolor: 'white',
                                borderRadius: 2,
                                px: 1,
                                py: 0.5,
                              }}
                            >
                              <Typography
                                sx={{ fontSize: 11, fontWeight: 'bold' }}
                              >
                                Fax
                              </Typography>
                            </Box>
                          </Box>
                        </CustomTabPanelChat>
                        <IconButton onClick={() => setExpandNotes(true)}>
                          <OpenInFull
                            sx={{ rotate: '90deg', color: '#0178DD' }}
                          />
                        </IconButton>
                      </Box>
                      <CustomTabPanelChat index={0} value={valueChat}>
                        <Box sx={{ py: 1, px: 3 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                bgcolor: 'white',
                                borderRadius: 2,
                                px: 1,
                                py: 0.5,
                                boxShadow:
                                  selectedNoteProgram === '' || undefined
                                    ? '4px 4px 10px 1px rgba(0,0,0,0.07)'
                                    : '',
                              }}
                              onClick={() => setSelectedNoteProgram('')}
                            >
                              <Typography
                                sx={{ fontSize: 11, fontWeight: 'bold' }}
                              >
                                All
                              </Typography>
                            </Box>
                            {client?.client?.programs?.map((item: any) => (
                              <Box
                                sx={{
                                  bgcolor: 'white',
                                  borderRadius: 2,
                                  px: 1,
                                  py: 0.5,
                                  boxShadow:
                                    selectedNoteProgram === item?.id
                                      ? '4px 4px 10px 1px rgba(0,0,0,0.07)'
                                      : '',
                                }}
                                onClick={() => setSelectedNoteProgram(item?.id)}
                              >
                                <Typography
                                  sx={{ fontSize: 11, fontWeight: 'bold' }}
                                >
                                  {item?.title}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        {/* <Divider sx={{ my: 1 }} /> */}
                        {isWriring ? (
                          <Box sx={{ bgcolor: 'white', borderRadius: 2 }}>
                            <textarea
                              style={{
                                height: '12vh',
                                width: '100%',
                                resize: 'none',
                              }}
                              onChange={(e) => setNoteText(e.target.value)}
                            />
                            <Divider sx={{ my: 1 }} />
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                gap: 2,
                              }}
                            >
                              <Box>
                                <Typography sx={{ fontSize: 13 }}>
                                  Date:
                                </Typography>
                                <DatePicker
                                  slotProps={{ textField: { size: 'small' } }}
                                  onChange={(value) => setNoteDate(value)}
                                />
                              </Box>
                              <Box>
                                <Typography sx={{ fontSize: 13 }}>
                                  Duration:
                                </Typography>
                                {/* <TextField
                                  size='small'
                                  onChange={(e) =>
                                    setNoteDuration(e.target.value)
                                  }
                                /> */}
                                <Autocomplete
                                  options={[
                                    '15 Min',
                                    '30 Min',
                                    '45 Min',
                                    '60 Min',
                                  ]}
                                  filterSelectedOptions={true}
                                  getOptionLabel={(option: any) => option}
                                  size='small'
                                  onChange={(event, value) =>
                                    setNoteDuration(value)
                                  }
                                  renderInput={(params: any) => (
                                    <TextField {...params} name='duration' />
                                  )}
                                />
                              </Box>
                              {/* <Box>
                                <Typography sx={{ fontSize: 13 }}>
                                  Duration:
                                </Typography>
                                <TextField
                                  size='small'
                                  onChange={(e) =>
                                    setNoteDuration(e.target.value)
                                  }
                                />
                              </Box> */}
                              <Button
                                variant='contained'
                                onClick={() => {
                                  setIsWriting(false)
                                  handleSave()
                                }}
                                sx={{ py: 1.2 }}
                              >
                                <Typography
                                  sx={{ fontSize: 13, fontWeight: 'bold' }}
                                >
                                  Save
                                </Typography>
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Box sx={{ position: 'relative', pt: 1 }}>
                            {carePlanNotes?.carePlanNotes?.length > 0 && (
                              <Box sx={{ bgcolor: '#3D577E', p: 1.5 }}>
                                <Typography
                                  sx={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                >
                                  Care Plan
                                </Typography>
                                <Box>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontWeight: 'bold',
                                      fontSize: 12,
                                    }}
                                  >
                                    {
                                      carePlanNotes?.carePlanNotes?.[0]
                                        ?.categoryField
                                    }
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: 'white',
                                      fontSize: 12,
                                    }}
                                  >
                                    {carePlanNotes?.carePlanNotes?.[0]?.notes?.slice(
                                      0,
                                      60
                                    )}
                                    .....
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            <Box
                              sx={{
                                py: 1,
                                px: 3,
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                  width: 0,
                                },
                                height: '20vh',
                                // border: '1px solid red',
                              }}
                            >
                              {/* <Typography sx={{ fontWeight: "bold" }}>
                                April
                              </Typography> */}
                              {clientNotes?.data?.notes?.map((item: any) => {
                                if (selectedNoteProgram === item?.programId) {
                                  return (
                                    <Box sx={{ my: 1, bgcolor: 'white', p: 2 }}>
                                      <Typography
                                        sx={{
                                          fontWeight: 'bold',
                                          fontSize: 14,
                                        }}
                                      >
                                        {moment(item?.createdAt).format('ll')}
                                      </Typography>
                                      <Typography sx={{ fontSize: 12 }}>
                                        {item?.value}
                                      </Typography>
                                    </Box>
                                  )
                                }
                              })}

                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: 0,
                                  left: '50%',
                                  transform: 'translate(-50%, -10%)',
                                  display:
                                    selectedNoteProgram === '' || undefined
                                      ? 'none'
                                      : '',
                                }}
                              >
                                <Button
                                  variant='contained'
                                  sx={{ borderRadius: 7, py: 1.3, px: 3 }}
                                  // onClick={() => {
                                  //   // setIsWriting(true)
                                  // }}
                                  onClick={handleClick}
                                >
                                  <Typography
                                    sx={{ fontSize: 13, fontWeight: 'bold' }}
                                  >
                                    Write Note
                                  </Typography>
                                </Button>
                                <Popover
                                  id={popId}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                >
                                  <Box sx={{ bgcolor: 'white', p: 2 }}>
                                    {client?.client?.programs
                                      ?.filter(
                                        (item: any) =>
                                          item?.id === selectedNoteProgram
                                      )?.[0]
                                      ?.noteConfig?.map((elt: any) => (
                                        <Box
                                          sx={{ px: 2, cursor: 'pointer' }}
                                          onClick={() => {
                                            setSelectedNoteType(elt?.noteTypeId)
                                            setIsWriting(true)
                                          }}
                                        >
                                          <Typography>
                                            {elt?.noteTypeName}
                                          </Typography>
                                        </Box>
                                      ))}
                                  </Box>
                                </Popover>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </CustomTabPanelChat>
                    </Box>
                  </Box>
                  {expandAppointment && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bgcolor: '#F4FAFF',
                        height: 'calc(100vh - 73px)',
                        width: { xs: '100vw', md: 'calc(100vw - 280px)' },
                        px: 3,
                      }}
                    >
                      <Box
                        sx={{
                          pt: 2,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Tabs
                          value={valueApp}
                          onChange={handleChangeApp}
                          aria-label='basic tabs example'
                        >
                          <Tab
                            label='Appointments'
                            {...a11yPropsApp(0)}
                            sx={{ fontWeight: 'bold' }}
                          />
                          <Tab
                            label='Goals'
                            {...a11yPropsApp(1)}
                            sx={{ fontWeight: 'bold' }}
                          />
                        </Tabs>
                        <Box sx={{ height: '100%', px: 2 }}>
                          <IconButton
                            onClick={() => setExpandAppointment(false)}
                          >
                            <OpenInFull
                              sx={{ rotate: '90deg', color: '#0178DD' }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                      <CustomTabPanelApp value={valueApp} index={0}>
                        <Appointments defaultData={clientAppointments} />
                      </CustomTabPanelApp>
                      <CustomTabPanelApp value={valueApp} index={1}>
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              my: 1,
                              mx: 2,
                            }}
                          >
                            <Button
                              variant='contained'
                              sx={{ borderRadius: 7 }}
                              onClick={() => setOpenAddGoal(true)}
                            >
                              <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Add />
                                <Typography
                                  sx={{ fontSize: 13, fontWeight: 'bold' }}
                                >
                                  New Goal
                                </Typography>
                              </Box>
                            </Button>
                          </Box>
                          <Box
                            sx={{ display: 'flex', justifyContent: 'center' }}
                          >
                            <Box
                              sx={{
                                bgcolor: 'white',
                                boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                                width: { xs: '80vw', md: '60vw', xl: '50vw' },
                                borderRadius: 3,
                                py: 1,
                              }}
                            >
                              <GoalCard />
                            </Box>
                          </Box>
                        </Box>
                      </CustomTabPanelApp>
                    </Box>
                  )}
                  {expandNotes && (
                    <ExpandedNotesAndChats client={client?.client} />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Card>
          <AssignCaseManager
            open={openAssign}
            close={() => setOpenAssign(false)}
            clientId={id}
          />
          <AddGoal
            open={openAddGoal}
            close={() => setOpenAddGoal(false)}
            refetch={refetchGoal}
            setRefetch={setRefetchGoal}
          />
          <AddAppointment
            open={openAddAppointment}
            close={() => setOpenAddAppointment(false)}
            refetch={refetchAppointemnt}
            setRefetch={setRefetchAppointment}
          />
          <AppointmentDetails
            open={openAppointmentDetails}
            close={() => setOpenAppointmentDetails(false)}
            data={
              clientAppointments?.data?.appointments?.filter(
                (item: any) => item?.id === appontment
              )?.[0]
            }
          />
          <AddLedger
            open={openAddLedger}
            close={() => setopenAddLedger(false)}
            clientId={id}
          />
          {ledgerId && (
            <LedgerDetail
              open={openLedgerDetail}
              close={() => setOpenLedgerDetail(false)}
              client_id={id}
              ledger_id={ledgerId}
            />
          )}
          <ClientTimeline
            open={openTimeline}
            close={() => setOpenTimeline(false)}
            clientId={id}
          />
        </Box>
      </Slide>
    </Modal>
  )
}
