import { UseMutateFunction, useMutation } from 'react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { BASE_URL } from '../utils/config'
import { authHeader } from '../utils/auth'

interface MUTATE {
  mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, void, unknown>
  isLoading: boolean
  isError: boolean
  error: AxiosError | any
  isSuccess: boolean
  data: any
}

export function useDelete(endpoint: string) {
  const { mutate, isLoading, isError, error, isSuccess, data }: MUTATE =
    useMutation((id) => {
      return axios.delete(`${BASE_URL}${endpoint}/${id}`, {
        headers: authHeader(),
      })
    })
  return { mutate, isLoading, isError, error, isSuccess, data }
}
