import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  TextField,
  Typography,
} from '@mui/material'
import { Add, CalendarMonth, Close, Flag, Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { usePost } from '../../hooks/usePost'
import AddDirectory from '../Directory/AddDirectory'
import DirectoryDetails from '../Directory/DirectoryDetails'

export default function Directory() {
  const [isSearch, setIsSearch] = useState(false)
  const { data: directories, mutate: fetchDirectories } = usePost(
    '/public/directory/directories/list'
  )

  const [refetch, setRefetch] = useState(false)
  const [openAddDirectory, setOpenAddDirectory] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [directoryItem, setDirectory] = useState() as any
  const [search, setSearch] = useState() as any

  useEffect(() => {
    const dataP: any = {
      filters: {
        filters: [
          {
            field: 'first_name',
            like: {
              value: search,
            },
          },
        ],
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDirectories(dataP)
  }, [refetch, search])

  console.log({ 111111111: directories })

  function DirectoryCard({ directory }: any) {
    return (
      <Box> 
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between',
            // gap: 6,
            width: '100%',
            my: 2,
            cursor: 'pointer',
          }}
          onClick={() => {
            setDirectory(directory)
            setOpenDetail(true)
          }}
        >
          <Avatar sx={{ width: 36, height: 36 }} src={directory?.imageUrl} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ px: 3, fontSize: 14, width: 255 }}>
              {directory?.firstName +
                ' ' +
                directory?.middleName +
                ' ' +
                directory?.lastName}
            </Typography>
            <Box sx={{ bgcolor: '#F4FAFF', px: 1, borderRadius: 5 }}>
              <Typography
                sx={{
                  color: '#4D72A6',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 12,
                }}
              >
                {directory?.tags?.[0]?.tag}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ color: '#F9F9FC' }} />
      </Box>
    )
  }
  return (
    <Box sx={{ height: '53%' }}>
      <Card
        sx={{
          bgcolor: 'white',
          boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
          height: '100%',
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}
        >
          <Typography
            sx={{
              color: '#0178DD',
              fontWeight: 'bold',
              display: isSearch ? 'none' : 'block',
            }}
          >
            Directory
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              sx={{ display: isSearch ? 'block' : 'none' }}
              size='small'
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root': {
                    borderRadius: 8,
                  },
                },
                startAdornment: <Search />,
                endAdornment: (
                  <IconButton onClick={() => setIsSearch(false)}>
                    <Close />
                  </IconButton>
                ),
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              variant='outlined'
              sx={{
                borderRadius: 5,
                borderColor: '#EBF3F9',
                bgcolor: '#F4FAFF',
                display: isSearch ? 'none' : 'block ',
              }}
              onClick={() => setIsSearch(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Search sx={{ color: 'black' }} />
                <Typography
                  sx={{
                    fontSize: 13,
                    color: 'black',
                  }}
                >
                  Search
                </Typography>
              </Box>
            </Button>
            <Button
              variant='outlined'
              sx={{ borderRadius: 5, bgcolor: '#E6F4FF' }}
              onClick={() => setOpenAddDirectory(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            px: 3,
            height: '39vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
            },
          }}
        >
          {directories?.data?.directories?.map((directory: any) => (
            <DirectoryCard directory={directory} />
          ))}
        </Box>
      </Card>
      <AddDirectory
        open={openAddDirectory}
        close={() => setOpenAddDirectory(false)}
        refetch={refetch}
        setRefetch={setRefetch}
      />
      <DirectoryDetails
        open={openDetail}
        close={() => setOpenDetail(false)}
        data={directoryItem}
      />
    </Box>
  )
}
