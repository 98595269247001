import { Add, Close, Edit, PeopleAlt } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Popover,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import FileUpload from '../File/FileUpload'
import { set, useForm } from 'react-hook-form'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { authHeader } from '../../utils/auth'
import { BASE_URL, STAGING_BASE_URL } from '../../utils/config'
import { TimePicker } from '@mui/x-date-pickers'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { usePatch } from '../../hooks/usePatch'

export default function EditDirectory({
  open,
  close,
  directoryData,
  refetch,
  setRefetch,
}: {
  open: boolean
  close: any
  directoryData: any
  refetch: boolean
  setRefetch: any
}) {
  console.log({ directoryEditData: directoryData })
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const openAnc = Boolean(anchorEl)
  const id = openAnc ? 'simple-popover' : undefined

  const [workingHours, setWorkingHours] = useState<
    {
      days: string[]
      from: string
      to: string
    }[]
  >([])
  const [weekDay, setWeekDay] = useState<string[]>([])
  const [openTime, setOpenTime] = useState() as any
  const [closeTime, setCloseTime] = useState() as any
  const [image_url, setImageUrl] = useState<string>()
  const [fileState, setFileState] = useState<any>()
  const [url, setUrl] = useState() as any

  console.log({ image_url })

  useEffect(() => {
    console.log({ fileState })
    if (fileState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${fileState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [fileState])
  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(fileState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => setImageUrl(url?.split('?')?.[0]))
      .catch((e) => console.log({ e }))
  }

  console.log({ workingHours })

  function DateSelect({ date }: { date: string }) {
    return (
      <Box
        sx={{
          '&:hover': {
            bgcolor: '#E6F4FF',
          },
          px: 2,
          py: 0.5,
          borderRadius: 1,
          cursor: 'pointer',
          bgcolor: weekDay?.includes(date) ? '#E6F4FF' : 'transparent',
        }}
        onClick={() => {
          if (workingHours?.some((item) => item?.days?.includes(date))) {
          } else {
            setWeekDay((day) => {
              if (day?.includes(date)) {
                return day.filter((d) => d !== date)
              } else {
                return [...day, date]
              }
            })
          }
        }}
      >
        {workingHours?.some((item) => item?.days?.includes(date)) ? (
          <Tooltip arrow placement='bottom' title='Date Already Selected'>
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 14, color: 'gray' }}
            >
              {date}
            </Typography>
          </Tooltip>
        ) : (
          <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
            {date}
          </Typography>
        )}
      </Box>
    )
  }

  type FormValues = {
    businessName: string
    first_name: string
    middle_name: string
    last_name: string
    phone: string
    email: string
    website: string
    image_url: string
    address: {
      id: string
      country: string
      addressLine1: string
      addressLine2: string
      city: string
      state: string
      postalCode: string
      latitude: number
      longitude: number
    }
    workingHours: [
      {
        days: string[]
        from: string
        to: string
      },
      {
        days: string[]
        from: string
        to: string
      }
    ]
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      businessName: directoryData?.businessName,
      first_name: directoryData?.firstName,
      middle_name: directoryData?.middleName,
      last_name: directoryData?.lastName,
      phone: directoryData?.phone,
      email: directoryData?.email,
      website: directoryData?.website,
      image_url: directoryData?.imageUrl,
      address: {
        id: directoryData?.address?.id,
        country: directoryData?.address?.country,
        addressLine1: directoryData?.address?.addressLine1,
        addressLine2: directoryData?.address?.addressLine2,
        city: directoryData?.address?.city,
        state: directoryData?.address?.state,
        postalCode: directoryData?.address?.postalCode,
        latitude: directoryData?.address?.latitude,
        longitude: directoryData?.address?.longitude,
      },
      workingHours: directoryData?.workingHours,
    },
    mode: 'onBlur',
  })

  console.log({ errors })

  const { data: directoryCategories } = useFetch(
    'directoryCat',
    '/public/directory/directory-categories'
  )
  const [directoryDepartments, setDirectoryDepartments] = useState() as any
  const [directoryTags, setDirectoryTags] = useState() as any
  const [category, setCategory] = useState() as any
  const [department, setDetartment] = useState() as any
  const [tag, setTag] = useState() as any
  const [address, setAddress] = useState() as any
  const [baseClientLocation, setBaseClientLocation] = useState() as any

  useEffect(() => {
    setCategory(directoryData?.categories?.[0])
    setDetartment(directoryData?.departments?.[0])
    setTag(directoryData?.tags?.[0])
    setPlacesValue(directoryData?.address?.addressLine1)
    setWorkingHours(directoryData?.workingHours)
  }, [])

  console.log({ category, department, tag })

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/public/directory/directory-categories/${category?.id}/directory-departments`,
        { headers: authHeader() }
      )
      .then((res) => {
        setDirectoryDepartments(res.data)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }, [category])

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/public/directory/directory-departments/${department?.id}/directory-tags`,
        { headers: authHeader() }
      )
      .then((res) => {
        setDirectoryTags(res.data)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }, [department])

  const { mutate, isSuccess, isError, error, isLoading } = usePatch(
    `/public/directory/directories/${directoryData?.id}`
  )

  function onSubmit(data: any) {
    console.log({ data })
    data.workingHours = workingHours
    data.tag_ids = [tag?.id]
    data.category_ids = [category.id]
    data.department_ids = [department.id]
    data.address = address
    data.image_url = image_url
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Directory Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetch(!refetch)
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue: setPlacesValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'GO_PLACES',
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })

  useEffect(() => {
    axios
      .get(
        `${STAGING_BASE_URL}/maps/api/place/details/json?place_id=${baseClientLocation?.place_id}`
      )
      .then((res) => {
        console.log({ data: res?.data })
        if (res?.data?.result) {
          setAddress({
            country: res?.data?.result?.address_components?.filter(
              (item: any) => item?.types?.includes('country')
            )[0]?.long_name,
            state:
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('administrative_area_level_1')
              )[0]?.long_name ||
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('locality')
              )[0]?.long_name,
            city:
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('locality')
              )[0]?.long_name ||
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('administrative_area_level_2')
              )[0]?.long_name,
            addressLine1: baseClientLocation?.description,
            addressLine2: '',
            postalCode: '',
            latitude: res?.data?.result?.geometry?.location?.lat,
            longitude: res?.data?.result?.geometry?.location?.lng,
          })
        } else {
          setAddress()
        }
      })
      .catch((e) => console.log(e))
  }, [baseClientLocation])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 1000 },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Edit Directory
                </Typography>
                <Box> </Box>
              </Box>
              <Box
                sx={{
                  px: { xs: 3, sm: 5, md: 10 },
                  py: 5,
                  height: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Box>
                  <FileUpload
                    type='PROFILE_IMAGE'
                    name='clientImage'
                    setFileState={setFileState}
                  />
                  <Grid container spacing={3} sx={{ py: 2 }}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Bussiness Name
                        </Typography>
                        <TextField
                          size='small'
                          defaultValue={directoryData?.businessName}
                          fullWidth
                          {...register('businessName', {
                            required: true,
                          })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Category
                        </Typography>
                        <Autocomplete
                          options={directoryCategories?.categories}
                          getOptionLabel={(item: any) => item.title}
                          size='small'
                          defaultValue={directoryData?.categories?.[0]}
                          onChange={(event, value) => {
                            setCategory(value)
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size='small' fullWidth />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Department
                        </Typography>
                        <Autocomplete
                          options={directoryDepartments?.departments || []}
                          getOptionLabel={(item: any) => item.title}
                          size='small'
                          defaultValue={directoryData?.departments?.[0]}
                          onChange={(event, value) => {
                            setDetartment(value)
                          }}
                          disabled={!category}
                          renderInput={(params) => (
                            <TextField {...params} size='small' fullWidth />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Tag
                        </Typography>
                        <Autocomplete
                          options={directoryTags?.tags || []}
                          getOptionLabel={(item: any) => item.tag}
                          size='small'
                          defaultValue={directoryData?.tags?.[0]}
                          onChange={(event, value) => {
                            setTag(value)
                          }}
                          disabled={!department}
                          renderInput={(params) => (
                            <TextField {...params} size='small' fullWidth />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ py: 1 }}>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        color: '#B5B5B5',
                      }}
                    >
                      PROVIDER
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          First Name
                        </Typography>
                        <TextField
                          size='small'
                          fullWidth
                          defaultValue={directoryData?.firstName}
                          {...register('first_name', {
                            required: true,
                          })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Middle Name
                        </Typography>
                        <TextField
                          size='small'
                          fullWidth
                          defaultValue={directoryData?.middleName}
                          {...register('middle_name', {
                            required: true,
                          })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Last Name
                        </Typography>
                        <TextField
                          size='small'
                          fullWidth
                          defaultValue={directoryData?.lastName}
                          {...register('last_name', {
                            required: true,
                          })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ py: 1 }}>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        color: '#B5B5B5',
                      }}
                    >
                      CONTACT
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Phone
                        </Typography>
                        <TextField
                          size='small'
                          fullWidth
                          defaultValue={directoryData?.phone}
                          {...register('phone', {
                            required: true,
                          })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Email
                        </Typography>
                        <TextField
                          size='small'
                          fullWidth
                          defaultValue={directoryData?.email}
                          {...register('email', {
                            required: true,
                          })}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Website
                        </Typography>
                        <TextField
                          size='small'
                          fullWidth
                          defaultValue={directoryData?.website}
                          {...register('website', {
                            required: true,
                          })}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ py: 1 }}>
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        color: '#B5B5B5',
                      }}
                    >
                      ADDRESS
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Location
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                          }}
                        >
                          <Autocomplete
                            options={data || []}
                            getOptionLabel={(option) => option?.description}
                            defaultValue={data?.[0]}
                            onChange={(event, value) => {
                              setBaseClientLocation(value)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                onChange={(e) => setPlacesValue(e.target.value)}
                              />
                            )}
                            fullWidth
                          />
                          {/* <IconButton onClick={() => setFormState('map')}>
                          <LocationOn sx={{ color: 'primary.main' }} />
                        </IconButton> */}
                        </Box>
                      </Box>
                    </Grid>
                    <Box
                      sx={{
                        p: 5,
                        border: '1px solid lightgray',
                        borderRadius: 4,
                        width: '100%',
                        my: 5,
                        display: address ? 'block' : 'none',
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 'bold', fontSize: 16 }}
                            >
                              Country:
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 14,
                                color: 'gray',
                              }}
                            >
                              {address?.country}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 'bold', fontSize: 16 }}
                            >
                              State:
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 14,
                                color: 'gray',
                              }}
                            >
                              {address?.state}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 'bold', fontSize: 16 }}
                            >
                              City:
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 14,
                                color: 'gray',
                              }}
                            >
                              {address?.city}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 'bold', fontSize: 16 }}
                            >
                              Address:
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 14,
                                color: 'gray',
                              }}
                            >
                              {address?.addressLine1}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Working Hours
                        </Typography>
                        <Box
                          sx={{
                            width: '100%',
                            border: '1px solid gray',
                            borderRadius: 2,
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            alignItems: 'center',
                            py: 0.5,
                          }}
                        >
                          {workingHours?.map((item, index) => (
                            <Box
                              sx={{
                                bgcolor: '#F4FAFF',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                px: 2,
                                py: 0.4,
                              }}
                            >
                              <Typography
                                sx={{
                                  color: '#8AA3BF',
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                }}
                              >
                                {item?.days?.join(',')}
                              </Typography>
                              <Divider
                                sx={{ color: '#8AA3BF' }}
                                flexItem
                                orientation='vertical'
                              />
                              <Typography
                                sx={{
                                  color: '#8AA3BF',
                                  fontWeight: 'bold',
                                  fontSize: 13,
                                }}
                              >
                                {item?.from}
                              </Typography>
                              <Typography
                                sx={{
                                  color: '#8AA3BF',
                                  fontWeight: 'bold',
                                  fontSize: 13,
                                }}
                              >
                                {item?.to}
                              </Typography>
                              {/* <IconButton
                              onClick={(event) => {
                                setWeekDay(workingHours?.[index]?.days)
                                setOpenTime(workingHours?.[index]?.from)
                                setCloseTime(workingHours?.[index]?.to)
                                handleClick(event)
                              }}
                            >
                              <Edit sx={{ width: 20, height: 20 }} />
                            </IconButton> */}
                              <IconButton
                                onClick={() =>
                                  setWorkingHours((work) =>
                                    work.filter(
                                      (item, index2) => index !== index2
                                    )
                                  )
                                }
                              >
                                <Close sx={{ color: '#F18DA8' }} />
                              </IconButton>
                            </Box>
                          ))}
                          <IconButton onClick={handleClick}>
                            <Box
                              sx={{
                                bgcolor: '#0A81F5',
                                cursor: 'pointer',
                                p: 1,
                                borderRadius: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 30,
                                height: 30,
                                boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                              }}
                            >
                              <Add sx={{ color: 'white' }} />
                            </Box>
                          </IconButton>
                          <Popover
                            id={id}
                            open={openAnc}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 2,
                                p: 3,
                                height: 300,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1,
                                }}
                              >
                                <DateSelect date='MONDAY' />
                                <DateSelect date='TUESDAY' />
                                <DateSelect date='WEDNESDAY' />
                                <DateSelect date='THURSDAY' />
                                <DateSelect date='FRIDAY' />
                                <DateSelect date='SATURDAY' />
                                <DateSelect date='SUNDAY' />
                              </Box>
                              <Divider flexItem orientation='vertical' />
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 2,
                                  justifyContent: 'space-evenly',
                                  alignItems: 'center',
                                  height: '100%',
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{ fontWeight: 'bold', fontSize: 11 }}
                                  >
                                    From
                                  </Typography>
                                  <Box
                                    sx={{
                                      bgcolor: '#F4FAFF',
                                      px: 3,
                                      py: 0.5,
                                      width: 200,
                                    }}
                                  >
                                    <TimePicker
                                      slotProps={{
                                        textField: {
                                          variant: 'standard',
                                          inputProps: {
                                            style: {
                                              color: '#5E7FA4',
                                              fontWeight: '400',
                                              padding: '0px',
                                            },
                                          },
                                          InputProps: {
                                            disableUnderline: true,
                                            sx: {
                                              '& .MuiSvgIcon-root': {
                                                color: '#4485C4',
                                              },
                                            },
                                          },
                                        },
                                      }}
                                      // defaultValue={
                                      //     campaign?.schedules[`${schIndex}`].time ||
                                      //     ''
                                      // }
                                      onChange={(value: any) => {
                                        setOpenTime(
                                          `${
                                            value?.$H > 12
                                              ? parseInt(value?.$H) - 12
                                              : value?.$H === 0
                                              ? 12
                                              : value?.$H
                                          }:${value?.$m} ${
                                            value?.$H >= 12 ? 'PM' : 'AM'
                                          }`
                                        )
                                      }}

                                      // disabled={disabled}
                                    />
                                  </Box>
                                </Box>
                                <Box>
                                  <Typography
                                    sx={{ fontWeight: 'bold', fontSize: 11 }}
                                  >
                                    To
                                  </Typography>
                                  <Box
                                    sx={{
                                      bgcolor: '#F4FAFF',
                                      px: 3,
                                      py: 0.5,
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                      width: 200,
                                    }}
                                  >
                                    <TimePicker
                                      slotProps={{
                                        textField: {
                                          variant: 'standard',
                                          inputProps: {
                                            style: {
                                              color: '#5E7FA4',
                                              fontWeight: '400',
                                              padding: '0px',
                                            },
                                          },
                                          InputProps: {
                                            disableUnderline: true,
                                            sx: {
                                              '& .MuiSvgIcon-root': {
                                                color: '#4485C4',
                                              },
                                            },
                                          },
                                        },
                                      }}
                                      // defaultValue={
                                      //     campaign?.schedules[`${schIndex}`].time ||
                                      //     ''
                                      // }
                                      onChange={(value: any) => {
                                        setCloseTime(
                                          `${
                                            value?.$H > 12
                                              ? parseInt(value?.$H) - 12
                                              : value?.$H === 0
                                              ? 12
                                              : value?.$H
                                          }:${value?.$m} ${
                                            value?.$H >= 12 ? 'PM' : 'AM'
                                          }`
                                        )
                                      }}
                                      // disabled={disabled}
                                    />
                                  </Box>
                                </Box>
                                <Button
                                  variant='contained'
                                  onClick={() => {
                                    setWorkingHours((state) => [
                                      ...state,
                                      {
                                        days: weekDay,
                                        from: openTime,
                                        to: closeTime,
                                      },
                                    ])
                                    setWeekDay([])
                                    setOpenTime()
                                    setCloseTime()
                                    handleClose()
                                  }}
                                >
                                  Confirm
                                </Button>
                              </Box>
                            </Box>
                          </Popover>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: 0,
                  width: { xs: '100vw', md: 1000 },
                  height: 80,
                  py: 3,
                  px: 5,
                }}
              >
                <Button
                  variant='contained'
                  sx={{ px: 10, py: 2.5 }}
                  type='submit'
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Save Directory
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
