import {
  Add,
  ArrowDownward,
  Download,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Message,
  Replay,
  ReportProblem,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Area,
  AreaChart,
  CartesianAxis,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import GenerateReport from '../components/Report/GenerateReport'
import { usePost } from '../hooks/usePost'
import AddReportingLabel from '../components/Report/AddReportingLabel'
import { useFetch } from '../hooks/useFetch'
import moment from 'moment'

export default function Reports() {
  const [refetch, setRefetch] = useState(false)
  const [openCustomReportDetails, setOpenCustomReportDetails] =
    useState() as any
  const { data: summaryData, mutate: getSummary } = usePost(
    '/public/reporting/summary'
  )

  const { data: activeReports } = useFetch(
    'getactivereports',
    '/public/reporting/active-reports'
  )

  console.log({ summaryData, activeReports })

  const [program_ids, setProgramIds] = useState() as any
  const [program_manager_ids, setProgramManagerIds] = useState() as any
  const [case_manager_ids, setCaseManagerIds] = useState() as any
  const [insurance_ids, setInsuranceIds] = useState() as any

  useEffect(() => {
    getSummary({
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
      ...(program_ids && { program_ids }),
      ...(program_manager_ids && { program_manager_ids }),
      ...(case_manager_ids && { case_manager_ids }),
      ...(insurance_ids && { insurance_ids }),
    } as any)
  }, [refetch])

  const data = [
    {
      name: 'Homeless',
      value: 2800,
    },
    {
      name: 'Sheltor',
      value: 1800,
    },
    {
      name: 'Housed',
      value: 1900,
    },
    {
      name: 'On Application',
      value: 3500,
    },
  ]
  const data2 = [
    {
      name: 'Unemployed',
      value: 6400,
    },
    {
      name: 'Employed',
      value: 1800,
    },
    {
      name: 'On Application',
      value: 1800,
    },
  ]
  const data3 = [
    {
      name: 'No Doctor',
      value: 7500,
    },
    {
      name: 'Seeing Doctor',
      value: 2500,
    },
  ]
  const COLORS = [
    '#FF8097',
    '#BF828D',
    '#2699FB',
    '#09244B',
    '#2CD889',
    '#0178DD',
    '#F7617D',
    '#FFCD54',
    '#FF8080',
    '#00B5D6',
    '#000000',
    '#FF6F6F',
    '#00D96D',
    '#FFAC33',
    '#0178DD',
    '#2CD889',
    '#C44141',
  ]
  const COLORS2 = ['#FF8080', '#00B5D6', '#000000']
  const COLORS3 = ['#FF6F6F', '#00D96D']
  const GOALCOLORS = ['#FFAC33', '#0178DD', '#2CD889', '#C44141']
  const OVERVIEWCOLORS = ['#2CD889', '#0178DD', '#F7617D', '#FFCD54']
  const [openGenerate, setOpenGenerate] = useState(false)

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const [openDetails, setOpenDetails] = useState(false)
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const [openAddLabel, setOpenAddLabel] = useState() as any
  const [fieldIndex, setFieldIndex] = useState(-1)

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number; fColor: any }
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant='determinate'
          {...props}
          sx={{ width: 160, height: 160, color: props.fColor }}
          size={160}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant='caption'
            component='div'
            sx={{ color: props.fColor, fontWeight: 'bold', fontSize: 35 }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            pt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 1,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Summary'
              {...a11yProps(0)}
              sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
            />
            <Tab
              label='Custom Reports'
              {...a11yProps(1)}
              sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
            />
            <Tab
              label='MIF Reports'
              {...a11yProps(2)}
              sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
            />
          </Tabs>
        </Box>
        <Button
          variant='contained'
          sx={{ px: 2, borderRadius: 7, height: 50 }}
          onClick={() => setOpenGenerate(true)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Add />
            <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
              Generate Report
            </Typography>
          </Box>
        </Button>
      </Box>
      <Box>
        <CustomTabPanel value={value} index={0}>
          <Box>
            <Box sx={{ py: 3 }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 3, my: 2 }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={2.7}>
                    <Box sx={{ my: 1 }}>
                      <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                        Program
                      </Typography>
                      <Autocomplete
                        options={[
                          'Comprehensive Needs',
                          'Housing Needs',
                          'Medical Needs',
                          'Employment Needs',
                        ]}
                        getOptionLabel={(item) => item}
                        size='small'
                        // onChange={(event, value) => {
                        //   setLanguage(value)
                        // }}
                        multiple
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            fullWidth
                            // onChange={(e) => setLanguage(e.target.value)}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.7}>
                    <Box sx={{ my: 1 }}>
                      <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                        Program Manager
                      </Typography>
                      <Autocomplete
                        options={[
                          'Comprehensive Needs',
                          'Housing Needs',
                          'Medical Needs',
                          'Employment Needs',
                        ]}
                        renderOption={(option, state) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                              px: 3,
                              py: 1,
                            }}
                          >
                            <Avatar sx={{ width: 40, height: 40 }} />
                            <Typography>Kebede</Typography>
                          </Box>
                        )}
                        getOptionLabel={(item) => item}
                        size='small'
                        // onChange={(event, value) => {
                        //   setLanguage(value)
                        // }}
                        multiple
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            fullWidth
                            // onChange={(e) => setLanguage(e.target.value)}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.7}>
                    <Box sx={{ my: 1 }}>
                      <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                        Case Manager
                      </Typography>
                      <Autocomplete
                        options={[
                          'Comprehensive Needs',
                          'Housing Needs',
                          'Medical Needs',
                          'Employment Needs',
                        ]}
                        getOptionLabel={(item) => item}
                        size='small'
                        renderOption={(option, state) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                              px: 3,
                              py: 1,
                            }}
                          >
                            <Avatar sx={{ width: 40, height: 40 }} />
                            <Typography>Kebede</Typography>
                          </Box>
                        )}
                        // onChange={(event, value) => {
                        //   setLanguage(value)
                        // }}
                        multiple
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            fullWidth
                            // onChange={(e) => setLanguage(e.target.value)}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.7}>
                    <Box sx={{ my: 1 }}>
                      <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                        Health Insurance
                      </Typography>
                      <Autocomplete
                        options={['Medhin', 'Madot', 'Momona', 'Shekihna']}
                        getOptionLabel={(item) => item}
                        size='small'
                        // onChange={(event, value) => {
                        //   setLanguage(value)
                        // }}
                        multiple
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            fullWidth
                            // onChange={(e) => setLanguage(e.target.value)}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={1.2}>
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        pb: 1,
                      }}
                    >
                      <Button variant='contained' sx={{ width: 400 }}>
                        Apply
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      border: '2px solid whitesmoke',
                      height: 150,
                      borderRadius: 3,
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: 23 }}>
                          {summaryData?.data?.summaryLineChartData
                            ?.find((item: any) => item?.name === 'Clients')
                            ?.values?.reduce(function (acc: any, elt: any) {
                              return (acc += elt?.value)
                            }, 0)}
                        </Typography>
                        <Typography sx={{ fontSize: 9 }}>CLIENTS</Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: '#51D8A4',
                          fontWeight: 'bold',
                        }}
                      >
                        {summaryData?.data?.summaryLineChartData
                          ?.find((item: any) => item?.name === 'Clients')
                          ?.lastChange?.toFixed(1)}
                        %
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AreaChart
                        width={270}
                        height={70}
                        data={summaryData?.data?.summaryLineChartData
                          ?.find((item: any) => item?.name === 'Clients')
                          ?.values?.map((item: any) => {
                            return { count: item?.value }
                          })}
                      >
                        <defs>
                          <linearGradient
                            id='colorUv'
                            x1='0'
                            y1='0'
                            x2='0'
                            y2='1'
                          >
                            <stop
                              offset='5%'
                              stopColor='#C8C8C8'
                              stopOpacity={1}
                            />
                            <stop
                              offset='10%'
                              stopColor='#D4D4D4'
                              stopOpacity={1}
                            />
                            <stop
                              offset='40%'
                              stopColor='#DBDBDB'
                              stopOpacity={1}
                            />
                            <stop
                              offset='70%'
                              stopColor='#DEDEDE'
                              stopOpacity={1}
                            />
                            <stop
                              offset='90%'
                              stopColor='#EFEFEF'
                              stopOpacity={1}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type='monotone'
                          dataKey='count'
                          stroke='#C0C0C0'
                          fill='url(#colorUv)'
                          strokeDasharray='1 5'
                        />
                      </AreaChart>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      border: '2px solid whitesmoke',
                      height: 150,
                      borderRadius: 3,
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: 23, color: '#319EFB' }}>
                          19
                        </Typography>
                        <Typography sx={{ fontSize: 9 }}>HOUSED</Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: '#F57272',
                          fontWeight: 'bold',
                        }}
                      >
                        86%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AreaChart
                        width={270}
                        height={70}
                        data={[
                          { count: 0 },
                          { count: 4 },
                          { count: 3 },
                          { count: 7 },
                          { count: 4 },
                          { count: 5 },
                          { count: 3 },
                          { count: 4 },
                          { count: 0 },
                          { count: 3 },
                          { count: 0 },
                        ]}
                      >
                        <defs>
                          <linearGradient
                            id='colorUv1'
                            x1='0'
                            y1='0'
                            x2='0'
                            y2='1'
                          >
                            <stop
                              offset='5%'
                              stopColor='#C1DFFD'
                              stopOpacity={1}
                            />
                            <stop
                              offset='10%'
                              stopColor='#CCE5FD'
                              stopOpacity={1}
                            />
                            <stop
                              offset='40%'
                              stopColor='#D8EAFD'
                              stopOpacity={1}
                            />
                            <stop
                              offset='70%'
                              stopColor='#E0EEFE'
                              stopOpacity={1}
                            />
                            <stop
                              offset='90%'
                              stopColor='#EEF5FE'
                              stopOpacity={1}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type='monotone'
                          dataKey='count'
                          stroke='#73BCFC'
                          fill='url(#colorUv1)'
                          strokeDasharray='1 5'
                        />
                      </AreaChart>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      border: '2px solid whitesmoke',
                      height: 150,
                      borderRadius: 3,
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: 23, color: '#6FD5E8' }}>
                          48
                        </Typography>
                        <Typography sx={{ fontSize: 9 }}>EMPLOYED</Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: '#22CE8B',
                          fontWeight: 'bold',
                        }}
                      >
                        91%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AreaChart
                        width={270}
                        height={70}
                        data={[
                          { count: 0 },
                          { count: 4 },
                          { count: 3 },
                          { count: 7 },
                          { count: 4 },
                          { count: 5 },
                          { count: 3 },
                          { count: 4 },
                          { count: 0 },
                          { count: 3 },
                          { count: 0 },
                        ]}
                      >
                        <defs>
                          <linearGradient
                            id='colorUv2'
                            x1='0'
                            y1='0'
                            x2='0'
                            y2='1'
                          >
                            <stop
                              offset='5%'
                              stopColor='#9CE2EF'
                              stopOpacity={1}
                            />
                            <stop
                              offset='10%'
                              stopColor='#AAE6F1'
                              stopOpacity={1}
                            />
                            <stop
                              offset='40%'
                              stopColor='#BBEBF4'
                              stopOpacity={1}
                            />
                            <stop
                              offset='70%'
                              stopColor='#CCF0F7'
                              stopOpacity={1}
                            />
                            <stop
                              offset='90%'
                              stopColor='#EBF9FB'
                              stopOpacity={1}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type='monotone'
                          dataKey='count'
                          stroke='#5FD0E5'
                          fill='url(#colorUv2)'
                          strokeDasharray='1 5'
                        />
                      </AreaChart>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      border: '2px solid whitesmoke',
                      height: 150,
                      borderRadius: 3,
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: 23, color: '#2CD889' }}>
                          12
                        </Typography>
                        <Typography sx={{ fontSize: 9 }}>TREATED</Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: 10,
                          color: '#8C8C8C',
                          fontWeight: 'bold',
                        }}
                      >
                        112%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AreaChart
                        width={270}
                        height={70}
                        data={[
                          { count: 0 },
                          { count: 4 },
                          { count: 3 },
                          { count: 7 },
                          { count: 4 },
                          { count: 5 },
                          { count: 3 },
                          { count: 4 },
                          { count: 0 },
                          { count: 3 },
                          { count: 0 },
                        ]}
                      >
                        <defs>
                          <linearGradient
                            id='colorUv3'
                            x1='0'
                            y1='0'
                            x2='0'
                            y2='1'
                          >
                            <stop
                              offset='5%'
                              stopColor='#9EF0C7'
                              stopOpacity={1}
                            />
                            <stop
                              offset='10%'
                              stopColor='#AAF2CE'
                              stopOpacity={1}
                            />
                            <stop
                              offset='40%'
                              stopColor='#B9F4D6'
                              stopOpacity={1}
                            />
                            <stop
                              offset='70%'
                              stopColor='#C2F6DC'
                              stopOpacity={1}
                            />
                            <stop
                              offset='90%'
                              stopColor='#E9FCF2'
                              stopOpacity={1}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type='monotone'
                          dataKey='count'
                          stroke='#56DFA0'
                          fill='url(#colorUv3)'
                          strokeDasharray='1 5'
                        />
                      </AreaChart>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: 20, my: 2 }}>
              Client Status
            </Typography>

            <Box>
              {/* <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 3, p: 2 }}>
                    <Typography sx={{ color: '#51ADFC', fontWeight: 'bold' }}>
                      HOUSING STATUS
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <PieChart width={200} height={250}>
                        <Pie
                          data={data}
                          cx='50%'
                          cy='50%'
                          outerRadius={90}
                          innerRadius={60}
                          fill='#8884d8'
                          dataKey='value'
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: 4,
                        }}
                      >
                        {data?.map((item, index) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                height: 25,
                                bgcolor: COLORS[index],
                                px: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  //   fontWeight: 'bold',
                                  color: 'white',
                                  fontSize: 14,
                                }}
                              >
                                {item?.value / 100}%
                              </Typography>
                            </Box>
                            <Typography>{item?.name}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 3, p: 2 }}>
                    <Typography sx={{ color: '#33C3DE', fontWeight: 'bold' }}>
                      EMPLOYMENT STATUS
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <PieChart width={200} height={250}>
                        <Pie
                          data={data2}
                          cx='50%'
                          cy='50%'
                          outerRadius={90}
                          innerRadius={60}
                          fill='#8884d8'
                          dataKey='value'
                        >
                          {data2.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS2[index % COLORS2.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: 4,
                        }}
                      >
                        {data2?.map((item, index) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                height: 25,
                                bgcolor: COLORS2[index],
                                px: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  //   fontWeight: 'bold',
                                  color: 'white',
                                  fontSize: 14,
                                }}
                              >
                                {item?.value / 100}%
                              </Typography>
                            </Box>
                            <Typography>{item?.name}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 3, p: 2 }}>
                    <Typography sx={{ color: '#00D96D', fontWeight: 'bold' }}>
                      MEDICAL STATUS
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <PieChart width={200} height={250}>
                        <Pie
                          data={data3}
                          cx='50%'
                          cy='50%'
                          outerRadius={90}
                          innerRadius={60}
                          fill='#8884d8'
                          dataKey='value'
                        >
                          {data3.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS3[index % COLORS3.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: 4,
                        }}
                      >
                        {data3?.map((item, index) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                height: 25,
                                bgcolor: COLORS3[index],
                                px: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  //   fontWeight: 'bold',
                                  color: 'white',
                                  fontSize: 14,
                                }}
                              >
                                {item?.value / 100}%
                              </Typography>
                            </Box>
                            <Typography>{item?.name}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid> */}
              <Grid container spacing={3}>
                {summaryData?.data?.summaryDonutChartData?.map(
                  (item: any, index: number) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 3, p: 2 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{ color: '#51ADFC', fontWeight: 'bold' }}
                          >
                            {item?.name}
                          </Typography>
                          <IconButton
                            onClick={() => {
                              setOpenAddLabel(true)
                              setFieldIndex(index)
                            }}
                          >
                            <Edit sx={{ color: '#51ADFC' }} />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <PieChart width={200} height={250}>
                            <Pie
                              data={item?.values}
                              cx='50%'
                              cy='50%'
                              outerRadius={90}
                              innerRadius={60}
                              dataKey='value'
                            >
                              {data.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index]}
                                />
                              ))}
                            </Pie>
                          </PieChart>
                          <Box
                            sx={{
                              // display: 'flex',
                              // flexDirection: 'column',
                              // justifyContent: 'center',
                              py: 1,
                              gap: 2,
                              height: 300,
                              overflowY: 'auto',
                              '&::-webkit-scrollbar': {
                                width: 0,
                              },
                            }}
                          >
                            {item?.values?.map((elt: any, index: number) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                  py: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    height: 25,
                                    bgcolor: COLORS[index],
                                    px: 0.5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      //   fontWeight: 'bold',
                                      color: 'white',
                                      fontSize: 14,
                                    }}
                                  >
                                    {(
                                      (elt?.value * 100) /
                                      item?.values?.reduce(function (
                                        acc: any,
                                        el: any
                                      ) {
                                        return (acc += el?.value)
                                      },
                                      0)
                                    )?.toFixed(1)}
                                    %
                                  </Typography>
                                </Box>
                                <Typography>{elt?.name}</Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )
                )}
                {summaryData?.data &&
                  Array(
                    3 - Number(summaryData?.data?.summaryDonutChartData?.length)
                  )
                    .fill('')
                    .map((item, index) => (
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          sx={{
                            border: '2px solid #DCDCDC',
                            borderRadius: 3,
                            p: 2,
                            height: 300,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 3,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setOpenAddLabel(true)
                            setFieldIndex(
                              index +
                                summaryData?.data?.summaryDonutChartData?.length
                            )
                          }}
                        >
                          <Add sx={{ height: 100, width: 100 }} />
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 15,
                              textAlign: 'center',
                            }}
                          >
                            ADD REPORTING LABEL
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
              </Grid>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: 20, my: 2 }}>
              Client Goals
            </Typography>
            <Box>
              <Grid container spacing={3}>
                {summaryData?.data?.goalsWithStatusAndClients?.map(
                  (item: any, index: number) => (
                    <Grid item xs={12} sm={6} md={3}>
                      <Box
                        sx={{
                          border: '1px solid #DCDCDC',
                          borderRadius: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          height: 300,
                          px: 2,
                        }}
                      >
                        <Box sx={{ height: '100%' }}>
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 18,
                              py: 3,
                              color:
                                item?.status === 'TO_DO'
                                  ? GOALCOLORS[0]
                                  : item?.status === 'IN_PROGRESS'
                                  ? GOALCOLORS[1]
                                  : item?.status === 'COMPLETE'
                                  ? GOALCOLORS[2]
                                  : GOALCOLORS[3],
                            }}
                          >
                            {item?.status}
                          </Typography>
                          <Box
                            sx={{
                              bgcolor: '#E4ECF3',
                              borderRadius: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            <Box
                              sx={{
                                position: 'absolute',
                                bgcolor: '#F4FAFF',
                                width: 131,
                                height: 131,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                borderRadius: '100%',
                              }}
                            ></Box>
                            <CircularProgressWithLabel
                              value={item?.percentage}
                              fColor={
                                item?.status === 'TO_DO'
                                  ? GOALCOLORS[0]
                                  : item?.status === 'IN_PROGRESS'
                                  ? GOALCOLORS[1]
                                  : item?.status === 'COMPLETE'
                                  ? GOALCOLORS[2]
                                  : GOALCOLORS[3]
                              }
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            height: 260,
                            my: 3,
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                              width: 0,
                            },
                          }}
                        >
                          {item?.goals?.map((elt: any) => (
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                                my: 2,
                              }}
                            >
                              <Avatar
                                src={elt?.clientImage}
                                sx={{ width: 40, height: 40 }}
                              />
                              <Box>
                                <Typography sx={{ fontSize: 12 }}>
                                  {elt?.clientName?.split(' ')?.[0] +
                                    ' ' +
                                    elt?.clientName?.split(' ')?.[2]}
                                </Typography>
                                <Box
                                  sx={{
                                    border: '1px solid #E5ECF3',
                                    px: 0.2,
                                    py: 0.2,
                                    borderRadius: 1,
                                  }}
                                >
                                  <Typography sx={{ fontSize: 9 }}>
                                    {elt?.goalName}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: 20, my: 2 }}>
              Appointments
            </Typography>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={8}>
                  <Box
                    sx={{
                      bgcolor: '#F4FAFF',
                      height: 370,
                      px: 1,
                      py: 2,
                      borderRadius: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 4,
                        pb: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#767E91',
                          fontSize: 12,
                          fontWeight: 'bold',
                        }}
                      >
                        CLIENTS
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <Box
                          sx={{ bgcolor: '#2CD889', p: 1, borderRadius: 1 }}
                        ></Box>
                        <Box>
                          <Typography sx={{ color: '#2CD889', fontSize: 13 }}>
                            Appointments
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <AreaChart
                      width={1000}
                      height={310}
                      data={summaryData?.data?.appointmentSummary?.values}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                      <defs>
                        <linearGradient
                          id='colorUv3'
                          x1='0'
                          y1='0'
                          x2='0'
                          y2='1'
                        >
                          <stop
                            offset='5%'
                            stopColor='#9EF0C7'
                            stopOpacity={1}
                          />
                          <stop
                            offset='10%'
                            stopColor='#AAF2CE'
                            stopOpacity={1}
                          />
                          <stop
                            offset='40%'
                            stopColor='#B9F4D6'
                            stopOpacity={1}
                          />
                          <stop offset='70%' stopColor='gray' stopOpacity={1} />
                          <stop
                            offset='90%'
                            stopColor='white'
                            stopOpacity={1}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey='name' />
                      <YAxis dataKey='value' />
                      <CartesianGrid strokeDasharray='3 3' />
                      <Tooltip />
                      <Area
                        type='monotone'
                        dataKey='value'
                        stroke='#35D98E'
                        fillOpacity={1}
                        fill='url(#colorUv3)'
                      />
                    </AreaChart>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      bgcolor: '#F4FAFF',
                      height: 370,
                      px: 1,
                      py: 2,
                      borderRadius: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 4,
                        py: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#767E91',
                          fontSize: 12,
                          fontWeight: 'bold',
                        }}
                      >
                        APPOINTMENT OVERVIEW
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <PieChart width={230} height={230}>
                        <Pie
                          data={[
                            { name: 'Male', value: 25 },
                            { name: 'Female', value: 25 },
                            { name: 'Young', value: 25 },
                            { name: 'Adult', value: 25 },
                          ]}
                          cx='50%'
                          cy='50%'
                          outerRadius={87}
                          innerRadius={80}
                          dataKey='value'
                          paddingAngle={7}
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={
                                OVERVIEWCOLORS[index % OVERVIEWCOLORS.length]
                              }
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </Box>
                    <Box sx={{ px: 3 }}>
                      <Grid container spacing={2}>
                        {summaryData?.data?.appointmentOverview?.values?.map(
                          (item: any, index: number) => (
                            <Grid
                              item
                              xs={6}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    bgcolor: OVERVIEWCOLORS[index],
                                    py: 0.4,
                                    px: 2,
                                    borderRadius: 2,
                                  }}
                                ></Box>
                                <Typography
                                  sx={{
                                    color: '#B9C3D1',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {item?.value?.toFixed(0)}% {item?.key}
                                </Typography>
                              </Box>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel index={1} value={value}>
          {activeReports?.reports?.map((item: any) => (
            <Box
              sx={{
                bgcolor: '#F4FAFF',
                // height: 100,
                borderRadius: 5,
                my: 2,
                display: 'flex',
                alignItems: 'flex-start',
                gap: 5,
                px: 3,
                py: 3,
              }}
            >
              <Box sx={{ width: 100, display: 'flex' }}>
                <Box
                  sx={{
                    bgcolor: 'white',
                    boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                    py: 1,
                    px: 2,
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                    {item?.count}
                  </Typography>
                  <KeyboardArrowDown />
                </Box>
              </Box>
              <Box sx={{ width: 150 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 11,
                    color: '#4F6D9A',
                    my: 1,
                  }}
                >
                  Report Title
                </Typography>
                <Typography sx={{ fontWeight: 'bold', my: 1 }}>
                  {item?.title}
                </Typography>
              </Box>
              <Box sx={{ width: 200 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 11,
                    color: '#4F6D9A',
                    my: 1,
                  }}
                >
                  Program
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 2,
                    height: 35,
                  }}
                >
                  {item?.programs?.map((elt: any) => (
                    <Box
                      sx={{ bgcolor: 'white', px: 1, py: 0.2, borderRadius: 1 }}
                    >
                      <Typography
                        sx={{
                          color: '#7C92B4',
                          fontWeight: 'bold',
                          fontSize: 11,
                        }}
                      >
                        {elt?.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box sx={{ width: 150 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 11,
                    color: '#4F6D9A',
                    my: 1,
                  }}
                >
                  Program Manager
                </Typography>
                <Typography sx={{ fontWeight: 'bold', my: 1 }}>
                  {item?.programManager}
                </Typography>
              </Box>
              <Box sx={{ width: 150 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 11,
                    color: '#4F6D9A',
                    my: 1,
                  }}
                >
                  Date Range
                </Typography>
                <Typography sx={{ fontWeight: 'bold', my: 1, fontSize: 12 }}>
                  {moment(item?.startDate).format('ll')} -{' '}
                  {moment(item?.endDate).format('ll')}
                </Typography>
              </Box>
              <Box sx={{ width: 170 }}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 11,
                    color: '#4F6D9A',
                    my: 1,
                  }}
                >
                  Notes
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                    12 People
                  </Typography>
                  <Box
                    sx={{ borderRadius: '100%', bgcolor: 'gray', p: 0.2 }}
                  ></Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Typography sx={{ fontSize: 14 }}>Notes</Typography>
                    <ReportProblem
                      sx={{ color: '#C44141', width: 20, height: 20 }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 11,
                    color: '#4F6D9A',
                    my: 1,
                  }}
                >
                  Action
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      border: '1px solid #BBC4DD',
                      px: 1,
                      py: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      borderRadius: 5,
                    }}
                  >
                    <Message sx={{ color: '#0178DD' }} />
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: '#0178DD',
                      }}
                    >
                      Remind Program Manager
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid gray',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '100%',
                      p: 0.5,
                    }}
                  >
                    <Download sx={{ color: '#0F7FDF' }} />
                  </Box>
                  <Box
                    sx={{
                      bgcolor: '#3376D6',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '100%',
                      p: 0.5,
                    }}
                  >
                    <Replay sx={{ color: 'white' }} />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 3 }}>
            <IconButton>
              <KeyboardArrowDown />
            </IconButton>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 3,
                gap: 10,
                p: 2,
              }}
            >
              <Avatar />
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Kidus Ababa
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                0915572560
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Male
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                34
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                01/01/2023
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                English
              </Typography>{' '}
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Black
              </Typography>{' '}
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                01/01/2023
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Demeke Mekonnen
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 3 }}>
            <IconButton>
              <KeyboardArrowDown />
            </IconButton>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 3,
                gap: 10,
                p: 2,
              }}
            >
              <Avatar />
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Kidus Ababa
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                0915572560
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Male
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                34
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                01/01/2023
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                English
              </Typography>{' '}
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Black
              </Typography>{' '}
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                01/01/2023
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Demeke Mekonnen
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 3 }}>
            <IconButton
              onClick={() =>
                openDetails ? setOpenDetails(false) : setOpenDetails(true)
              }
            >
              {openDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Box
              sx={{
                border: '1px solid gray',

                borderRadius: 3,
                p: 2,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <Avatar />
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  Kidus Ababa
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  0915572560
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  Male
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  34
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  01/01/2023
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  English
                </Typography>{' '}
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  Black
                </Typography>{' '}
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  01/01/2023
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  Demeke Mekonnen
                </Typography>
              </Box>
              {openDetails && (
                <Box sx={{ my: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8}>
                      <Box sx={{ bgcolor: '#FBFBFC', p: 2, borderRadius: 3 }}>
                        <Box
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Button>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Edit />
                              <Typography sx={{ fontSize: 12 }}>
                                Edit
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Box sx={{ bgcolor: '#F8FCFF', p: 2, borderRadius: 3 }}>
                        <Box
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Button>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Edit />
                              <Typography sx={{ fontSize: 12 }}>
                                Edit
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel index={2} value={value}>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              height: 100,
              borderRadius: 5,
              my: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              px: 3,
            }}
          >
            <Box
              sx={{
                bgcolor: 'white',
                boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                py: 1,
                px: 2,
                borderRadius: 2,
              }}
            >
              123
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 11,
                  color: '#4F6D9A',
                  my: 1,
                }}
              >
                Report Title
              </Typography>
              <Typography sx={{ fontWeight: 'bold', my: 1 }}>
                Dec-12 2024
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 11,
                  color: '#4F6D9A',
                  my: 1,
                }}
              >
                Program
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  height: 35,
                }}
              >
                <Box sx={{ bgcolor: 'white' }}>
                  <Typography
                    sx={{
                      color: '#7C92B4',
                      fontWeight: 'bold',
                      fontSize: 11,
                    }}
                  >
                    Comprehensive Need
                  </Typography>
                </Box>
                <Box sx={{ bgcolor: 'white' }}>
                  <Typography
                    sx={{ color: '#7C92B4', fontWeight: 'bold', fontSize: 11 }}
                  >
                    Mental Need
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 11,
                  color: '#4F6D9A',
                  my: 1,
                }}
              >
                Program Manager
              </Typography>
              <Typography sx={{ fontWeight: 'bold', my: 1 }}>
                Demeke Mekonnen
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 11,
                  color: '#4F6D9A',
                  my: 1,
                }}
              >
                Date Range
              </Typography>
              <Typography sx={{ fontWeight: 'bold', my: 1 }}>
                Dec-12 2024
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 11,
                  color: '#4F6D9A',
                  my: 1,
                }}
              >
                Insurance
              </Typography>
              <Typography sx={{ fontWeight: 'bold', my: 1 }}>Lion</Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 11,
                  color: '#4F6D9A',
                  my: 1,
                }}
              >
                Action
              </Typography>
              <Box
                sx={{
                  border: '1px solid gray',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '100%',
                  p: 0.5,
                }}
              >
                <Download sx={{ color: '#0F7FDF' }} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 3 }}>
            <IconButton>
              <KeyboardArrowDown />
            </IconButton>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 3,
                gap: 10,
                p: 2,
              }}
            >
              <Avatar />
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Kidus Ababa
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                0915572560
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Male
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                34
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                01/01/2023
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                English
              </Typography>{' '}
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Black
              </Typography>{' '}
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                01/01/2023
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Demeke Mekonnen
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 3 }}>
            <IconButton>
              <KeyboardArrowDown />
            </IconButton>
            <Box
              sx={{
                border: '1px solid gray',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 3,
                gap: 10,
                p: 2,
              }}
            >
              <Avatar />
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Kidus Ababa
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                0915572560
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Male
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                34
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                01/01/2023
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                English
              </Typography>{' '}
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Black
              </Typography>{' '}
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                01/01/2023
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                Demeke Mekonnen
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: 3 }}>
            <IconButton
              onClick={() =>
                openDetails ? setOpenDetails(false) : setOpenDetails(true)
              }
            >
              {openDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Box
              sx={{
                border: '1px solid gray',

                borderRadius: 3,
                p: 2,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <Avatar />
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  Kidus Ababa
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  0915572560
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  Male
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  34
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  01/01/2023
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  English
                </Typography>{' '}
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  Black
                </Typography>{' '}
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  01/01/2023
                </Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  Demeke Mekonnen
                </Typography>
              </Box>
              {openDetails && (
                <Box sx={{ my: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8}>
                      <Box sx={{ bgcolor: '#FBFBFC', p: 2, borderRadius: 3 }}>
                        <Box
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Button>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Edit />
                              <Typography sx={{ fontSize: 12 }}>
                                Edit
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Box sx={{ bgcolor: '#F8FCFF', p: 2, borderRadius: 3 }}>
                        <Box
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Button>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              <Edit />
                              <Typography sx={{ fontSize: 12 }}>
                                Edit
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>{' '}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              p: 1,
                            }}
                          >
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Member Residential Address Line 1:
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                              100 N Alta Ave
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <GenerateReport
        open={openGenerate}
        close={() => setOpenGenerate(false)}
      />
      {openAddLabel && fieldIndex >= 0 && (
        <AddReportingLabel
          open={openAddLabel}
          close={() => setOpenAddLabel(false)}
          refetch={refetch}
          setRefetch={setRefetch}
          fieldLabels={summaryData?.data?.fieldLabels}
          fieldIndex={fieldIndex}
        />
      )}
    </Box>
  )
}
