import { Close, LocationOn } from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import axios from 'axios'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { BASE_URL, STAGING_BASE_URL } from '../../utils/config'
import { authHeader } from '../../utils/auth'

export default function AddAppointment({
  open,
  close,
  refetch,
  setRefetch,
}: {
  open: boolean
  close: any
  refetch?: boolean
  setRefetch?: any
}) {
  const [meetingType, setmeetingType] = useState('inperson')
  const [color, setColor] = useState('')
  const [selectedParticipants, setSelectedParticipants] = useState([]) as any
  const [appDate, setAppDate] = useState() as any
  const [startTime, setStartTime] = useState() as any
  const [endTime, setEndTime] = useState() as any
  const [appTypeId, setAppTypeId] = useState() as any
  const [address, setAddress] = useState() as any
  const [baseClientLocation, setBaseClientLocation] = useState() as any
  const [reminderId, setReminderId] = useState() as any
  const [fileUrl, setFileUrl] = useState<string>()
  const [fileState, setFileState] = useState<any>()
  const [url, setUrl] = useState() as any

  console.log({ fileUrl })

  useEffect(() => {
    console.log({ fileState })
    if (fileState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${fileState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [fileState])
  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(fileState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => setFileUrl(url?.split('?')?.[0]))
      .catch((e) => console.log({ e }))
  }

  console.log({
    appDate,
    selectedParticipants,
    startTime,
    endTime,
    color,
    meetingType,
    address,
  })

  const { data: appointmentTypes } = useFetch(
    'appointmenttype',
    '/public/appointment/appointment-types/list'
  )

  const { data: reminderTemplates } = useFetch(
    'reminder',
    '/public/appointment/reminder-templates'
  )
  console.log({ reminderTemplates })
  const { data: participants, mutate: fetchParticipants } = usePost(
    '/public/appointment/participants/list'
  )

  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/appointment/appointments'
  )

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Appointment Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetch(!refetch)
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchParticipants(dataP)
  }, [])

  console.log({ appointmentTypes, participants })

  const colors = [
    '#3376D6',
    '#2CD889',
    '#00B5D6',
    '#09244B',
    '#DB0000',
    '#8B33D6',
    '#DD7C0B',
    '#FFEB00',
  ]

  type FormValues = {
    appointment_type_id: string
    participant_ids: string[]
    reason: string
    start_time: string
    end_time: string
    note: string
    document_id: string
    reminder_template_id: string
    confirmation_requested: boolean
    color: string
    meeting_type: number
    meeting_details: {
      location_coordinates: {
        latitude: number
        longitude: number
      }
      meeting_link: string
      phone_number: string
    }
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      appointment_type_id: '',
      participant_ids: [],
      reason: '',
      start_time: '',
      end_time: '',
      note: '',
      document_id: '',
      reminder_template_id: '',
      confirmation_requested: false,
      color: '',
      meeting_type: 1,
      meeting_details: {
        location_coordinates: {
          latitude: 1.4,
          longitude: 1.5,
        },
        meeting_link: '',
        phone_number: '',
      },
    },
    mode: 'onBlur',
  })

  const onSubmit = (data: any) => {
    let partic: string[] = []
    selectedParticipants.map((item: any) => {
      partic?.push(item.id)
    })
    data.start_time = startTime?.$d?.toISOString()?.replace('.000', '')
    data.end_time = endTime?.$d?.toISOString()?.replace('.000', '')
    data.participant_ids = partic
    data.appointment_type_id = appTypeId.id
    data.reminder_template_id = reminderId.id
    data.color = color
    data.meeting_details.location_coordinates.latitude = address?.latitude
    data.meeting_details.location_coordinates.longitude = address?.longitude
    data.meeting_details.location_coordinates.name = address?.addressLine1

    if (meetingType === 'inperson') {
      data.meeting_type = 'IN_PERSON'
    } else if (meetingType === 'video') {
      data.meeting_type = 'VIDEO'
    } else {
      data.meeting_type = 'CALL'
    }
    console.log({ data })
    mutate(data)
  }

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue: setPlacesValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'GO_PLACES',
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })

  useEffect(() => {
    axios
      .get(
        `${STAGING_BASE_URL}/maps/api/place/details/json?place_id=${baseClientLocation?.place_id}`
      )
      .then((res) => {
        console.log({ data: res?.data })
        if (res?.data?.result) {
          setAddress({
            country: res?.data?.result?.address_components?.filter(
              (item: any) => item?.types?.includes('country')
            )[0]?.long_name,
            state:
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('administrative_area_level_1')
              )[0]?.long_name ||
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('locality')
              )[0]?.long_name,
            city:
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('locality')
              )[0]?.long_name ||
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('administrative_area_level_2')
              )[0]?.long_name,
            addressLine1: baseClientLocation?.description,
            addressLine2: '',
            postalCode: '',
            latitude: res?.data?.result?.geometry?.location?.lat,
            longitude: res?.data?.result?.geometry?.location?.lng,
          })
        } else {
          setAddress()
        }
      })
      .catch((e) => console.log(e))
  }, [baseClientLocation])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: '80vw', md: '50vw' },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Add New Appointment
                </Typography>
                <Box> </Box>
              </Box>
              <Box
                sx={{
                  px: { xs: 5, sm: 10 },
                  py: 5,
                  height: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Grid container columnSpacing={5} rowSpacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Appointment Type
                    </Typography>
                    <Autocomplete
                      options={appointmentTypes?.types || []}
                      getOptionLabel={(item: any) => item.title}
                      size='small'
                      onChange={(event, value) => {
                        setAppTypeId(value)
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size='small' fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Choose Color
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {colors.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            bgcolor: color === item ? '#F1F1F1' : 'white',
                            p: 0.8,
                            borderRadius: '100%',
                            cursor: 'pointer',
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: item,
                              p: 1,
                              borderRadius: '100%',
                              cursor: 'pointer',
                            }}
                            onClick={() => setColor(item)}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Person(s)
                    </Typography>
                    <Autocomplete
                      options={participants?.data?.participants || []}
                      getOptionLabel={(item: any) => item.name}
                      size='small'
                      multiple
                      onChange={(event, value) => {
                        setSelectedParticipants(value)
                      }}
                      renderTags={(value, getTagProps) => (
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          {value.map((option, index) => (
                            <Box
                              sx={{
                                bgcolor: '#F4FAFF',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #ECEDED',
                                px: 1,
                                py: 0.5,
                                borderRadius: 1.5,
                              }}
                            >
                              <Avatar sx={{ width: 20, height: 20 }} />
                              <Typography
                                sx={{ fontWeight: 'bold', fontSize: 12 }}
                              >
                                {option?.name?.split(' ')[0]}
                              </Typography>
                              <IconButton
                                sx={{
                                  border: '1px solid gray',
                                  width: 16,
                                  height: 16,
                                }}
                                onClick={() =>
                                  getTagProps({ index })?.onDelete(option)
                                }
                              >
                                <Close
                                  sx={{
                                    width: 13,
                                    height: 13,
                                    color: 'gray',
                                  }}
                                />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component='li'
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 6,
                            width: '100%',
                          }}
                          {...props}
                        >
                          <Avatar sx={{ width: 45, height: 45 }} />

                          <Typography sx={{ width: 200 }}>
                            {option?.name}
                          </Typography>
                          <Box
                            sx={{
                              bgcolor: '#F4FAFF',
                              px: 1,
                              borderRadius: 5,
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#4D72A6',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: 12,
                              }}
                            >
                              Therapist
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} size='small' fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Reason
                    </Typography>

                    <TextField
                      size='small'
                      fullWidth
                      {...register('reason', {
                        required: true,
                      })}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Choose Date
                    </Typography>
                    <DatePicker
                      value={appDate}
                      onChange={(newValue) => setAppDate(newValue)}
                      sx={{ width: '100%' }}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Choose Meeting Type
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: 1,
                      }}
                    >
                      <FormControl sx={{ display: 'flex' }}>
                        <RadioGroup
                          value={meetingType}
                          onChange={(e) => {
                            setmeetingType(e.target.value)
                          }}
                          row
                          name='meetingType'
                        >
                          <FormControlLabel
                            value='inperson'
                            control={
                              <Radio
                                sx={{
                                  color:
                                    meetingType == 'inperson'
                                      ? '#fff'
                                      : '#5E7FA4',
                                  '&.MuiButtonBase-root': {
                                    padding: '0px',
                                    marginRight: '5px',
                                    '&.Mui-checked': {
                                      color: '#fff',
                                    },
                                  },
                                }}
                                {...register('meeting_type', {
                                  required: true,
                                })}
                              />
                            }
                            label='Inperson'
                            sx={{
                              px: 3,
                              py: 1,
                              // marginRight: '0',
                              // marginTop: '90px',
                              background:
                                meetingType == 'inperson'
                                  ? '#0A81F5'
                                  : '#F7F7F7',
                              color:
                                meetingType == 'inperson' ? '#fff' : '#000',
                              borderRadius: '10px',
                              // height: '35px',
                            }}
                          />
                          <FormControlLabel
                            value='video'
                            control={
                              <Radio
                                sx={{
                                  color:
                                    meetingType == 'video' ? '#fff' : '#5E7FA4',
                                  '&.MuiButtonBase-root': {
                                    padding: '0px',
                                    marginRight: '5px',
                                    '&.Mui-checked': {
                                      color: '#fff',
                                    },
                                  },
                                }}
                                {...register('meeting_type', {
                                  required: true,
                                })}
                              />
                            }
                            label='Video'
                            sx={{
                              px: 2,
                              //   marginRight: '0',
                              //   marginTop: '15px',
                              background:
                                meetingType == 'video' ? '#0A81F5' : '#F7F7F7',
                              color: meetingType == 'video' ? '#fff' : '#000',
                              borderRadius: '10px',
                              //   height: '35px',
                            }}
                          />
                          <FormControlLabel
                            value='call'
                            control={
                              <Radio
                                sx={{
                                  color:
                                    meetingType == 'call' ? '#fff' : '#5E7FA4',
                                  '&.MuiButtonBase-root': {
                                    padding: '0px',
                                    marginRight: '5px',
                                    '&.Mui-checked': {
                                      color: '#fff',
                                    },
                                  },
                                }}
                                {...register('meeting_type', {
                                  required: true,
                                })}
                              />
                            }
                            label='Call'
                            sx={{
                              px: 2,
                              //   marginRight: '0',
                              //   marginTop: '15px',
                              background:
                                meetingType == 'call' ? '#0A81F5' : '#F7F7F7',
                              color: meetingType == 'call' ? '#fff' : '#000',
                              borderRadius: '10px',
                              //   height: '35px',
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                  {meetingType === 'inperson' ? (
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                        Choose Location
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          width: '100%',
                        }}
                      >
                        <Autocomplete
                          options={data || []}
                          getOptionLabel={(option) => option?.description}
                          onChange={(event, value) => {
                            setBaseClientLocation(value)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              onChange={(e) => setPlacesValue(e.target.value)}
                            />
                          )}
                          fullWidth
                        />
                        {/* <IconButton onClick={() => setFormState('map')}>
                          <LocationOn sx={{ color: 'primary.main' }} />
                        </IconButton> */}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                        Meeting Link
                      </Typography>

                      <TextField
                        size='small'
                        fullWidth
                        {...register('meeting_details.meeting_link', {
                          required: true,
                        })}
                      />
                    </Grid>
                  )}
                </Grid>
                <Box
                  sx={{
                    p: 5,
                    border: '1px solid lightgray',
                    borderRadius: 4,
                    width: '100%',
                    my: 5,
                    display:
                      address && meetingType === 'inperson' ? 'block' : 'none',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                          Country:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: 'gray',
                          }}
                        >
                          {address?.country}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                          State:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: 'gray',
                          }}
                        >
                          {address?.state}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                          City:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: 'gray',
                          }}
                        >
                          {address?.city}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
                          Address:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: 'gray',
                          }}
                        >
                          {address?.addressLine1}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container columnSpacing={5} rowSpacing={3} sx={{ my: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Time - Start
                    </Typography>
                    <DateTimePicker
                      slotProps={{
                        textField: { size: 'small', fullWidth: true },
                      }}
                      value={startTime}
                      onChange={(newValue) => setStartTime(newValue)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Time - End
                    </Typography>
                    <DateTimePicker
                      slotProps={{
                        textField: { size: 'small', fullWidth: true },
                      }}
                      value={endTime}
                      onChange={(newValue) => setEndTime(newValue)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Note
                    </Typography>

                    <textarea
                      style={{
                        width: '100%',
                        height: 100,
                        resize: 'none',
                        borderRadius: 6,
                        border: '1px solid #DDE1EE',
                      }}
                      {...register('note', {
                        required: true,
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Attatch File
                    </Typography>

                    {/* <textarea
                    style={{
                      width: '100%',
                      height: 100,
                      resize: 'none',
                      borderRadius: 6,
                      border: '1px solid #DDE1EE',
                    }}
                  /> */}
                    <FileUpload
                      name='attatchedFile'
                      type='FILE'
                      size={100}
                      setFileState={setFileState}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Choose Reminder Template
                    </Typography>

                    <Autocomplete
                      options={reminderTemplates?.templates || []}
                      getOptionLabel={(item: any) => item.name}
                      size='small'
                      onChange={(event, value) => {
                        setReminderId(value)
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size='small' fullWidth />
                      )}
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ my: 3 }} />
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox {...register('confirmation_requested')} />
                    }
                    label='Ask confirmation of meeting'
                    slotProps={{ typography: { sx: { color: 'gray' } } }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  gap: 5,
                  bottom: 0,
                  width: { xs: '100vw', sm: '80vw', md: '50vw' },
                  height: 80,
                  pb: 3,
                  px: 10,
                  // bgcolor: '#F5F5F5',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 120,
                    height: 55,
                    bgcolor: '#EFEFEF',
                    borderRadius: 2,
                    cursor: 'pointer',
                  }}
                  onClick={close}
                >
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    Cancel
                  </Typography>
                </Box>
                <Button
                  variant='contained'
                  type='submit'
                  // onClick={handleSubmit}
                  sx={{ px: 2, borderRadius: 2, height: 50 }}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                      Create Appointment
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
