import {
  Add,
  AddCard,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ManageAccounts,
  Message,
  Search,
  Send,
  TagFaces,
  Visibility,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import PdfImage from '../assets/pdfimage.svg'
import AddDocument from '../components/Documents/AddDocument'
import DocumentDetail from '../components/Documents/DocumentDetail'
import { usePost } from '../hooks/usePost'
import { authHeader } from '../utils/auth'
import { BASE_URL } from '../utils/config'
import axios from 'axios'
import AddDocumentCategory from '../components/Configurations/Document/AddDocumentCategory'
import moment from 'moment'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function CustomTabPanelVertical(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function a11yPropsVertical(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Documents() {
  const [value, setValue] = useState(0)
  const [documentId, setDocumentId] = useState() as any

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueVertical(0)
    setValue(newValue)
  }
  const [valueVertical, setValueVertical] = useState(0)

  const handleChangeVertical = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValueVertical(newValue)
  }

  const [categoryId, setCategoryId] = useState() as any
  const [subcategoryId, setSubCategoryId] = useState() as any
  const [openAddDocument, setOpenAddDocument] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [openNewDocuemntCategory, setOpenNewDocumentCategory] = useState(false)
  const [documentSubCategoryId, setDocumentSubCategoryId] = useState() as any
  const [createDocType, setCreateDocType] = useState() as any

  const [refetchDocumentCategory, setRefetchDocumentCategory] = useState(false)
  const { data: documentCategories, mutate: fetchDocumentCategories } = usePost(
    '/public/document/document_categories/list'
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDocumentCategories(dataP)
  }, [refetchDocumentCategory])

  const { data: documents, mutate: fetchDocuments } = usePost(
    '/public/document/documents/list'
  )
  const { data: companyDocuments, mutate: fetchCompanyDocuments } = usePost(
    '/public/document/company_documents/list'
  )
  const { data: clientDocuments, mutate: fetchClientDocuments } = usePost(
    '/public/document/client_documents/list'
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDocuments(dataP)
  }, [fetchDocuments])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchCompanyDocuments(dataP)
  }, [])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchClientDocuments(dataP)
  }, [])

  console.log({ documents, clientDocuments, companyDocuments })

  const types = ['All', 'Client', 'Company']

  const subDocuments = documents?.data?.documents?.filter(
    (item: any) => item?.documentSubCategoryId === documentSubCategoryId
  )

  const documentsList = documentSubCategoryId
    ? subDocuments
    : documents?.data?.documents
  const clientDocumentsList = documentSubCategoryId
    ? subDocuments
    : clientDocuments?.data?.documents
  const companyDocumentsList = documentSubCategoryId
    ? subDocuments
    : companyDocuments?.data?.documents

  return (
    <Box sx={{ pt: 3 }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          pt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          //   alignItems: 'center',
          px: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Box sx={{ py: 1 }}>
            <TextField
              InputProps={{
                startAdornment: <Search />,
                sx: {
                  '&.MuiOutlinedInput-root': {
                    borderRadius: 8,
                    height: 45,
                    bgcolor: '#E6F4FF ',
                  },
                },
              }}
              placeholder='Search'
              //   sx={{ my: 1 }}
            />
          </Box>
          <Tabs value={value} onChange={handleChange}>
            {types?.map((item, index) => (
              <Tab
                label={item}
                {...a11yProps(index)}
                sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ height: '100%', px: 3 }}>
          <Button
            variant='contained'
            sx={{ borderRadius: 5, py: 1 }}
            onClick={() => {
              setCreateDocType(
                value === 0
                  ? 'allDoc'
                  : value === 1
                  ? 'clientDoc'
                  : 'companyDoc'
              )
              setOpenAddDocument(true)
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Add />
              <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                New Document
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          height: 'calc(100vh - 170px)',
        }}
      >
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={2}>
            <Box
              sx={{
                pt: 2,
                px: 3,
              }}
            >
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setCategoryId()
                  setSubCategoryId()
                  setDocumentSubCategoryId()
                }}
              >
                <Typography sx={{ fontWeight: 'bold', py: 1, fontSize: 15 }}>
                  All
                </Typography>
              </Box>
              {documentCategories?.data?.documentCategories?.map(
                (item: any) => (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        py: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (categoryId === item?.id) {
                          setCategoryId()
                          setSubCategoryId()
                        } else {
                          setCategoryId(item?.id)
                        }
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
                        {item?.name?.slice(0, 11)}
                      </Typography>
                      {categoryId === item?.id ? (
                        <KeyboardArrowUp sx={{ width: 30, height: 30 }} />
                      ) : (
                        <KeyboardArrowDown sx={{ width: 30, height: 30 }} />
                      )}
                    </Box>
                    {categoryId === item?.id && (
                      <Box>
                        {item?.documentSubCategories?.map((elt: any) => (
                          <Box
                            sx={{
                              cursor: 'pointer',
                              '&:hover': { bgcolor: '#F7F7F7' },
                              py: 0.5,
                              px: 1,
                              //   bgcolor:
                              //     elt?.id === departmentId ? '#F7F7F7' : 'white',
                            }}
                            onClick={() => setDocumentSubCategoryId(elt?.id)}
                          >
                            <Typography
                              sx={{
                                color: '#A0AABE',
                                fontSize: 12,
                                fontWeight: 'bold',
                              }}
                            >
                              {elt?.name}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                )
              )}
              <Button onClick={() => setOpenNewDocumentCategory(true)}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      border: '2px solid blue',
                      borderRadius: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Add />
                  </Box>
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    Add Category
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={10} sx={{ px: 7, py: 3 }}>
            <CustomTabPanel value={value} index={0}>
              {documentsList?.map((item: any) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    my: 3,
                  }}
                >
                  <Box component='img' src={PdfImage} />
                  <Box sx={{ width: 350 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                        }}
                      >
                        #K01B2
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        Kidus Abba
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        0912345678
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        {moment(item?.createdAt)?.format('l')}
                      </Typography>
                    </Box>
                    <Typography sx={{ color: '#495377', fontSize: 15 }}>
                      {item?.title}
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        ({`V${item?.documentVersions?.length}`})
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      flexWrap: 'wrap',
                      width: 150,
                    }}
                  >
                    {item?.programs?.map((elt: any) => (
                      <Box
                        sx={{
                          bgcolor: '#E6F4FF',
                          px: 2,
                          py: 0.5,
                          borderRadius: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#56739F',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {elt?.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      bgcolor: '#F0F8FF',
                      borderRadius: 7,
                      py: 0.5,
                      pl: 0.5,
                      pr: 1,
                    }}
                  >
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      src={item?.documentCurrentVersion?.createdBy?.imageUrl}
                    />
                    <Typography
                      sx={{
                        color: '#8EA1BE',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      By{' '}
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: 'normal',
                          color: 'black',
                        }}
                      >
                        {item?.documentCurrentVersion?.createdBy?.firstName}
                      </span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <IconButton
                      onClick={() => {
                        setDocumentId(item?.id)
                        setOpenDetail(true)
                      }}
                    >
                      <Visibility sx={{ color: '#3376D6' }} />
                    </IconButton>
                    {/* <Edit sx={{ color: '#3376D6' }} />
                      <Send sx={{ color: '#3376D6' }} /> */}
                  </Box>
                </Box>
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {clientDocumentsList?.map((item: any) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    my: 3,
                  }}
                >
                  <Box component='img' src={PdfImage} />
                  <Box sx={{ width: 350 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                        }}
                      >
                        #K01B2
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        Kidus Abba
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        0912345678
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        {moment(item?.createdAt)?.format('l')}
                      </Typography>
                    </Box>
                    <Typography sx={{ color: '#495377', fontSize: 15 }}>
                      {item?.title}
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        ({`V${item?.documentVersions?.length}`})
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      flexWrap: 'wrap',
                      width: 150,
                    }}
                  >
                    {item?.programs?.map((elt: any) => (
                      <Box
                        sx={{
                          bgcolor: '#E6F4FF',
                          px: 1,
                          py: 0.2,
                          borderRadius: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#92999F',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {elt?.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                      sx={{
                        color: '#8EA1BE',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      Added By
                    </Typography>
                    <Avatar sx={{ width: 30, height: 30 }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <IconButton
                      onClick={() => {
                        setDocumentId(item?.id)
                        setOpenDetail(true)
                      }}
                    >
                      <Visibility sx={{ color: '#3376D6' }} />
                    </IconButton>
                    {/* <Edit sx={{ color: '#3376D6' }} />
                      <Send sx={{ color: '#3376D6' }} /> */}
                  </Box>
                </Box>
              ))}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {companyDocumentsList?.map((item: any) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    my: 3,
                  }}
                >
                  <Box component='img' src={PdfImage} />
                  <Box sx={{ width: 350 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                        }}
                      >
                        #K01B2
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        Kidus Abba
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        0912345678
                      </Typography>
                      <Box
                        sx={{
                          bgcolor: '#4F6D9A',
                          p: 0.25,
                          borderRadius: '100%',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 12,
                          color: '#7F90B4',
                          // fontWeight: 'bold',
                        }}
                      >
                        {moment(item?.createdAt)?.format('l')}
                      </Typography>
                    </Box>
                    <Typography sx={{ color: '#495377', fontSize: 15 }}>
                      {item?.title}
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        ({`V${item?.documentVersions?.length}`})
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      flexWrap: 'wrap',
                      width: 150,
                    }}
                  >
                    {item?.programs?.map((elt: any) => (
                      <Box
                        sx={{
                          bgcolor: '#E6F4FF',
                          px: 1,
                          py: 0.2,
                          borderRadius: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#92999F',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {elt?.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                      sx={{
                        color: '#8EA1BE',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      Added By
                    </Typography>
                    <Avatar sx={{ width: 30, height: 30 }} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    <IconButton
                      onClick={() => {
                        setDocumentId(item?.id)
                        setOpenDetail(true)
                      }}
                    >
                      <Visibility sx={{ color: '#3376D6' }} />
                    </IconButton>
                    {/* <Edit sx={{ color: '#3376D6' }} />
                      <Send sx={{ color: '#3376D6' }} /> */}
                  </Box>
                </Box>
              ))}
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Box>

      <AddDocumentCategory
        open={openNewDocuemntCategory}
        close={() => setOpenNewDocumentCategory(false)}
        refetch={refetchDocumentCategory}
        setRefetch={setRefetchDocumentCategory}
      />
      <AddDocument
        open={openAddDocument}
        close={() => setOpenAddDocument(false)}
        createDocType={createDocType}
      />
      {documentId && (
        <DocumentDetail
          open={openDetail}
          close={() => setOpenDetail(false)}
          documentData={documents?.data?.documents?.find(
            (item: any) => item?.id === documentId
          )}
        />
      )}
    </Box>
  )
}
