import {
  Add,
  AddCard,
  Delete,
  ManageAccounts,
  Message,
  Search,
  TagFaces,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import CreateMessage from '../components/messaging/CreateMessage'
import TagPeople from '../components/messaging/TagPeople'
import { useFetchEmail } from '../hooks/useFetch'
import { getUser } from '../utils/auth'
import { UserContext } from '../context/UserContext'
import { use } from 'i18next'
import moment from 'moment'
import { useDelete } from '../hooks/useDelete'
import axios from 'axios'
import { EMAIL_BASE_URL } from '../utils/config'
import ThreadMessage from '../components/messaging/ThreadMessage'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function CustomTabPanelVertical(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function a11yPropsVertical(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Messaging() {
  const [value, setValue] = useState(0)
  const [openTag, setOpenTag] = useState(false)
  const [messageId, setMessageId] = useState('') as any
  const [openNewMessage, setOpenNewMessage] = useState(false)
  const [openThread, setOpenThread] = useState(false)
  // const [messageIdToDelete, setMessageIdToDelete] = useState('') as any

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueVertical(0)
    setValue(newValue)
  }
  const [valueVertical, setValueVertical] = useState(0)

  const handleChangeVertical = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValueVertical(newValue)
  }

  const [receivedEmails, setReceivedEmails] = useState() as any
  const [sentEmails, setSentEmails] = useState() as any
  const [draftEmails, setDraftEmails] = useState() as any
  const [spamEmails, setSpamEmails] = useState() as any
  const [trashEmails, setTrashEmails] = useState() as any
  const [recipientEmail, setRecipientEmail] = useState() as any

  console.log({ recipientEmail })

  const { user }: any = useContext(UserContext)

  // const { data: receivedEmails, refetch } = useFetchEmail(
  //   'received-emails',
  //   `/accounts/${user?.id}/messages/inbox`
  // )
  // const { data: sentEmails, refetch: refetchSent } = useFetchEmail(
  //   'sent-emails',
  //   `/accounts/${user?.id}/messages/sent`
  // )
  // const { data: draftEmails, refetch: refetchDrafts } = useFetchEmail(
  //   'draft-emails',
  //   `/accounts/${user?.id}/messages/drafts`
  // )
  // const { data: spamEmails, refetch: refetchSpam } = useFetchEmail(
  //   'spam-emails',
  //   `/accounts/${user?.id}/messages/spam`
  // )
  // const { data: trashEmails, refetch: refetchTrash } = useFetchEmail(
  //   'trash-emails',
  //   `/accounts/${user?.id}/messages/trash`
  // )

  const { mutate: deleteEmail } = useDelete(`/accounts/${user?.id}/messages`)

  useEffect(() => {
    if (user) {
      axios
        .get(`${EMAIL_BASE_URL}/accounts/${user?.id}/messages/inbox`)
        .then((res) => setReceivedEmails(res?.data))
        .catch((e) => console.log(e))
      axios
        .get(`${EMAIL_BASE_URL}/accounts/${user?.id}/messages/sent`)
        .then((res) => setSentEmails(res?.data))
        .catch((e) => console.log(e))
      axios
        .get(`${EMAIL_BASE_URL}/accounts/${user?.id}/messages/drafts`)
        .then((res) => setDraftEmails(res?.data))
        .catch((e) => console.log(e))
      axios
        .get(`${EMAIL_BASE_URL}/accounts/${user?.id}/messages/spam`)
        .then((res) => setSpamEmails(res?.data))
        .catch((e) => console.log(e))
      axios
        .get(`${EMAIL_BASE_URL}/accounts/${user?.id}/messages/trash`)
        .then((res) => setTrashEmails(res?.data))
        .catch((e) => console.log(e))
    }
    // refetch()
    // refetchSent()
    // refetchDrafts()
    // refetchSpam()
    // refetchTrash()
  }, [user])

  console.log({
    user,
    receivedEmails,
    sentEmails,
    spamEmails,
    trashEmails,
    draftEmails,
  })

  const handleDelete = (id: string) => {
    console.log({ id })
    deleteEmail(id as any)
  }

  function EmailCard({ email, name }: any) {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 5,
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box
              sx={{
                mt: 0.5,
                p: 1,
                border: '1px solid gray',
                height: 7,
                width: 7,
              }}
            />

            <Box>
              <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                {name}
              </Typography>
              <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                {email?.subject}
              </Typography>
              <Typography sx={{ fontSize: 12, width: '80%' }}>
                {email?.text?.plain?.slice(0, 250)}
              </Typography>
              <Typography sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}>
                {moment(email?.date).format('lll')}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              onClick={() => {
                setRecipientEmail(email?.from?.address)
                setOpenThread(true)
              }}
            >
              <Message sx={{ color: '#0178DD' }} />
            </IconButton>
            <IconButton>
              <AddCard sx={{ color: '#0178DD' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setMessageId()
                setOpenTag(true)
              }}
            >
              <ManageAccounts sx={{ color: '#0178DD' }} />
            </IconButton>
            <IconButton
              onClick={() =>
                // setMessageIdToDelete(email.id)
                handleDelete(email.id)
              }
            >
              <Delete sx={{ color: '#F49B9B' }} />
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
      </Box>
    )
  }

  return (
    <Box sx={{ pt: 3 }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          pt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          //   alignItems: 'center',
          px: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Box sx={{ py: 1 }}>
            <TextField
              InputProps={{
                startAdornment: <Search />,
                sx: {
                  '&.MuiOutlinedInput-root': {
                    borderRadius: 8,
                    height: 45,
                    bgcolor: '#E6F4FF ',
                  },
                },
              }}
              placeholder='Search'
              //   sx={{ my: 1 }}
            />
          </Box>
          <Tabs value={value} onChange={handleChange}>
            {/* <Tab
              label='All'
              {...a11yProps(0)}
              sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
            /> */}
            <Tab
              label={`Email`}
              {...a11yProps(0)}
              sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
            />
            {/* <Tab
              label='Text'
              {...a11yProps(2)}
              sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
            />
            <Tab
              label={'Fax'}
              {...a11yProps(3)}
              sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
            /> */}
          </Tabs>
        </Box>
        <Box sx={{ height: '100%', px: 3 }}>
          <Button
            variant='contained'
            sx={{ borderRadius: 7, py: 1.5 }}
            onClick={() => setOpenNewMessage(true)}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Add />
              <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                New Message
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
      {/* <CustomTabPanel value={value} index={0}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 170px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  height: '100%',
                  borderRight: '1px solid',
                  borderColor: 'divider',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Received'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={`Send`}
                    {...a11yPropsVertical(1)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label='Draft'
                    {...a11yPropsVertical(2)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={'Unread'}
                    {...a11yPropsVertical(3)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={`Starred`}
                    {...a11yPropsVertical(4)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label='Trash'
                    {...a11yPropsVertical(5)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={'Spam'}
                    {...a11yPropsVertical(6)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5} sx={{ p: 7 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setMessageId()
                        setOpenTag(true)
                      }}
                    >
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel> */}

      <CustomTabPanel value={value} index={0}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 170px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  height: '100%',
                  borderRight: '1px solid',
                  borderColor: 'divider',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Received'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={`Sent`}
                    {...a11yPropsVertical(1)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label='Draft'
                    {...a11yPropsVertical(2)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label='Trash'
                    {...a11yPropsVertical(5)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={'Spam'}
                    {...a11yPropsVertical(6)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5} sx={{ p: 5 }}>
              <CustomTabPanel value={valueVertical} index={0}>
                <Box
                  sx={{
                    height: 'calc(100vh - 250px)',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: 0,
                    },
                  }}
                >
                  {receivedEmails?.map((email: any) => (
                    <EmailCard email={email} name={email?.from?.name} />
                  ))}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={valueVertical} index={1}>
                <Box
                  sx={{
                    height: 'calc(100vh - 250px)',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: 0,
                    },
                  }}
                >
                  {sentEmails?.map((email: any) => (
                    <EmailCard email={email} name={email?.to?.[0]?.name} />
                  ))}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={valueVertical} index={2}>
                <Box
                  sx={{
                    height: 'calc(100vh - 250px)',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: 0,
                    },
                  }}
                >
                  {draftEmails?.map((email: any) => (
                    <EmailCard email={email} name={email?.to?.[0]?.name} />
                  ))}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={valueVertical} index={3}>
                <Box
                  sx={{
                    height: 'calc(100vh - 250px)',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: 0,
                    },
                  }}
                >
                  {trashEmails?.map((email: any) => (
                    <EmailCard email={email} name={email?.from?.name} />
                  ))}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={valueVertical} index={4}>
                <Box
                  sx={{
                    height: 'calc(100vh - 250px)',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: 0,
                    },
                  }}
                >
                  {spamEmails?.map((email: any) => (
                    <EmailCard email={email} name={email?.from?.name} />
                  ))}
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      {/* <CustomTabPanel value={value} index={2}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 170px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  height: '100%',
                  borderRight: '1px solid',
                  borderColor: 'divider',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Received'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={`Send`}
                    {...a11yPropsVertical(1)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label='Draft'
                    {...a11yPropsVertical(2)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={'Unread'}
                    {...a11yPropsVertical(3)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={`Starred`}
                    {...a11yPropsVertical(4)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label='Trash'
                    {...a11yPropsVertical(5)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={'Spam'}
                    {...a11yPropsVertical(6)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5} sx={{ p: 7 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton onClick={() => setOpenTag(true)}>
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel> */}

      {/* <CustomTabPanel value={value} index={3}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 170px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  height: '100%',
                  borderRight: '1px solid',
                  borderColor: 'divider',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Received'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={`Send`}
                    {...a11yPropsVertical(1)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label='Draft'
                    {...a11yPropsVertical(2)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={'Unread'}
                    {...a11yPropsVertical(3)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={`Starred`}
                    {...a11yPropsVertical(4)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label='Trash'
                    {...a11yPropsVertical(5)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  <Tab
                    label={'Spam'}
                    {...a11yPropsVertical(6)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5} sx={{ p: 7 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box
                    sx={{
                      mt: 0.5,
                      p: 1,
                      border: '1px solid gray',
                      height: 7,
                      width: 7,
                    }}
                  />

                  <Box>
                    <Typography sx={{ fontWeight: 'bold', color: '#0178DD' }}>
                      Abebe Kebede
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', my: 0.5 }}>
                      Rescheduling Request - Kidney Checkup
                    </Typography>
                    <Typography sx={{ fontSize: 12, width: '80%' }}>
                      I hope this email finds you well. I am writing to request
                      a reschedule for my upcoming Kidney Checkup appointment,
                      which is currently scheduled for June 22, 2023, at 10:00
                      AM. Due to an unexpected conflict, I am unable to attend
                      the appointment at the designated time.
                    </Typography>
                    <Typography
                      sx={{ fontSize: 12, color: '#818CA0', my: 1.5 }}
                    >
                      04:00
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <AddCard sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton>
                      <Delete sx={{ color: '#F49B9B' }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel> */}
      <CreateMessage
        open={openNewMessage}
        close={() => setOpenNewMessage(false)}
        refetch={false}
        setRefetch={() => {}}
      />
      {recipientEmail && (
        <ThreadMessage
          open={openThread}
          close={() => setOpenThread(false)}
          refetch={false}
          setRefetch={() => {}}
          recipientEmail={recipientEmail}
        />
      )}
      <TagPeople
        open={openTag}
        close={() => setOpenTag(false)}
        messageId={messageId}
        refetch={() => {}}
      />
    </Box>
  )
}
