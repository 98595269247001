import {
  AttachFile,
  AutoAwesome,
  Edit,
  Mic,
  OpenInFull,
  Print,
  Send,
  Spellcheck,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { usePost } from '../../hooks/usePost'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { useFetch } from '../../hooks/useFetch'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function CustomTabPanelNote(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yPropsNote(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function ExpandedNotesAndChats({ client }: any) {
  const [value, setValue] = useState(0)
  const [valueNote, setValueNote] = useState(0)
  const [refetchNotes, setRefetchNotes] = useState(false)
  const [activeProgram, setActiveProgram] = useState('All')
  const [type, setType] = useState('chat')
  const [spellCheckRes, setSpellCheckRes] = useState<any>()
  const [text, setText] = useState<string>() as any
  const [note, setNote] = useState() as any
  const [noteDate, setNoteDate] = useState() as any
  const [noteDuration, setNoteDuration] = useState() as any
  const [noteAppointmentType, setNoteAppointmentType] = useState() as any

  console.log({ spellCheckRes })

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const handleChangeNote = (event: React.SyntheticEvent, newValue: number) => {
    setValueNote(newValue)
  }
  const { mutate: getClientNotes, data: clientNotes } = usePost(
    `/public/clients/notes/list`
  )

  const {
    mutate: createNote,
    isError,
    isLoading,
    isSuccess,
    error,
  } = usePost(`/public/clients/notes`)

  const handleSpellCheck = () => {
    axios
      .post('http://64.226.72.36:8001/api/v1/correct-spelling', {
        user_text: note,
      })
      .then((res) => {
        // setisLoading(false)
        // if (type === 'audio') {
        //   setVoiceResponse(res?.data?.response)
        // }
        setSpellCheckRes(res)
        setNote(res?.data?.content)
      })
      .catch((e) => {
        // setisLoading(false)
        // setIsVoiceLoading(false)
        console.log({ e })
      })
  }

  console.log({ clientNotes })
  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
      client_id: client?.id,
    }
    getClientNotes(datap)
  }, [refetchNotes])
  console.log({ client })

  console.log(
    222222222223,
    client?.programs?.find((item: any) => item?.id === activeProgram)
      ?.noteConfig?.[valueNote]
  )

  const handleSaveNote = () => {
    const data: any = {
      client_id: client?.id,
      value: note,
      program_id: activeProgram,
      note_type_id: client?.programs?.find(
        (item: any) => item?.id === activeProgram
      )?.noteConfig?.[valueNote]?.noteTypeId,
      duration:
        noteDuration === '15 Min'
          ? '15m'
          : noteDuration === '30 Min'
          ? '30m'
          : noteDuration === '45 Min'
          ? '45m'
          : '60m',
      date: noteDate,
    }
    createNote(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Note Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetchNotes(!refetchNotes)
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  const { data: carePlanNotes } = useFetch(
    'getCarePlanNotes',
    `/public/clients/${client?.id}/care_plan_notes`
  )
  console.log({ carePlanNotes })

  return (
    // <Box
    //   sx={{
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    //     bgcolor: '#F4FAFF',
    //     height: 'calc(100vh - 73px)',
    //     width: { xs: '100vw', md: 'calc(100vw - 280px)' },
    //     p: 3,
    //   }}
    // >
    //   <Box
    //     sx={{
    //       pt: 2,
    //       display: 'flex',
    //       justifyContent: 'space-between',
    //     }}
    //   >
    //     <Box sx={{ display: 'flex', gap: 10 }}>
    //       <Tabs
    //         value={value}
    //         onChange={handleChange}
    //         aria-label='basic tabs example'
    //       >
    //         <Tab
    //           label='Note'
    //           {...a11yProps(0)}
    //           sx={{ fontWeight: 'bold' }}
    //         />
    //         {/* <Tab
    //             label={`Chat`}
    //             {...a11yPropsApp(1)}
    //             sx={{ fontWeight: 'bold' }}
    //           /> */}
    //       </Tabs>
    //       <Box
    //         sx={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           gap: 1,
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             bgcolor: 'white',
    //             borderRadius: 2,
    //             px: 1,
    //             py: 0.5,
    //             boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
    //           }}
    //         >
    //           <Typography sx={{ fontSize: 11, fontWeight: 'bold' }}>
    //             All
    //           </Typography>
    //         </Box>
    //         <Box
    //           sx={{
    //             bgcolor: 'white',
    //             borderRadius: 2,
    //             px: 1,
    //             py: 0.5,
    //           }}
    //         >
    //           <Typography sx={{ fontSize: 11, fontWeight: 'bold' }}>
    //             Job
    //           </Typography>
    //         </Box>
    //         <Box
    //           sx={{
    //             bgcolor: 'white',
    //             borderRadius: 2,
    //             px: 1,
    //             py: 0.5,
    //           }}
    //         >
    //           <Typography sx={{ fontSize: 11, fontWeight: 'bold' }}>
    //             Housing
    //           </Typography>
    //         </Box>
    //       </Box>
    //     </Box>
    //     <Box sx={{ height: '100%', px: 2 }}>
    //       <IconButton onClick={() => setExpandNotes(false)}>
    //         <OpenInFull sx={{ rotate: '90deg', color: '#0178DD' }} />
    //       </IconButton>
    //     </Box>
    //   </Box>
    //   <CustomTabPanel value={value} index={0}>
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <Box sx={{ p: 2, width: '65%' }}>
    //         <Typography sx={{ fontWeight: 'bold' }}>April</Typography>
    //         <Box sx={{ my: 1, bgcolor: 'white', p: 2 }}>
    //           <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
    //             Wed-2013
    //           </Typography>
    //           <Typography sx={{ fontSize: 12 }}>
    //             Patient Exhibits Signs Of Seasonal Allergies, Complains Of Itchy
    //             Eyes And Sneezing In The Springtime. Prescribed Antihistamines
    //             And Advised Avoidance Of Allergens.
    //           </Typography>
    //         </Box>
    //         <Box sx={{ my: 1, bgcolor: 'white', p: 2 }}>
    //           <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
    //             Wed-2013
    //           </Typography>
    //           <Typography sx={{ fontSize: 12 }}>
    //             Patient Exhibits Signs Of Seasonal Allergies, Complains Of Itchy
    //             Eyes And Sneezing In The Springtime. Prescribed Antihistamines
    //             And Advised Avoidance Of Allergens.
    //           </Typography>
    //         </Box>
    //         <Box sx={{ my: 1, bgcolor: 'white', p: 2 }}>
    //           <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
    //             Wed-2013
    //           </Typography>
    //           <Typography sx={{ fontSize: 12 }}>
    //             Patient Exhibits Signs Of Seasonal Allergies, Complains Of Itchy
    //             Eyes And Sneezing In The Springtime. Prescribed Antihistamines
    //             And Advised Avoidance Of Allergens.
    //           </Typography>
    //         </Box>
    //       </Box>
    //       <Divider />
    //       {isWriring ? (
    //         <Box sx={{ bgcolor: 'white', borderRadius: 2 }}>
    //           <textarea
    //             style={{
    //               height: '12vh',
    //               width: '100%',
    //               resize: 'none',
    //             }}
    //           />
    //           <Divider sx={{ my: 1, color: '#F7F7F7' }} />
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               alignItems: 'flex-end',
    //               justifyContent: 'space-between',
    //               gap: 2,
    //             }}
    //           >
    //             <Box
    //               sx={{
    //                 display: 'flex',
    //                 gap: 2,
    //                 alignItems: 'center',
    //               }}
    //             >
    //               <Typography sx={{ fontSize: 16 }}>Date:</Typography>
    //               <DatePicker slotProps={{ textField: { size: 'small' } }} />
    //             </Box>
    //             <Box
    //               sx={{
    //                 display: 'flex',
    //                 gap: 2,
    //                 alignItems: 'center',
    //               }}
    //             >
    //               <Typography sx={{ fontSize: 16 }}>Duration:</Typography>
    //               <TextField size='small' />
    //               <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
    //                 In Minutes
    //               </Typography>
    //             </Box>
    //             <Button
    //               variant='contained'
    //               onClick={() => setIsWriting(false)}
    //               sx={{ py: 1.2 }}
    //             >
    //               <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
    //                 Save
    //               </Typography>
    //             </Button>
    //           </Box>
    //         </Box>
    //       ) : (
    //         <Button
    //           variant='contained'
    //           sx={{ borderRadius: 7, px: 4 }}
    //           onClick={() => setIsWriting(true)}
    //         >
    //           <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //             <Add />
    //             <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
    //               Add
    //             </Typography>
    //           </Box>
    //         </Button>
    //       )}
    //     </Box>
    //   </CustomTabPanelApp>
    //   <CustomTabPanelApp value={valueApp} index={1}>
    //     <></>
    //   </CustomTabPanelApp>
    // </Box>
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bgcolor: 'white',
        height: 'calc(100vh - 73px)',
        width: { xs: '100vw', md: 'calc(100vw - 280px)' },
        p: 1.2,
      }}
    >
      <Box
        sx={{
          bgcolor: '#F4FAFF',
          borderRadius: 1,
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ ml: 7, width: '100%', py: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box>
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    label='Note'
                    {...a11yProps(0)}
                    sx={{ fontWeight: 'bold' }}
                  />
                  <Tab
                    label='Chat'
                    {...a11yProps(1)}
                    sx={{ fontWeight: 'bold' }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    bgcolor: 'white',
                    borderRadius: 2,
                    px: 2,
                    py: 0.5,
                    cursor: 'pointer',
                    boxShadow:
                      activeProgram === 'All'
                        ? '4px 4px 10px 1px rgba(0,0,0,0.07)'
                        : '',
                  }}
                  onClick={() => setActiveProgram('All')}
                >
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    All
                  </Typography>
                </Box>
                {client?.programs?.map((item: any) => (
                  <Box
                    sx={{
                      bgcolor: 'white',
                      borderRadius: 2,
                      px: 2,
                      py: 0.5,
                      cursor: 'pointer',
                      boxShadow:
                        activeProgram === item?.id
                          ? '4px 4px 10px 1px rgba(0,0,0,0.07)'
                          : '',
                    }}
                    onClick={() => setActiveProgram(item?.id)}
                  >
                    <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                      {item?.title}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <CustomTabPanel index={0} value={value}>
            <Box sx={{ py: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      // bgcolor: '#3D577E',
                      width: 400,
                      borderRadius: 3,
                      // background: 'rgb(2,50,219)',
                      background:
                        'linear-gradient(45deg, rgba(2,50,219,1) 0%, rgba(63,221,216,1) 85%)',
                    }}
                  >
                    {/* <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 3,
                        px: 3,
                        py: 1,
                      }}
                    >
                      <IconButton>
                        <Print sx={{ color: '#BBC4DD' }} />
                      </IconButton>
                      <IconButton>
                        <Edit sx={{ color: '#BBC4DD' }} />
                      </IconButton>
                      <IconButton>
                        <OpenInFull
                          sx={{ rotate: '90deg', color: '#F5FAFF' }}
                        />
                      </IconButton>
                    </Box> */}
                    <Box sx={{ py: 5, px: 3 }}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <Avatar
                          sx={{ width: 70, height: 70 }}
                          src={client?.profilePictureUrl}
                        />
                        <Box>
                          <Typography
                            sx={{
                              color: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            {client?.firstName + ' ' + client?.lastName}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography sx={{ color: 'white', fontSize: 14 }}>
                              {client?.mcpId}
                            </Typography>
                            <Box
                              sx={{
                                p: 0.2,
                                bgcolor: '#BAC3DC',
                                borderRadius: '100%',
                              }}
                            />
                            <Typography sx={{ color: 'white', fontSize: 14 }}>
                              {moment(client?.dob).format('l')}
                            </Typography>
                            <Box
                              sx={{
                                p: 0.2,
                                bgcolor: '#BAC3DC',
                                borderRadius: '100%',
                              }}
                            />
                            <Typography sx={{ color: 'white', fontSize: 14 }}>
                              {client?.address?.city}
                            </Typography>
                          </Box>
                          <Typography sx={{ color: 'white', fontSize: 13 }}>
                            Created At {moment(client?.createdAt)?.format('ll')}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 4, bgcolor: '#E4EFF8' }} />
                      <Box>
                        <Typography
                          sx={{
                            color: '#D9D972',
                            fontWeight: 'bold',
                            fontSize: 20,
                          }}
                        >
                          Care Plan
                        </Typography>
                      </Box>
                      {carePlanNotes?.carePlanNotes?.map((item: any) => (
                        <Box sx={{ pt: 2 }}>
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontSize: 16,
                              color: 'white',
                              my: 1,
                            }}
                          >
                            {item?.categoryField}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: 'white',
                            }}
                          >
                            {item?.notes}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        height: 'calc(100vh - 450px)',
                        width: '100%',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          width: 0,
                        },
                      }}
                    >
                      {clientNotes?.data?.notes?.map((item: any) => {
                        if (
                          activeProgram === 'All' ||
                          activeProgram === item?.programId
                        ) {
                          return (
                            <Box
                              sx={{
                                bgcolor: 'white',
                                p: 2,
                                borderRadius: 2,
                                my: 2,
                                width: '100%',
                              }}
                            >
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {moment(item?.createdAt).format('ll')}
                              </Typography>
                              <Typography
                                sx={{
                                  color: '#232C42',
                                  pt: 1,
                                  fontSize: 13,
                                }}
                              >
                                {item?.value}
                              </Typography>
                            </Box>
                          )
                        }
                      })}
                    </Box>
                    <Box sx={{ position: 'fixed', bottom: 10 }}>
                      <Divider sx={{ mb: 2 }} />
                      <Box
                        sx={{
                          bgcolor: 'white',
                          borderRadius: 2,
                          boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                        }}
                      >
                        <Box
                          sx={{
                            display: activeProgram === 'All' ? 'none' : 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #EFEFEF',
                          }}
                        >
                          <Tabs value={valueNote} onChange={handleChangeNote}>
                            {client?.programs
                              ?.find((item: any) => item?.id === activeProgram)
                              ?.noteConfig?.map((elt: any, index: number) => (
                                <Tab
                                  label={elt?.noteTypeName}
                                  {...a11yProps(index)}
                                  sx={{ fontWeight: 'bold' }}
                                />
                              ))}
                          </Tabs>

                          <Avatar
                            sx={{
                              mx: 1,
                              height: 32,
                              width: 32,
                              bgcolor: '#FFAC33',
                              cursor: 'pointer',
                            }}
                            onClick={handleClick}
                          >
                            <AutoAwesome
                              sx={{ color: 'white', width: 20, height: 20 }}
                            />
                          </Avatar>

                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                          >
                            <Box
                              sx={{
                                border: '2px solid #FFAC33',
                              }}
                            >
                              <Box sx={{ py: 1, px: 2 }}>
                                <Typography
                                  sx={{
                                    color: '#FFAC33',
                                    fontSize: 11,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Austin - AI Powered Client Support Assistant
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  py: 6,
                                  px: 2,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: 5,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alingItems: 'center',
                                    p: 0.6,
                                    borderRadius: 10,
                                    border: '1px solid #C8D0DC',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      bgcolor:
                                        type === 'chat' ? '#3376D6' : 'none',
                                      boxShadow:
                                        type === 'text'
                                          ? '4px 4px 10px 1px rgba(0,0,0,0.07)'
                                          : 'none',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      px: 3,
                                      borderRadius: 10,
                                      py: 1.5,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => setType('chat')}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        color:
                                          type === 'chat' ? 'white' : 'black',
                                      }}
                                    >
                                      Chat
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      bgcolor:
                                        type === 'summary' ? '#3376D6' : 'none',
                                      boxShadow:
                                        type === 'summary'
                                          ? '4px 4px 10px 1px rgba(0,0,0,0.07)'
                                          : 'none',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      px: 3,
                                      borderRadius: 10,
                                      py: 1.5,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => setType('summary')}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 14,
                                        color:
                                          type === 'summary'
                                            ? 'white'
                                            : 'black',
                                      }}
                                    >
                                      Summarize
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <TextField
                                    // variant='outlined'
                                    // variant='filled'
                                    onKeyDown={(event) => {
                                      if (event?.key === 'Enter') {
                                        // if (text.length > 0) {
                                        //   handleSend()
                                        // }
                                      }
                                    }}
                                    placeholder='Wrtie Here'
                                    fullWidth
                                    // value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    sx={{
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          border: 'none', // Remove the outline
                                        },
                                      },
                                      height: 60,
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <IconButton
                                        // onClick={handleSend}
                                        >
                                          <Send
                                            sx={{ color: 'primary.main' }}
                                          />
                                        </IconButton>
                                      ),
                                      sx: {
                                        borderRadius: 30,
                                        bgcolor: '#F0F0F0',
                                        border: '1px solid #EDEDED',
                                        height: 60,
                                        px: 1,
                                        // boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.05)',
                                      },
                                    }}
                                  />

                                  <IconButton>
                                    <Mic />
                                  </IconButton>
                                  <IconButton>
                                    <AttachFile />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </Popover>
                        </Box>
                        <Box
                          sx={{
                            position: 'relative',
                            display: 'inline-block',
                            width: '100%',
                          }}
                        >
                          <textarea
                            style={{
                              width: '100%',
                              height: 130,
                              resize: 'none',
                              border: 'none',
                              borderBottom: '1px solid #EFEFEF',
                            }}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            disabled={activeProgram === 'All' ? true : false}
                          />
                          <IconButton
                            sx={{
                              position: 'absolute',
                              bottom: '8px',
                              right: '8px',
                            }}
                            onClick={handleSpellCheck}
                          >
                            <Spellcheck />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            p: 2,
                            bgcolor: activeProgram === 'All' ? '#FAFAFA' : '',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: 'space-between',
                              gap: 3,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                              }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                Date:
                              </Typography>
                              <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                disabled={
                                  activeProgram === 'All' ? true : false
                                }
                                onChange={(value) => setNoteDate(value)}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                              }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                Duration:
                              </Typography>
                              <Autocomplete
                                options={[
                                  '15 Min',
                                  '30 Min',
                                  '45 Min',
                                  '60 Min',
                                ]}
                                filterSelectedOptions={true}
                                getOptionLabel={(option: any) => option}
                                size='small'
                                onChange={(event, value) =>
                                  setNoteDuration(value)
                                }
                                disabled={
                                  activeProgram === 'All' ? true : false
                                }
                                sx={{ width: 200 }}
                                renderInput={(params: any) => (
                                  <TextField {...params} name='duration' />
                                )}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                              }}
                            >
                              <Typography sx={{ fontSize: 16 }}>
                                Type:
                              </Typography>
                              <Autocomplete
                                options={['In-person', 'Video', 'Audio Call']}
                                filterSelectedOptions={true}
                                getOptionLabel={(option: any) => option}
                                size='small'
                                onChange={(event, value) =>
                                  setNoteAppointmentType(value)
                                }
                                disabled={
                                  activeProgram === 'All' ? true : false
                                }
                                sx={{ width: 200 }}
                                renderInput={(params: any) => (
                                  <TextField {...params} name='duration' />
                                )}
                              />
                            </Box>
                            <Button
                              variant='contained'
                              sx={{ py: 1.2 }}
                              disabled={activeProgram === 'All' ? true : false}
                              onClick={handleSaveNote}
                            >
                              <Typography
                                sx={{ fontSize: 13, fontWeight: 'bold' }}
                              >
                                Save
                              </Typography>
                            </Button>
                          </Box>
                          {/* <Box sx={{ py: 1 }}>
                          <FormControlLabel
                            label='Non Contact'
                            control={
                              <Checkbox
                                disabled={
                                  activeProgram === 'All' ? true : false
                                }
                              />
                            }
                          />
                        </Box> */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CustomTabPanel>
        </Box>
        <Box
          sx={{
            width: 70,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <IconButton>
            <OpenInFull />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}
